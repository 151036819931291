<template>
  <div class="HelpPanelPaginationComponent">
    <ButtonComponent
      v-if="currentPage !== 0"
      buttonType="button"
      class="go-top"
      width="50px"
      height="50px"
      rounded="circle"
      size="small"
      @click="onClickInitialPage"
      ><i class="icon icon-arrow-skip"></i
    ></ButtonComponent>
    <span v-if="currentPage === 0" class="spacer"></span>
    <div class="action-group">
      <ButtonComponent
        v-if="currentPage !== 0"
        buttonType="button"
        class="go-previous"
        width="150px"
        height="50px"
        type="outline"
        color="#8d9caa"
        @click="onClickPreviousPage"
        >前へ</ButtonComponent
      >
      <ButtonComponent
        v-if="currentPage !== totalPages - 1"
        buttonType="button"
        class="go-next"
        width="150px"
        height="50px"
        color="#8d9caa"
        @click="onClickNextPage"
        >次へ</ButtonComponent
      >
    </div>
    <span class="spacer"></span>
  </div>
</template>

<script>
export default {
  name: "HelpPanelPaginationComponent",
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {},
  methods: {
    onClickInitialPage() {
      this.$emit("pagechanged", 0);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.HelpPanelPaginationComponent {
  font-size: 18px;
  font-weight: bold;
  .go-top {
    .icon-arrow-skip {
      transform: rotate(-90deg);
    }
  }
  .go-top + .action-group {
    margin-top: -50px;
  }
  .action-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .HelpPanelPaginationComponent {
    display: flex;
    align-items: flex-end;
    .go-top {
      flex: 1;
    }
    .go-top + .action-group {
      margin-top: 0;
    }
    .action-group {
      flex: 0;
      flex-direction: column-reverse;
      width: 150px;
    }
    .spacer {
      flex: 1;
    }
  }
}
</style>
