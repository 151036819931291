<template>
  <div class="ResetMFAComponent">
    <CardComponent class="form-resetMFA" :width="'960px'" :height="'550px'">
      <template v-if="!isSuccessReset">
        <TitleComponent
          class="form-resetMFA_title fitContent"
          :hasUnderLine="true"
        >
          <template v-slot:content>多要素認証をリセットする</template>
        </TitleComponent>
        <p class="form-resetMFA_description">
          リセットした後は多要素認証が設定されていない状態となり、
          ログインから多要素認証の設定をやり直す必要があります。
        </p>
        <div class="form-resetMFA-action-group">
          <ButtonComponent
            buttonType="button"
            type="outline"
            width="160px"
            @click="cancel()"
          >
            キャンセル
          </ButtonComponent>
          <ButtonComponent
            width="250px"
            buttonType="submit"
            @click="resetMFA()"
            class="btn-submit"
          >
            多要素認証のリセット
          </ButtonComponent>
        </div>
      </template>
      <template v-else>
        <TitleComponent
          class="form-resetMFA_title fitContent"
          :hasUnderLine="true"
        >
          <template v-slot:content
            >多要素認証リセットURLをメールに送信しました</template
          >
        </TitleComponent>
        <div class="form-resetMFA-action-group-text">
          多要素認証の再設定を行ってください。
        </div>
      </template>
    </CardComponent>
  </div>
</template>

<script>
import LoginService from "@/services/login.service";
import { mapState } from "vuex";
import { STATUS } from "@/globals/enums";

export default {
  name: "resetMFAComponent",
  computed: mapState({
    tempUser: (state) => state.settings.tempUser,
  }),
  data: () => ({
    isSuccessReset: false,
    loginService: new LoginService(),
  }),
  methods: {
    async resetMFA() {
      const result = await this.loginService.resetMFASetting(
        this.tempUser?.username
      );
      if (result.status === STATUS.ERROR) {
        //add no token to /re-setting-sms?token=??? and show error
        this.$router.push({
          name: "ReSettingSMSComponent",
        });
        return;
      }
      if (result.status === STATUS.SUCCESS) this.isSuccessReset = true;
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.ResetMFAComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-resetMFA {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &_description {
        font-size: 14px;
        font-weight: 500;
        margin: 16px 0 40px;
        width: 400px;
      }
      &_inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 220px 40px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          font-weight: bold;
          color: #7c8185;
        }
        .InputComponent {
          margin: 0;
        }
      }
      &-action-group {
        display: flex;
        gap: 20px;
        margin: 30px 0 30px;
        &-text {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.57;
          letter-spacing: 0.7px;
        }
        font-size: 18px;
        font-weight: bold;
      }
      &-resetMFA {
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .ResetMFAComponent {
    height: 100%;
    display: block;
    .v-card {
      width: 100% !important;
      height: 100% !important;
    }
    form {
      height: 100%;
    }
    .form {
      &-resetMFA {
        padding: 30px 20px 0;
        &_description {
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          margin: 16px 0 7px;
          width: 335px;
          height: 93px;
        }
        &_inputGroups {
          width: 100%;
          gap: 40px;
          padding: 0;
          margin-bottom: 40px;
        }
        &-inputGroup {
          display: flex;
          gap: 30px;
          align-items: center;
          justify-content: space-between;
          &_title {
            font-size: 14px;
            // font-weight: bold;
            color: #7c8185;
          }
          .InputComponent {
            margin: 0;
            width: 100% !important;
          }
        }
        &-action-group {
          margin: 0;
          flex-direction: column-reverse;
          align-items: center;
          gap: unset;
          .btn-submit {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
