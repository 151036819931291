import {
  MONTHS,
  DATES,
  SAFETY_CHECK_RESULT,
  SAFETY_CHECK_PERSON,
  START_RANGE_TIME,
} from "@/globals/enums";

import dayjs from "dayjs";

export const convertYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const result = [
    {
      value: 0,
      label: "ー",
    },
  ];
  for (let index = currentYear; index >= START_RANGE_TIME.YEAR; index--) {
    result.push({
      value: index,
      label: index,
    });
  }

  return result;
};

export const convertMonthOptions = () => {
  return MONTHS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertDateOptions = () => {
  return DATES.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertAnalysisEnums = () => {
  return {
    startOptions: {
      years: convertYearOptions(),
      months: convertMonthOptions(),
      dates: convertDateOptions(),
    },
    endOptions: {
      years: convertYearOptions(),
      months: convertMonthOptions(),
      dates: convertDateOptions(),
    },
  };
};

export const convertBodyGetAnalysis = (body, pagination, sort) => {
  const searchDateFrom = dayjs(
    `${body.startYear}-${body.startMonth}-${body.startDate}`
  ).format("YYYY-MM-DD");
  const searchDateTo = dayjs(
    `${body.endYear || new Date().getFullYear()}-${body.endMonth}-${
      body.endDate
    }`
  ).format("YYYY-MM-DD");
  return {
    search_date_from:
      body?.startDate && body.startMonth && body.startYear
        ? searchDateFrom
        : null,
    search_date_to:
      body?.endDate && body?.endMonth && body?.endYear ? searchDateTo : null,
    analysis_result_code: body.judgmentResult || null,
    order_by: sort,
    page: pagination.page || 1,
    number: pagination.number || 10,
  };
};

export const convertAnalysis = (analysis) => {
  return {
    analysisResultId: analysis.analysis_result_id,
    analysisDate: analysis.analysis_date,
    analysisResultCode: analysis.analysis_result_code,
    detectionResult: analysis.detection_result,
    confirmStatus: analysis.confirm_status,
    alertFlag: analysis.alert_flg,
    safetyCheckUpdate: analysis.safety_check_update || "ー",
    safetyCheckResult:
      SAFETY_CHECK_RESULT[analysis.safety_check_result] || "ー",
    safetyCheckPerson:
      SAFETY_CHECK_PERSON[analysis.safety_check_person] || "ー",
    pauseStartDate: analysis.pause_start_date,
    pauseEndDate: analysis.pause_end_date,
    pauseStart: !!analysis.pause_start_date,
  };
};

export const convertSafetyCheckHistory = (safety) => {
  return {
    safetyCheckUpdate: safety.safety_check_update || "ー",
    safetyCheckResult: SAFETY_CHECK_RESULT[safety.safety_check_result] || "ー",
    safetyCheckPerson: SAFETY_CHECK_PERSON[safety.safety_check_person] || "ー",
    safetyCheckId: safety.safety_check_result,
  };
};

export const convertMimamoriInfo = (mimamoriInfo) => {
  return {
    analysisResultId: mimamoriInfo.analysis_result_id,
    analysisDate: mimamoriInfo.analysis_date,
    mimamoriTargetPersonId: mimamoriInfo.mimamori_target_person_id,
    lastNameKanji: mimamoriInfo.last_name_kanji,
    firstNameKanji: mimamoriInfo.first_name_kanji,
    lastNameKana: mimamoriInfo.last_name_kana,
    firstNameKanan: mimamoriInfo.first_name_kana,
    zipCode1: mimamoriInfo.zip_code1,
    zipCode2: mimamoriInfo.zip_code2,
    addressPrefecture: mimamoriInfo.address_prefecture,
    addressCity: mimamoriInfo.address_city,
    addressStreetNumber: mimamoriInfo.address_street_number,
    addressBuildingName: mimamoriInfo.address_building_name,
    addressRoomNumber: mimamoriInfo.address_room_number,
    phoneNumber1: mimamoriInfo.phone_number1,
    phoneNumber2: mimamoriInfo.phone_number2,
    phoneNumber3: mimamoriInfo.phone_number3,
    analysisResultCode: mimamoriInfo.analysis_result_code,
    detectionResult: mimamoriInfo.detection_result,
    confirmStatus: mimamoriInfo.confirm_status,
    alertFlag: mimamoriInfo.alert_flg,
    calendarId: mimamoriInfo.calendar_id,
    pauseStartDate: mimamoriInfo.pause_start_date,
    pauseEndDate: mimamoriInfo.pause_end_date,
    reasonType: mimamoriInfo.reason_type,
    createPerson: mimamoriInfo.create_person,
    pauseStart: !!mimamoriInfo.pause_start_date,
    latestDataFlg: mimamoriInfo.latest_data_flg,
  };
};
