<template>
  <v-app>
    <HeaderComponent v-if="showHeaderFooter" />
    <v-main :class="{ 'background-azure': isPrint }">
      <router-view />
    </v-main>
    <FooterComponent v-if="showHeaderFooter" />
    <modals-container></modals-container>
    <LoaderComponent v-if="loadingStatus" />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { ModalsContainer } from "vue-final-modal";
import HomeService from "./services/home.service";
import { convertHomeChangeRouter } from "@/store/converter/home.converter";
import { STATUS, IS_ACTIVATE_EMAIL_PAGE } from "./globals/enums";
export default {
  name: "App",
  components: {
    ModalsContainer,
  },
  data: () => ({
    homeService: new HomeService(),
  }),
  mounted() {
    setTimeout(() => {
      /**
       * NOTE:
       * The initial setup flow switches screens according to the value of service_status.
       * However, if /home and /help are explicitly specified, they should be opened.
       * (Skip execution of initialDataSources() function.)
       *
       * ATTENTION:
       * In the initial setup flow,
       * the /help screen is used to guide the user through the process.
       * (If you execute the function, you will leave /help screen.)
       */
      if (
        this.$route.name !== "HomeComponent" &&
        this.$route.name !== "HelpComponent"
      ) {
        this.initialDataSources();
      }
    }, 500);
  },
  computed: {
    ...mapState({
      loadingStatus: (state) => state.global.loadingStatus,
    }),
    showHeaderFooter() {
      return !["PrintComponent"].includes(this.$route.name);
    },
    isPrint() {
      return this.$route.name === "PrintComponent";
    },
  },
  methods: {
    initialDataSources() {
      if (window.location.pathname.match(IS_ACTIVATE_EMAIL_PAGE)) {
        return;
      }
      this.$store.dispatch("global/updateLoadingStatus", true);
      this.homeService.getHome().then((result) => {
        this.$store.dispatch("global/updateLoadingStatus", false);
        if (result?.status !== STATUS.SUCCESS) {
          return;
        }
        const reRouterComponent = convertHomeChangeRouter(result.data);
        if (reRouterComponent) {
          this.$router.push({ name: reRouterComponent });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.background-azure {
  background-color: #f6f9f9;
}
</style>
