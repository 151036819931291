import { FALSY_VALUE } from "@/globals/enums";

export const convertRelativesAccountInfo = (account) => {
  return {
    relativesAccountId: account.relatives_account_id,
    lastNameKanji: account.last_name_kanji,
    fristNameKanji: account.first_name_kanji,
    lastNameKana: account.last_name_kana,
    firstNameKana: account.first_name_kana,
    relativesEmail: account.relatives_email,
  };
};
export const convertMimamoriInfo = (mimamoriInfo) => {
  return {
    estateId: mimamoriInfo.estate_id,
    estateName: mimamoriInfo.estate_name,
    lastNameKanji: mimamoriInfo.last_name_kanji,
    firstNameKanji: mimamoriInfo.first_name_kanji,
    lastNameKana: mimamoriInfo.last_name_kana,
    firtNameKana: mimamoriInfo.first_name_kana,
    serviceStatus: mimamoriInfo.service_status,
    mimamoriStatus: mimamoriInfo.mimamori_status,
    contractDate: mimamoriInfo.contract_date,
    serviceStartDate: mimamoriInfo.service_start_date,
  };
};
export const convertAnalysisResultList = (result) => {
  return {
    analysisResultId: result.analysis_result_id,
    analysisDate: result.analysis_date,
    analysisResultCode: result.analysis_result_code,
    detectionResult: result.detection_result,
    confirmStatus: result.confirm_status,
    alertFlag: result.alert_flg,
    pauseStartDate: result.pause_start_date,
    pauseEndDate: result.pause_end_date,
    pauseStart: !!result.pause_start_date,
  };
};

export const convertServicePauseCalendar = (calendar) => {
  return {
    calendarId: calendar.calendar_id,
    pauseStartDate: calendar.pause_start_date,
    pauseEndDate:
      calendar.pause_end_date !==
      `${FALSY_VALUE.YEAR}-${FALSY_VALUE.MONTH}-${FALSY_VALUE.DAY}`
        ? calendar.pause_end_date
        : null,
    reasonType: calendar.reason_type,
    createPerson: calendar.create_person,
  };
};

export const convertNotificationInformation = (notification) => {
  return {
    notificationInformation: notification.notification_information_id,
    notificationDisplayDate: notification.notification_display_date,
    notificationDetail: notification.notification_detail,
  };
};

export const convertInquiryInformation = (information) => {
  return {
    inquiryName: information.inquiry_name,
    inquiryPhoneNumber1: information.inquiry_phone_number1,
    inquiryPhoneNumber2: information.inquiry_phone_number2,
    inquiryPhoneNumber3: information.inquiry_phone_number3,
    inquiryReceptionTime: information.inquiry_reception_time,
    inquiryNotices: information.inquiry_notices,
  };
};

export const convertAlertInfo = (alertInfo) => {
  return {
    analysisResultId: alertInfo?.analysis_result_id,
  };
};

export const convertDataHome = (dataHome) => {
  return {
    mimamoriTargetPersonId: dataHome.mimamori_target_person_id,
    currentStatus: dataHome.current_status,
    currentStatusDate: dataHome.current_status_date,
    relativesAccountInfo: convertRelativesAccountInfo(
      dataHome.relatives_account_info
    ),
    mimamoriInfo: convertMimamoriInfo(dataHome.mimamori_info),
    analysisResultList: dataHome.analysis_result_list.map((analysisResult) =>
      convertAnalysisResultList(analysisResult)
    ),
    servicePauseCalendar: convertServicePauseCalendar(
      dataHome.service_pause_calendar
    ),
    notificationInformationList: dataHome.notification_information_list.map(
      (notification) => convertNotificationInformation(notification)
    ),
    inquiryAdminInformation: dataHome.inquiry_admin_information
      .map((information) => convertInquiryInformation(information))
      // NOTE: #60542 Use the first item only
      .slice(0, 1)
      .pop(),
    inquiryEstateInformation: dataHome.inquiry_estate_information
      .map((information) => convertInquiryInformation(information))
      // NOTE: #60542 Use the first item only
      .slice(0, 1)
      .pop(),
    alertInfo: convertAlertInfo(dataHome.alert_info),
  };
};

export const convertHomeChangeRouter = (dataHome, fromLogin) => {
  switch (dataHome.serviceStatus) {
    case 2:
      return dataHome.relativesEmail
        ? "ProfileCreateComponent"
        : "MailRegistrationComponent";
    case 3:
    case 4:
      return fromLogin ? "HomeComponent" : null;
    default:
      return fromLogin ? "HomeComponent" : null;
  }
};
