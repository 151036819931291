<template>
  <router-view />
</template>

<script>
import { mapState } from "vuex";
import UserService from "@/services/user.service";
export default {
  name: "ProfileComponent",
  computed: {
    ...mapState({
      profileInfo: (state) => state.settings.profileInfo,
      relativeInfo: (state) => state.settings.relativeInfo,
      mimamoriTargetPersonId: (state) =>
        state.global?.globalData?.mimamoriTargetPersonId,
    }),
  },
  data: () => ({
    userService: new UserService(),
  }),
  mounted() {
    !this.mimamoriTargetPersonId &&
      this.$store.dispatch("global/updateLoadingStatus", true);
    if (this.mimamoriTargetPersonId && !this.profileInfo) {
      this.getProfileInfo(this.mimamoriTargetPersonId);
    }
  },
  watch: {
    mimamoriTargetPersonId: function (val) {
      if (val && !this.profileInfo) this.getProfileInfo(val);
    },
  },
  methods: {
    async getProfileInfo(id) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      await this.userService.getProfileInfo(id);
      await this.userService.getRalativeInfo(id);
      this.$store.dispatch("global/updateLoadingStatus", false);
    },
  },
};
</script>
<style scoped lang="scss"></style>
