<template>
  <div class="StepsComponent">
    <ul class="progressbar">
      <li
        :class="{
          'active-step': active >= index + 1,
          'active-step-small': active > index + 1,
        }"
        v-for="(item, index) in steps"
        :key="index"
      >
        <p class="label">{{ item.label }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "StepsComponent",
  props: {
    active: {
      type: Number,
      default: 1,
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.StepsComponent {
  position: sticky;
  .progressbar {
    margin-bottom: 30px;
    counter-reset: step;
    position: relative;
    .label {
      white-space: pre-wrap;
    }
    li {
      list-style-type: none;
      text-transform: uppercase;
      font-size: 9px;
      float: left;
      text-align: center;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      width: 176px;
      color: #7c8185;
      &:before {
        content: counter(step);
        counter-increment: step;
        width: 30px;
        line-height: 30px;
        height: 30px;
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        background: #d0d9df;
        border-radius: 30px;
        margin: 5px auto 10px auto;
        z-index: 10;
      }
      &:after {
        content: "";
        width: 90%;
        height: 2px;
        background: #d0d9df;
        position: absolute;
        left: -50%;
        top: 20px;
        z-index: -1;
        margin-left: 10px;
        margin-right: 10px;
      }
      &:first-child:after {
        content: none;
      }
    }
    .active-step {
      color: #000;
      &:before {
        content: counter(step);
        counter-increment: step;
        width: 40px;
        height: 40px;
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        background: #8baac7;
        border-radius: 30px;
        margin: 0 auto 5px auto;
        z-index: 10;
        padding: 5px;
      }
      &:after {
        background: #8baac7;
      }
      &-small {
        color: #7c8185;
        &:before {
          content: counter(step);
          counter-increment: step;
          width: 30px;
          line-height: 30px;
          height: 30px;
          display: block;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          background: #8baac7;
          border-radius: 30px;
          margin: 5px auto 10px auto;
          padding: 0px;
          z-index: 10;
        }
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .StepsComponent {
    width: 100%;
    text-align: center;
    .progressbar {
      width: 100%;
      li {
        list-style-type: none;
        text-transform: uppercase;
        font-size: 9px;
        float: left;
        text-align: center;
        position: relative;
        font-size: 14px;
        font-weight: 500;
        width: calc(100% / 3);
        color: #7c8185;
        p {
          display: none;
        }
        &:before {
          content: counter(step);
          counter-increment: step;
          width: 20px;
          line-height: 20px;
          height: 20px;
          display: block;
          font-size: 10px;
          font-weight: 500;
          color: #fff;
          background: #d0d9df;
          border-radius: 30px;
          margin: 10px auto;
          z-index: 10;
        }
        &:after {
          content: "";
          width: 90%;
          height: 2px;
          background: #d0d9df;
          position: absolute;
          left: -50%;
          top: 20px;
          z-index: -1;
          margin-left: 10px;
          margin-right: 10px;
        }
        &:first-child:after {
          content: none;
        }
      }
      .active-step {
        color: #000;
        &:before {
          content: counter(step);
          counter-increment: step;
          width: 25px;
          height: 25px;
          display: block;
          font-size: 10px;
          color: #fff;
          background: #8baac7;
          border-radius: 20px;
          margin: 7px auto;
          z-index: 10;
          padding: 0 1px 0 2px;
          line-height: 25px;
        }
        &:after {
          background: #8baac7;
        }
        &-small {
          color: #7c8185;
          &:before {
            content: counter(step);
            counter-increment: step;
            width: 20px;
            line-height: 20px;
            height: 20px;
            display: block;
            font-size: 10px;
            font-weight: 500;
            border-radius: 30px;
            margin: 10px auto;
            z-index: 10;
          }
        }
      }
    }
  }
}
</style>
