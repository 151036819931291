<template>
  <div class="ErrorDetailComponent">
    <span class="icon-ico_ijyo2">
      <span class="path1"></span><span class="path2"></span>
    </span>
    <span class="content">{{ errorDetail }}</span>
  </div>
</template>

<script>
export default {
  name: "ErrorDetailComponent",
  props: {
    errorDetail: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.ErrorDetailComponent {
  color: #c51f1f;
  font-size: 14px;
  text-align: center;
  margin: 15px;
  .icon-ico_ijyo2 {
    .path1 {
      &::before {
        color: #c51f1f;
        font-size: 12px;
      }
    }
    .path2 {
      &::before {
        font-size: 12px;
      }
    }
  }
  .content {
    white-space: pre-wrap;
    margin-left: 5px;
  }
}
</style>
