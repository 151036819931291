import { formatPostCode } from "./formatPostCode";
import { inputFilter } from "./inputFilter";
import { formatDateTimeJanpanese } from "./generateDateTimeJapansese";
import { tableRange } from "./tableRange";
export default {
  install: (app) => {
    app
      .directive("formatDateTimeJanpanese", formatDateTimeJanpanese)
      .directive("formatPostCode", formatPostCode)
      .directive("inputFilter", inputFilter)
      .directive("tableRange", tableRange);
  },
};
