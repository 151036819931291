import AnalysisLabelComponent from "@/components/common/AnalysisLabelComponent";
import AnalysisStatusComponent from "@/components/common/AnalysisStatusComponent";
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import CardComponent from "@/components/common/CardComponent.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import DatePickerComponent from "@/components/common/DatePickerComponent.vue";
import DateTimeInputGroupComponent from "@/components/common/DateTimeInputGroupComponent";
import ErrorDetailComponent from "@/components/common/ErrorDetailComponent";
import FooterComponent from "@/components/common/FooterComponent.vue";
import GroupContentComponent from "@/components/common/GroupContentComponent.vue";
import HeaderComponent from "@/components/common/HeaderComponent.vue";
import HelpPanelPaginationComponent from "@/components/common/HelpPanelPaginationComponent.vue";
import IconCheckComponent from "@/components/common/IconCheckComponent.vue";
import InputComponent from "@/components/common/InputComponent.vue";
import InquiryBannerComponent from "@/components/common/InquiryBannerComponent.vue";
import LinkComponent from "@/components/common/LinkComponent";
import LoaderComponent from "@/components/common/LoaderComponent";
import PaginationComponent from "@/components/common/PaginationComponent.vue";
import PanelComponent from "@/components/common/PanelComponent";
import PanelsComponent from "@/components/common/PanelsComponent";
import SelectComponent from "@/components/common/SelectComponent.vue";
import StatusChipComponent from "@/components/common/StatusChipComponent.vue";
import StepsComponent from "@/components/common/StepsComponent";
import TableCellComponent from "@/components/common/TableCellComponent";
import TableHeaderComponent from "@/components/common/TableHeaderComponent";
import TableRowComponent from "@/components/common/TableRowComponent";
import TelephoneInputComponent from "@/components/common/TelephoneInputComponent.vue";
import TextAreaComponent from "@/components/common/TextAreaComponent";
import TitleComponent from "@/components/common/TitleComponent.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import ZipCodeSearchComponent from "@/components/address/ZipCodeSearchComponent";

export default {
  install: (app) => {
    app
      .component("AnalysisLabelComponent", AnalysisLabelComponent)
      .component("AnalysisStatusComponent", AnalysisStatusComponent)
      .component("ButtonComponent", ButtonComponent)
      .component("CardComponent", CardComponent)
      .component("ConfirmDialog", ConfirmDialog)
      .component("DatePickerComponent", DatePickerComponent)
      .component("DateTimeInputGroupComponent", DateTimeInputGroupComponent)
      .component("ErrorDetailComponent", ErrorDetailComponent)
      .component("FooterComponent", FooterComponent)
      .component("GroupContentComponent", GroupContentComponent)
      .component("HeaderComponent", HeaderComponent)
      .component("HelpPanelPaginationComponent", HelpPanelPaginationComponent)
      .component("IconCheckComponent", IconCheckComponent)
      .component("InputComponent", InputComponent)
      .component("InquiryBannerComponent", InquiryBannerComponent)
      .component("LinkComponent", LinkComponent)
      .component("LoaderComponent", LoaderComponent)
      .component("PaginationComponent", PaginationComponent)
      .component("PanelComponent", PanelComponent)
      .component("PanelsComponent", PanelsComponent)
      .component("SelectComponent", SelectComponent)
      .component("StatusChipComponent", StatusChipComponent)
      .component("StepsComponent", StepsComponent)
      .component("TableCellComponent", TableCellComponent)
      .component("TableHeaderComponent", TableHeaderComponent)
      .component("TableRowComponent", TableRowComponent)
      .component("TelephoneInputComponent", TelephoneInputComponent)
      .component("TextAreaComponent", TextAreaComponent)
      .component("TitleComponent", TitleComponent)
      .component("WarningDialog", WarningDialog)
      .component("ZipCodeSearchComponent", ZipCodeSearchComponent);
  },
};
