<template>
  <div class="AutoCallEditComponent">
    <TitleComponent class="AutoCallEditComponent-title">
      <template v-slot:icon
        ><img
          :src="require('@/assets/images/qms/t_ico_profile.svg')"
          class="image"
      /></template>
      <template v-slot:content> プロフィール情報 </template>
    </TitleComponent>
    <Form @submit="submit" :validation-schema="schema" v-slot="{ meta }">
      <CardComponent
        class="main"
        :width="'960px'"
        :height="'auto'"
        v-if="profileInfo"
      >
        <TitleComponent :small="true">
          <template v-slot:icon><span class="icon-t_ico_service" /></template>
          <template v-slot:content> みまもり対象者 </template>
        </TitleComponent>
        <div class="main_userInfo">
          <div class="flex flex-column-rp">
            <div class="flex fullName">
              <span>{{ profileInfo.lastNameKanji }}</span
              ><span>{{ profileInfo.firstNameKanji }}</span>
            </div>
            <div class="flex fullName fullName-small">
              <span>{{ profileInfo.lastNameKana }}</span
              ><span>{{ profileInfo.firstNameKana }}</span
              >様
            </div>
          </div>
          <div class="flex address flex-column-rp" v-if="profileInfo.zipCode">
            <span>〒{{ profileInfo.zipCode }}</span
            ><span>{{ profileInfo.address }}</span>
          </div>
        </div>
        <div class="group">
          <div class="group_title">オートコールの連絡先</div>
          <GroupContentComponent class="group-big" :required="true">
            <template v-slot:title> 連絡先電話番号 </template>
            <template v-slot:content>
              <div class="flex flex-column-rp">
                <div class="telephone">
                  <InputComponent
                    name="phoneNumber"
                    type="telephone"
                    inputFilter="number"
                    maxlength="11"
                    :small="true"
                    :defaultValue="profileInfo.parsedPhoneNumber"
                    placeholder="電話番号を入力"
                    @trigle-change="errorDetail = null"
                  />
                </div>
                <span class="telephone-memo"
                  >※半角数字、ハイフンなしで入力してください。</span
                >
              </div>
            </template>
          </GroupContentComponent>
          <!-- #59427 Hide 特記事項 field -->
          <GroupContentComponent
            class="group-oversize"
            :isTitleTop="true"
            v-if="false"
          >
            <template v-slot:title> 特記事項 </template>
            <template v-slot:content>
              <div class="notices">
                <TextAreaComponent
                  name="notices"
                  :defaultValue="
                    profileInfo.notices !== 'ー' ? profileInfo.notices : ''
                  "
                  placeholder="特記事項ありましたらご入力ください"
                  :rows="2"
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
          <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
          <div class="action-group">
            <ButtonComponent
              width="150px"
              height="50px"
              type="outline"
              color="#8d9caa"
              buttonType="Button"
              @click="cancel"
              >キャンセル</ButtonComponent
            >
            <ButtonComponent
              buttonType="submit"
              width="150px"
              height="50px"
              color="#8d9caa"
              class="btn-submit"
              :disabled="!meta.valid"
              >登録</ButtonComponent
            >
          </div>
        </div>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import { mapState } from "vuex";
import UserService from "@/services/user.service";
import { STATUS } from "@/globals/enums";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import WarningDialogService from "@/services/dialog/warning-dialog.service";
import { updateAutoCallSchema } from "@/validations/schemas/updateProfileInfoSchema";

export default {
  name: "AutoCallEditComponent",
  components: {
    Form,
  },
  mounted() {
    this.isCreated = true;
  },
  data: () => ({
    userService: new UserService(),
    confirmDialogService: new ConfirmDialogService(),
    warningDialogService: new WarningDialogService(),
    schema: updateAutoCallSchema,
    errorDetail: null,
  }),
  computed: {
    ...mapState({
      profileInfo: (state) => state.settings.profileInfo,
      mimamoriTargetPersonId: (state) =>
        state.global?.globalData?.mimamoriTargetPersonId,
    }),
  },
  methods: {
    cancel() {
      this.$router.push({ name: "ProfileDetailComponent" });
    },
    async submit(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.userService.updateAutoCallTelephone(
        this.mimamoriTargetPersonId,
        data
      );
      await this.userService.getProfileInfo(this.mimamoriTargetPersonId);
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result.status === STATUS.SUCCESS) {
        this.confirmDialogService.openConfirmDialog(
          "プロフィール情報を編集しました。",
          null,
          () => {
            this.$router.push({ name: "ProfileDetailComponent" });
            this.showAutoCall();
          }
        );
      }
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
    showAutoCall() {
      this.warningDialogService.openWarningAutoCallDialog(
        "オートコールの通知先が変更されました。",
        "有効性確認を実施しますか？",
        null,
        async () => await this.testAutoCall(),
        "確認しない",
        "確認する",
        "autoCall",
        true
      );
    },
    async testAutoCall() {
      const result = await this.userService.checkPhoneNumberMimamori(
        this.mimamoriTargetPersonId
      );
      if (result.status === STATUS.SUCCESS) {
        this.$vfm.hideAll();
        this.confirmDialogService.openConfirmDialog(
          "現在、コール中です。",
          "着信がありましたら、電話をお取りください。"
        );
        return null;
      }
      if (result.status === STATUS.ERROR) {
        return result;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.AutoCallEditComponent {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: var(--default-distance-card);
  &-title {
    width: 960px;
    margin: 0 auto;
    display: flex;
    color: #000000 !important;
    .icon-ico_user::before {
      color: #000000;
    }
  }
  .main {
    padding: 30px 40px;
    .flex {
      display: flex;
      align-items: center;
    }
    &_title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      font-weight: 500;
    }
    &_userInfo {
      padding: 13px 0 30px;
      margin-left: 21px;
      display: flex;
      flex-direction: column;
      gap: 7px;
      .fullName {
        font-size: 20px;
        font-weight: 500;
        gap: 13px;
        margin-right: 12px;
        &-small {
          font-size: 14px;
          gap: 10px;
        }
      }
      .address {
        gap: 19px;
      }
    }
    .group {
      &_title {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
        border-bottom: solid 2px #d0d9df;
      }
      .telephone {
        width: 240px;
        &-memo {
          margin-left: 50px;
          font-size: 12px;
          font-weight: normal;
          color: #7c8185;
        }
      }
      .status {
        margin-left: auto;
        font-size: 12px;
        width: 99px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .orange {
        color: #eba447;
        border: solid 1px #eba447;
      }
      .blue {
        color: #328dc2;
        border: solid 1px #328dc2;
      }
      &-big {
        height: 100px;
      }
      &-normal {
        height: 60px;
      }
      &-oversize {
        padding: 35px 10px;
        .notices {
          width: 600px;
          height: 60px;
        }
      }
      .action-group {
        margin-top: 30px;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
      }
    }
    &_bottom {
      margin: 30px 0 40px;
    }
    .fullName-small {
      gap: 10px;
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .AutoCallEditComponent {
    height: 100%;
    display: block;
    margin: 0;
    &-title {
      width: 300px;
      margin: 0 20px;
    }
    .main {
      width: 100% !important;
      padding: 30px 20px;
      &_userInfo {
        margin: 0 20px;
      }
      .flex-column-rp {
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
      }
      .group-big {
        height: 167px;
        display: flex;
        flex-direction: column;
        gap: 11px;
        padding: 20px 10px;
        .telephone {
          width: 100%;
          &-memo {
            margin: 10px 0 0;
          }
        }
      }
      .group-oversize {
        height: 150px;
        display: flex;
        flex-direction: column;
        gap: 11px;
        padding: 20px 10px;
      }
      .group {
        .action-group {
          text-align: center;
        }
      }
      .action-group {
        flex-direction: column-reverse;
        gap: 0 !important;
        .btn-submit {
          margin-bottom: 20px;
        }
      }
    }
    &-title {
      width: 300px;
      margin: 0 20px;
    }
  }
}
</style>
