import * as Yup from "yup";
import { ERROR_MESSAGES } from "@/globals/error-messages";
import {
  REG_8_CHARACTER,
  MIN_8_CHARACTER,
  CHECK_EMAIL,
  CHECK_PASSWORD,
  START_RANGE_TIME,
  CHECK_FULL_WIDTH_KANA,
} from "@/globals/enums";
import {
  isDatetimeisOlderThanCurrentTime,
  isDatetimeisOlderAndEqualWithTime,
  isStartTimeOverThanEndTime,
  isTimeInList,
  isTimeNotSelect,
  isFullWidth,
  isHalfWidthOrFullWidth,
} from "@/directives/validations";

import { testphoneNumber } from "@/utils/utils";

export const validateStringRequired = Yup.string().required(
  ERROR_MESSAGES.REQUIRED
);

export const validatePassword = Yup.string()
  .matches(CHECK_PASSWORD, ERROR_MESSAGES.INVALID_PASSWORD)
  .min(MIN_8_CHARACTER, ERROR_MESSAGES.PLEASE_INPUT_OVER_8_NUMBER)
  .max(256, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 256))
  .required(ERROR_MESSAGES.REQUIRED);

export const validateConfirmPassword = (passwordInput) =>
  Yup.string()
    .oneOf(
      [Yup.ref(passwordInput), null],
      ERROR_MESSAGES.SAME_PASSWORD_REQUIRED
    )
    .required(ERROR_MESSAGES.REQUIRED);

export const validateEmail = Yup.string()
  .min(MIN_8_CHARACTER, ERROR_MESSAGES.PLEASE_INPUT_OVER_8_NUMBER)
  .max(100, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 100))
  .matches(CHECK_EMAIL, ERROR_MESSAGES.INVALID_EMAIL)
  .required(ERROR_MESSAGES.REQUIRED);

export const validateConfirmEmail = (emailInput) =>
  Yup.string().oneOf(
    [Yup.ref(emailInput), null],
    ERROR_MESSAGES.SAME_EMAIL_REQUIRED
  );

export const validateName = {
  firstname: () =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(10, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 10))
      .test(
        "isFullWidth",
        ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
        (value) => isFullWidth(value)
      ),
  lastname: () =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(10, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 10))
      .test(
        "isFullWidth",
        ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
        (value) => isFullWidth(value)
      ),
};

export const validateNameRequiredKana = {
  firstname: () =>
    Yup.string()
      .max(20, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 20))
      .matches(
        CHECK_FULL_WIDTH_KANA,
        ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH_KANA
      )
      .required(ERROR_MESSAGES.REQUIRED),
  lastname: () =>
    Yup.string()
      .max(20, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 20))
      .matches(
        CHECK_FULL_WIDTH_KANA,
        ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH_KANA
      )
      .required(ERROR_MESSAGES.REQUIRED),
};

export const validateZipCode = Yup.string()
  .required(ERROR_MESSAGES.REQUIRED)
  .max(7, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 7))
  .min(7, ERROR_MESSAGES.PLEASE_INPUT_OVER_N_NUMBER.replace("N", 7))
  .test(
    "verificationCode",
    ERROR_MESSAGES.PLEASE_INPUT_VALID_HALF_WIDTH_NUMBER,
    (value) => /^-?\d+$/.test(value)
  );

export const validateAddressCity = Yup.string()
  .required(ERROR_MESSAGES.REQUIRED)
  .max(20, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 20))
  .test("isFullWidth", ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH, (value) =>
    isFullWidth(value)
  );

export const validateRelationship = Yup.string()
  .max(10, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 10))
  .test("isFullWidth", ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH, (value) =>
    isFullWidth(value)
  );

export const validateNotices = Yup.string()
  .max(500, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 500))
  .test(
    "isHaftWidthOrFullWidth",
    ERROR_MESSAGES.PLEASE_INPUT_HAFT_WIDTH_OR_FULL_WIDTH,
    (value) => {
      return isHalfWidthOrFullWidth(value);
    }
  );

export const validateTelephoneGroup = {
  firstPathTelephone: (firstPath, secondPath, lastPath) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(6, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 6))
      .test(
        "minLengthPhone",
        ERROR_MESSAGES.PLEASE_INPUT_OVER_N_NUMBER.replace("N", 10),
        (value, ctx) => {
          if (
            ctx.parent[firstPath]?.length === 0 ||
            ctx.parent[secondPath]?.length === 0 ||
            ctx.parent[lastPath]?.length === 0
          ) {
            return true;
          }
          const telephoneLength =
            (ctx.parent[firstPath]?.length || 0) +
            (ctx.parent[secondPath]?.length || 0) +
            (ctx.parent[lastPath]?.length || 0);
          return telephoneLength >= 10;
        }
      )
      .test(
        "maxLengthPhone",
        ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 11),
        (value, ctx) => {
          if (
            ctx.parent[firstPath]?.length === 0 ||
            ctx.parent[secondPath]?.length === 0 ||
            ctx.parent[lastPath]?.length === 0
          ) {
            return true;
          }
          const telephoneLength =
            (ctx.parent[firstPath]?.length || 0) +
            (ctx.parent[secondPath]?.length || 0) +
            (ctx.parent[lastPath]?.length || 0);
          return telephoneLength <= 11;
        }
      )
      .test("telephone", ERROR_MESSAGES.INVALID_TELEPHONE, (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        return value.charAt(0) === "0";
      }),
  secondPathTelephone: (firstPath, secondPath, lastPath) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(4, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 4))
      .test("minLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        const telephoneLength =
          (ctx.parent[firstPath]?.length || 0) +
          (ctx.parent[secondPath]?.length || 0) +
          (ctx.parent[lastPath]?.length || 0);
        return telephoneLength >= 10;
      })
      .test("maxLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        const telephoneLength =
          (ctx.parent[firstPath]?.length || 0) +
          (ctx.parent[secondPath]?.length || 0) +
          (ctx.parent[lastPath]?.length || 0);
        return telephoneLength <= 11;
      }),
  lastPathTelephone: (firstPath, secondPath, lastPath) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(4, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 4))
      .test("minLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        const telephoneLength =
          (ctx.parent[firstPath]?.length || 0) +
          (ctx.parent[secondPath]?.length || 0) +
          (ctx.parent[lastPath]?.length || 0);
        return telephoneLength >= 10;
      })
      .test("maxLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        const telephoneLength =
          (ctx.parent[firstPath]?.length || 0) +
          (ctx.parent[secondPath]?.length || 0) +
          (ctx.parent[lastPath]?.length || 0);
        return telephoneLength <= 11;
      }),
};

export const validateTelephoneGroupSelect = {
  firstPathTelephone: (firstPath, secondPath, lastPath) =>
    Yup.string()
      .required(ERROR_MESSAGES.PLEASE_SELECT)
      .test(
        "INVALID_TELEPHONE",
        ERROR_MESSAGES.INVALID_TELEPHONE,
        (value, ctx) => {
          if (
            ctx.parent[firstPath]?.length === 0 ||
            ctx.parent[secondPath]?.length === 0 ||
            ctx.parent[lastPath]?.length === 0
          ) {
            return true;
          }
          return testphoneNumber(
            ctx.parent[firstPath] +
              ctx.parent[secondPath] +
              ctx.parent[lastPath]
          );
        }
      ),
  secondPathTelephone: (firstPath, secondPath, lastPath) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(4, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 4))
      .test("minLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        return testphoneNumber(
          ctx.parent[firstPath] + ctx.parent[secondPath] + ctx.parent[lastPath]
        );
      }),
  lastPathTelephone: (firstPath, secondPath, lastPath) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(4, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 4))
      .test("minLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        return testphoneNumber(
          ctx.parent[firstPath] + ctx.parent[secondPath] + ctx.parent[lastPath]
        );
      }),
};

export const validateTelephone = Yup.string()
  .min(11, ERROR_MESSAGES.PLEASE_INPUT_N_NUMBER.replace("N", 11))
  .max(11, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 11))
  .test("telephone", ERROR_MESSAGES.INVALID_TELEPHONE, (value) => {
    return testphoneNumber(value);
  })
  .required(ERROR_MESSAGES.REQUIRED);

export const validateTelephoneNormal = Yup.string()
  .max(11, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 11))
  .test("telephone", ERROR_MESSAGES.INVALID_TELEPHONE, (value) => {
    return testphoneNumber(value);
  })
  .required(ERROR_MESSAGES.REQUIRED);

// code is number and length === 6
export const validateVerificationCode = Yup.string()
  .test(
    "verificationCode",
    ERROR_MESSAGES.OTP_6_NUMBER_REQUIRED,
    (value) => /^-?\d+$/.test(value) && value.length === 6
  )
  .required(ERROR_MESSAGES.REQUIRED);

export const validateSelectNumber = (defaultValue) =>
  Yup.number().test(
    "validateSelectNumber",
    ERROR_MESSAGES.PLEASE_SELECT,
    (val) => {
      return val !== parseInt(defaultValue);
    }
  );

export const validateSelectString = (defaultValue) => {
  return Yup.string().test(
    "validateSelectNumber",
    ERROR_MESSAGES.PLEASE_SELECT,
    (val) => val != defaultValue
  );
};

export const validateMailAddress = {
  mailAddress1: (another1, another2) =>
    Yup.string()
      /**
       * NOTE:
       * Only the first item "mailAddress1" is required.
       */
      .required(ERROR_MESSAGES.REQUIRED)
      /**
       * NOTE:
       * How can you use string.min *without* the field being required?
       * see. [https://github.com/jquense/yup/issues/1267]
       */
      .matches(REG_8_CHARACTER, {
        excludeEmptyString: true,
        message: ERROR_MESSAGES.PLEASE_INPUT_OVER_8_NUMBER,
      })
      .max(100, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 100))
      .matches(CHECK_EMAIL, ERROR_MESSAGES.INVALID_EMAIL)
      .test("sameEmail", ERROR_MESSAGES.SAME_EMAIL_NOT_VALID, (val, ctx) => {
        if (val?.length === 0) return true;
        return val !== ctx.parent[another1] && val !== ctx.parent[another2];
      }),
  mailAddress2: (another1, another2) =>
    Yup.string()
      /**
       * NOTE:
       * How can you use string.min *without* the field being required?
       * see. [https://github.com/jquense/yup/issues/1267]
       */
      .matches(REG_8_CHARACTER, {
        excludeEmptyString: true,
        message: ERROR_MESSAGES.PLEASE_INPUT_OVER_8_NUMBER,
      })
      .max(100, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 100))
      .test("sameEmail", ERROR_MESSAGES.SAME_EMAIL_NOT_VALID, (val, ctx) => {
        if (val?.length === 0) return true;
        return val !== ctx.parent[another1] && val !== ctx.parent[another2];
      })
      .test("emailMin", ERROR_MESSAGES.INVALID_EMAIL, (val) => {
        if (val?.length === 0) return true;
        return CHECK_EMAIL.test(val);
      }),
  mailAddress3: (another1, another2) =>
    Yup.string()
      /**
       * NOTE:
       * How can you use string.min *without* the field being required?
       * see. [https://github.com/jquense/yup/issues/1267]
       */
      .matches(REG_8_CHARACTER, {
        excludeEmptyString: true,
        message: ERROR_MESSAGES.PLEASE_INPUT_OVER_8_NUMBER,
      })
      .max(100, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 100))
      .test("sameEmail", ERROR_MESSAGES.SAME_EMAIL_NOT_VALID, (val, ctx) => {
        if (val?.length === 0) return true;
        return val !== ctx.parent[another1] && val !== ctx.parent[another2];
      })
      .test("emailMin", ERROR_MESSAGES.INVALID_EMAIL, (val) => {
        if (val?.length === 0) return true;
        return CHECK_EMAIL.test(val);
      }),
};

export const validateSearchStartTimeWithEndTime = (
  startYearInput,
  startMonthInput,
  startDateInput,
  endYearInput,
  endMonthInput,
  endDateInput,
  errorMessage,
  isDatetimeisOlderThanCurrentTimeErrorMessage,
  isDatetimeSameCurrentTimeErrorMessage
) =>
  Yup.string()
    .test("testDateTime", errorMessage || "", (val, ctx) => {
      return !isTimeInList(
        ctx.parent[startYearInput],
        ctx.parent[startMonthInput],
        ctx.parent[startDateInput]
      );
    })
    .test(
      "testDateTime",
      isDatetimeisOlderThanCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isDatetimeisOlderAndEqualWithTime(
          ctx.parent[startYearInput],
          ctx.parent[startMonthInput],
          ctx.parent[startDateInput],
          new Date(
            START_RANGE_TIME.YEAR,
            START_RANGE_TIME.MONTH,
            START_RANGE_TIME.DAY,
            0,
            0,
            0,
            0
          )
        )
    )
    .test(
      "testDateTime",
      isDatetimeSameCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isStartTimeOverThanEndTime(
          {
            year: ctx.parent[startYearInput],
            month: ctx.parent[startMonthInput],
            date: ctx.parent[startDateInput],
          },
          {
            year: ctx.parent[endYearInput],
            month: ctx.parent[endMonthInput],
            date: ctx.parent[endDateInput],
          }
        )
    );

export const validateSearchEndTimeWithStartTime = (
  startYearInput,
  startMonthInput,
  startDateInput,
  endYearInput,
  endMonthInput,
  endDateInput,
  errorMessage,
  pastTimeNotValid
) =>
  Yup.string()
    .test("testDateTime", errorMessage || "", (val, ctx) => {
      return !isTimeInList(
        ctx.parent[endYearInput],
        ctx.parent[endMonthInput],
        ctx.parent[endDateInput]
      );
    })
    .test("testDateTime", pastTimeNotValid || "", (val, ctx) =>
      isStartTimeOverThanEndTime(
        {
          year: ctx.parent[startYearInput],
          month: ctx.parent[startMonthInput],
          date: ctx.parent[startDateInput],
        },
        {
          year: ctx.parent[endYearInput],
          month: ctx.parent[endMonthInput],
          date: ctx.parent[endDateInput],
        }
      )
    )
    .test("testDateTime", pastTimeNotValid || "", (val, ctx) =>
      isDatetimeisOlderAndEqualWithTime(
        ctx.parent[endYearInput],
        ctx.parent[endMonthInput],
        ctx.parent[endDateInput],
        new Date(
          START_RANGE_TIME.YEAR,
          START_RANGE_TIME.MONTH,
          START_RANGE_TIME.DAY,
          0,
          0,
          0,
          0
        )
      )
    );

export const validateStartDateTimewithEndTime = (
  startYearInput,
  startMonthInput,
  startDateInput,
  endYearInput,
  endMonthInput,
  endDateInput,
  isDatetimeisOlderThanCurrentTimeErrorMessage,
  isDatetimeSameCurrentTimeErrorMessage,
  isTimeInHoliDateErrorMessage,
  isPastTimeErrorMessage,
  isTimeNotSelectErrorMessage
) =>
  Yup.string()
    .test(
      "testDateTime",
      isDatetimeisOlderThanCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isDatetimeisOlderThanCurrentTime(
          ctx.parent[startYearInput],
          ctx.parent[startMonthInput],
          ctx.parent[startDateInput]
        )
    )
    .test(
      "testDateTime",
      isDatetimeSameCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isStartTimeOverThanEndTime(
          {
            year: ctx.parent[startYearInput],
            month: ctx.parent[startMonthInput],
            date: ctx.parent[startDateInput],
          },
          {
            year: ctx.parent[endYearInput],
            month: ctx.parent[endMonthInput],
            date: ctx.parent[endDateInput],
          }
        )
    )
    .test("testDateTime", isTimeInHoliDateErrorMessage || "", (val, ctx) => {
      return !isTimeInList(
        ctx.parent[startYearInput],
        ctx.parent[startMonthInput],
        ctx.parent[startDateInput]
      );
    })
    .test("testDateTime", isPastTimeErrorMessage || "", (val, ctx) =>
      isDatetimeisOlderThanCurrentTime(
        ctx.parent[startYearInput],
        ctx.parent[startMonthInput],
        ctx.parent[startDateInput]
      )
    )
    .test("testDateTime", isTimeNotSelectErrorMessage || "", (val, ctx) => {
      return !isTimeNotSelect(
        ctx.parent[startYearInput],
        ctx.parent[startMonthInput],
        ctx.parent[startDateInput]
      );
    });

export const validateEndDateTimewithStartDateTime = (
  startYearInput,
  startMonthInput,
  startDateInput,
  endYearInput,
  endMonthInput,
  endDateInput,
  isDatetimeisOlderThanCurrentTimeErrorMessage,
  isDatetimeSameCurrentTimeErrorMessage,
  isTimeInHoliDateErrorMessage,
  isPastTimeErrorMessage
) =>
  Yup.string()
    .test(
      "testDateTime",
      isDatetimeisOlderThanCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isDatetimeisOlderThanCurrentTime(
          ctx.parent[endYearInput],
          ctx.parent[endMonthInput],
          ctx.parent[endDateInput]
        )
    )
    .test(
      "testDateTime",
      isDatetimeSameCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isStartTimeOverThanEndTime(
          {
            year: ctx.parent[startYearInput],
            month: ctx.parent[startMonthInput],
            date: ctx.parent[startDateInput],
          },
          {
            year: ctx.parent[endYearInput],
            month: ctx.parent[endMonthInput],
            date: ctx.parent[endDateInput],
          }
        )
    )
    .test("testDateTime", isTimeInHoliDateErrorMessage || "", (val, ctx) => {
      return !isTimeInList(
        ctx.parent[endYearInput],
        ctx.parent[endMonthInput],
        ctx.parent[endDateInput]
      );
    })
    .test("testDateTime", isPastTimeErrorMessage || "", (val, ctx) =>
      isDatetimeisOlderThanCurrentTime(
        ctx.parent[endYearInput],
        ctx.parent[endMonthInput],
        ctx.parent[endDateInput]
      )
    );
