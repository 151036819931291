import {
  ABSENCE_REASON_TYPE,
  DATES,
  FALSY_VALUE,
  MONTHS,
  START_RANGE_TIME,
  VALIDATE_DATE_TIME_TYPE,
} from "@/globals/enums";
import dayjs from "dayjs";
const convertReasonType = (reasonType) => {
  const findingValue = ABSENCE_REASON_TYPE.find(
    (type) => type.VALUE === reasonType
  );
  return {
    value: findingValue?.VALUE,
    label: findingValue?.LABEL || "ー",
  };
};

export const absencesOptions = () => {
  return ABSENCE_REASON_TYPE.map((type) => ({
    value: type.VALUE,
    label: type.LABEL,
  }));
};

export const convertYearOptions = (startYear = new Date().getFullYear()) => {
  const endYear = new Date().getFullYear() + 5;
  const result = [
    {
      value: 0,
      label: "ー",
    },
  ];
  for (let index = endYear; index >= startYear; index--) {
    result.push({
      value: index,
      label: index,
    });
  }

  return result;
};

export const convertMonthOptions = () => {
  return MONTHS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertDateOptions = () => {
  return DATES.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const getToday = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0,
    0
  );
};

const getTomorow = () => {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate;
};

const getRange = (calenderId, startInitDate) => {
  const result = {
    startRange: {
      start: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
      end: new Date(new Date().getFullYear() + 5, 0, 1, 0, 0, 0, 0),
    },
    endRange: {
      start: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
      end: new Date(new Date().getFullYear() + 5, 0, 1, 0, 0, 0, 0),
    },
    startOptions: {
      years: convertYearOptions(),
      months: convertMonthOptions(),
      dates: convertDateOptions(),
    },
    endOptions: {
      years: convertYearOptions(),
      months: convertMonthOptions(),
      dates: convertDateOptions(),
    },
    disabledStartSelect: false,
    currentType: VALIDATE_DATE_TIME_TYPE.NOT_SETTING_YET,
  };
  return {
    ...result,
    ...(!calenderId && {
      startYear: getTomorow().getFullYear(),
      startMonth: getTomorow().getMonth() + 1,
      startDate: getTomorow().getDate(),
      endYear: 0,
      endMonth: 0,
      endDate: 0,
    }),
    ...(calenderId &&
      startInitDate &&
      startInitDate > getToday() && {
        startRange: {
          start: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
          end: new Date(new Date().getFullYear() + 5, 0, 1, 0, 0, 0, 0),
        },
        endRange: {
          start: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
          end: new Date(new Date().getFullYear() + 5, 0, 1, 0, 0, 0, 0),
        },
        startOptions: {
          years: convertYearOptions(),
          months: convertMonthOptions(),
          dates: convertDateOptions(),
        },
        endOptions: {
          years: convertYearOptions(),
          months: convertMonthOptions(),
          dates: convertDateOptions(),
        },
        currentType: VALIDATE_DATE_TIME_TYPE.START_DATE_IS_FUTURE,
      }),
    ...(calenderId &&
      startInitDate &&
      startInitDate <= getToday() && {
        startRange: {
          start: new Date(START_RANGE_TIME.YEAR, 0, 1, 0, 0, 0, 0),
          end: new Date(new Date().getFullYear() + 5, 0, 1, 0, 0, 0, 0),
        },
        endRange: {
          start: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
          end: new Date(new Date().getFullYear() + 5, 0, 1, 0, 0, 0, 0),
        },
        startOptions: {
          years: convertYearOptions(START_RANGE_TIME.YEAR),
          months: convertMonthOptions(),
          dates: convertDateOptions(),
        },
        endOptions: {
          years: convertYearOptions(),
          months: convertMonthOptions(),
          dates: convertDateOptions(),
        },
        disabledStartSelect: true,
        currentType: VALIDATE_DATE_TIME_TYPE.START_DATE_IS_PAST,
      }),
  };
};

const isYearInlist = (currentValue, list) => {
  const found = list.find(
    (element) => element.value == currentValue.getFullYear()
  );
  return !!found;
};

const findMinRange = (list) => {
  return list.find((element) => element.value !== 0);
};

export const convertAbsencePreviod = (data) => {
  const startInitDate = data?.pause_start_date
    ? new Date(data.pause_start_date)
    : null;
  const endInitDate =
    data?.pause_end_date &&
    data?.pause_end_date !==
      `${FALSY_VALUE.YEAR}-${FALSY_VALUE.MONTH}-${FALSY_VALUE.DAY}`
      ? new Date(data.pause_end_date)
      : null;

  const range = getRange(data?.calendar_id, startInitDate);

  const result = {
    calenderId: data.calendar_id,
    alreadySetting: !!data.calendar_id,
    ...(data.pause_start_date && {
      startYear: isYearInlist(startInitDate, range.startOptions.years)
        ? startInitDate.getFullYear()
        : findMinRange(range.startOptions.years).value,
      startMonth: startInitDate.getMonth() + 1,
      startDate: startInitDate.getDate(),
    }),
    startDateTime: data.pause_start_date || "ー",
    ...(endInitDate && {
      endYear: isYearInlist(endInitDate, range.endOptions.years)
        ? endInitDate.getFullYear()
        : findMinRange(range.endOptions.years).value,
      endMonth: endInitDate.getMonth() + 1,
      endDate: endInitDate.getDate(),
    }),
    endDateTime:
      data?.pause_end_date &&
      data?.pause_end_date !==
        `${FALSY_VALUE.YEAR}-${FALSY_VALUE.MONTH}-${FALSY_VALUE.DAY}`
        ? data?.pause_end_date
        : "ー",
    reasonType: data?.reason_type ? convertReasonType(data.reason_type) : 0,
    absencesOptions: absencesOptions(),
  };
  return {
    ...result,
    ...range,
  };
};

export const convertBodyPeriod = (period) => {
  const pauseStartDate =
    period.startYear && period.startMonth && period.startDate
      ? dayjs(
          `${period.startYear}-${period.startMonth}-${period.startDate}`
        ).format("YYYY-MM-DD")
      : null;
  const pauseEndDate =
    period.endYear && period.endMonth && period.endDate
      ? dayjs(`${period.endYear}-${period.endMonth}-${period.endDate}`).format(
          "YYYY-MM-DD"
        )
      : `${FALSY_VALUE.YEAR}-${FALSY_VALUE.MONTH}-${FALSY_VALUE.DAY}`;
  return {
    ...(!period?.disabledStartSelect && { pause_start_date: pauseStartDate }),
    pause_end_date: pauseEndDate,
    reason_type: period.absenceType,
  };
};
