import * as Yup from "yup";
import {
  validateName,
  validateNameRequiredKana,
  validateZipCode,
  validateSelectString,
  validateAddressCity,
  validateRelationship,
  validateNotices,
  validateMailAddress,
  validateTelephoneGroupSelect,
} from "../common";

export const createProfileSchema = Yup.object().shape({
  lastNameKanji: validateName.lastname(),
  firstNameKanji: validateName.firstname(),
  lastNameKana: validateNameRequiredKana.lastname(),
  firstNameKana: validateNameRequiredKana.lastname(),
  zipCode: validateZipCode,
  addressPrefecture: validateSelectString(0),
  addressCity: validateAddressCity,
  relationship: validateRelationship,
  notices: validateNotices,
});

export const createAlertEmailSchema = Yup.object().shape({
  mailAddress1: validateMailAddress.mailAddress1(
    "mailAddress2",
    "mailAddress3"
  ),
  mailAddress2: validateMailAddress.mailAddress2(
    "mailAddress1",
    "mailAddress3"
  ),
  mailAddress3: validateMailAddress.mailAddress3(
    "mailAddress1",
    "mailAddress2"
  ),
  firstPathNumbers: validateTelephoneGroupSelect.firstPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  secondPathNumbers: validateTelephoneGroupSelect.secondPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  thirtPathNumbers: validateTelephoneGroupSelect.lastPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
});
