<template>
  <v-card
    class="CardComponent"
    :class="{ 'mx-auto': center, 'CardComponent-empty': isEmpty }"
    :width="width"
    :height="height"
  >
    <div class="CardComponent-empty__content" v-if="isEmpty">
      {{ emptyText }}
    </div>
    <slot v-else> </slot>
  </v-card>
</template>

<script>
export default {
  name: "CardComponent",
  props: {
    width: {
      type: [String, Number],
      default: 0,
    },
    height: {
      type: [String, Number],
      default: 0,
    },
    center: {
      type: Boolean,
      default: true,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: "該当するデータはございませんでした。",
    },
  },
};
</script>

<style lang="scss" scoped>
.CardComponent {
  border-radius: 0;
  box-shadow: unset;
  &-empty {
    max-height: 375px !important;
    &__content {
      width: 100%;
      height: 100%;
      font-size: 20px !important;
      line-height: 1.1;
      letter-spacing: 0.6px;
      font-size: 25px;
      align-items: center;
      display: flex;
      justify-content: center;
      color: var(--color-black-text);
    }
  }
}
@media screen and (max-width: $tablet-breakpoint) {
  .v-card {
    width: 100% !important;
  }
}
</style>
