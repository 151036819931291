<template>
  <div class="DateTimeInputGroupComponent">
    <SelectComponent
      :isSelectYear="true"
      :name="yearNameSelect"
      :options="yearOptions"
      :initialValue="initYear"
      :disabled="disabled"
      @trigle-change="handleChangeYear"
    />
    <p>年</p>
    <SelectComponent
      :isSelectTime="true"
      :name="monthNameSelect"
      :options="monthOptions"
      :initialValue="initMonth"
      :disabled="disabled"
      @trigle-change="handleChangeMonth"
    />
    <p>月</p>
    <SelectComponent
      :isSelectTime="true"
      :name="dateNameSelect"
      :options="dateOptions"
      :initialValue="initDate"
      :disabled="disabled"
      @trigle-change="handleChangeDate"
    />
    <p>日</p>
    <DatePickerComponent
      :disabled="disabled"
      iconClassName="finalLabel"
      :initDate="currentChoiseDate"
      :disabledList="disabledList"
      @change-date="pickDate"
      :minDateSearch="minDateSearch"
      :maxDateSearch="maxDateSearch"
    />
  </div>
</template>

<script>
import {
  convertDateToYearMonthDate,
  convertYearMonthDateToDate,
} from "@/plugins/parseDateChoise";
import { START_RANGE_TIME } from "@/globals/enums";
export default {
  name: "DateTimeInputGroupComponent",
  props: {
    groupInput: {
      type: Array,
      default: null,
    },
    dateOptions: {
      type: Array,
      default: () => {},
    },
    monthOptions: {
      type: Array,
      default: () => {},
    },
    yearOptions: {
      type: Array,
      default: () => {},
    },
    yearNameSelect: {
      type: String,
      default: "year",
    },
    monthNameSelect: {
      type: String,
      default: "month",
    },
    dateNameSelect: {
      type: String,
      default: "date",
    },
    timeNameSelect: {
      type: String,
      default: "time",
    },
    initYear: {
      type: [String, Number],
      default: new Date().getFullYear(),
    },
    initMonth: {
      type: [String, Number],
      default: new Date().getMonth() + 1,
    },
    initDate: {
      type: [String, Number],
      default: new Date().getDate(),
    },
    minDateSearch: {
      type: Date,
      default: new Date(
        START_RANGE_TIME.YEAR,
        START_RANGE_TIME.MONTH,
        START_RANGE_TIME.DAY,
        0,
        0,
        0,
        0
      ),
    },
    maxDateSearch: {
      type: Date,
      default: new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0),
    },
    setFieldValue: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledList: {
      type: Array,
      default: () => [],
    },
    isDisplayTime: {
      type: Boolean,
      default: true,
    },
    validateField: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    date: 0,
    month: 0,
    year: 0,
    currentChoiseDate: new Date(),
  }),
  mounted() {
    this.mappingDateTime();
  },
  methods: {
    onChangeError() {
      this.$emit("trigle-change");
    },
    pickDate(e) {
      const newData = convertDateToYearMonthDate(e);
      this.setFieldValue(this.dateNameSelect, newData.date);
      this.setFieldValue(this.monthNameSelect, newData.month);
      this.setFieldValue(this.yearNameSelect, newData.year);
      this.currentChoiseDate = convertYearMonthDateToDate(
        newData.year,
        newData.month - 1,
        newData.date
      );
      this.date = newData.date;
      this.month = newData.month;
      this.year = newData.year;
      this.validateField(this.yearNameSelect);
      this.validateField(this.monthNameSelect);
      this.validateField(this.dateNameSelect);
      this.onChangeError();
    },
    handleChangeYear(e) {
      this.year = parseInt(e.target.value);
      this.currentChoiseDate = convertYearMonthDateToDate(
        e.target.value,
        this.month - 1,
        this.date
      );
      this.validateField(this.yearNameSelect);
      this.validateField(this.monthNameSelect);
      this.validateField(this.dateNameSelect);
      this.onChangeError();
    },
    handleChangeMonth(e) {
      this.month = parseInt(e.target.value);
      this.currentChoiseDate = convertYearMonthDateToDate(
        this.year,
        e.target.value - 1,
        this.date
      );
      this.validateField(this.yearNameSelect);
      this.validateField(this.monthNameSelect);
      this.validateField(this.dateNameSelect);
      this.onChangeError();
    },
    handleChangeDate(e) {
      this.date = parseInt(e.target.value);
      this.currentChoiseDate = convertYearMonthDateToDate(
        this.year,
        this.month - 1,
        e.target.value
      );
      this.validateField(this.yearNameSelect);
      this.validateField(this.monthNameSelect);
      this.validateField(this.dateNameSelect);
      this.onChangeError();
    },

    mappingDateTime() {
      if (this.initYear && this.initMonth && this.initDate) {
        this.currentChoiseDate = convertYearMonthDateToDate(
          this.initYear,
          this.initMonth - 1,
          this.initDate
        );
        this.date = this.initDate;
        this.month = this.initMonth;
        this.year = this.initYear;
      }
    },
  },
  watch: {
    initYear: function () {
      this.mappingDateTime();
    },
    initMonth: function () {
      this.mappingDateTime();
    },
    initDate: function () {
      this.mappingDateTime();
    },
  },
};
</script>

<style lang="scss" scoped>
.DateTimeInputGroupComponent {
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    color: #7c8185;
    &.finalLabel {
      margin-left: 32px;
    }
  }
}
</style>
