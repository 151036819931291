<template>
  <div class="ZipCodeSearchComponent">
    〒
    <div class="zipCode">
      <InputComponent
        :name="name"
        :defaultValue="defaultValue"
        maxlength="7"
        inputFilter="number"
        :small="true"
        :placeholder="$domain.PLACEHOLDER.zipcode"
      />
    </div>
    <ButtonComponent
      :className="isDisabledButton ? 'disabled noRounded' : 'noRounded'"
      buttonType="Button"
      :color="'#8d9caa'"
      @click="handleChange"
      :disabled="isDisabledButton"
      >郵便番号から検索</ButtonComponent
    >
  </div>
</template>

<script>
import CommonService from "@/services/common.service";
import { STATUS } from "@/globals/enums";
export default {
  name: "ZipCodeSearchComponent",
  data: () => ({
    commonService: new CommonService(),
  }),
  props: {
    name: {
      type: String,
      default: "zipCode",
    },
    defaultValue: {
      type: String || Number,
      default: "",
    },
    currentValue: {
      type: String || Number,
      default: "",
    },
    isDisabledButton: {
      type: Boolean,
      default: false,
    },
    addressPrefectureInput: {
      type: String,
      default: null,
    },
    addressCityInput: {
      type: String,
      default: null,
    },
    setFieldValue: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    async handleChange() {
      const result = await this.commonService.getAddress(this.currentValue);
      if (result.status === STATUS.SUCCESS) {
        !!this.addressPrefectureInput &&
          result?.data?.addressPrefecture !== undefined &&
          this.setFieldValue(
            this.addressPrefectureInput,
            result.data.addressPrefecture
          );
        !!this.addressCityInput &&
          result?.data?.addressCity !== undefined &&
          this.setFieldValue(this.addressCityInput, result.data.addressCity);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ZipCodeSearchComponent {
  display: flex;
  align-items: center;
  .zipCode {
    margin: 0 26px 0 7px;
    width: 120px;
  }
}
</style>
