import { get, put } from "../https/http.service";

export const getAnalysis = (params) => {
  return get("analysis/relatives", params);
};

export const getAnalysisInfo = (params) => {
  return get(
    `analysis/information/${params.mimamoriTargetPersonId}/${params.analysisResultId}`
  );
};

export const updateSafety = (id, params) => {
  return put(`safety/check/${params.mimamori_target_person_id}/${id}`, params);
};
