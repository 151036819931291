<template>
  <v-pagination
    class="custom-pagination"
    :class="{
      'custom-active__next': currentPage < totalPages,
      'custom-active__prev': currentPage > 1,
    }"
    :length="totalPages"
    rounded="circle"
    :total-visible="totalPages > TOTAL_VISIBLE ? TOTAL_VISIBLE : 0"
    :model-value="currentPage"
    :next-icon="'icon-ico_next'"
    :prev-icon="'icon-ico_prev'"
    :size="'small'"
    @update:modelValue="onClickPage"
  ></v-pagination>
</template>

<script>
import { TOTAL_VISIBLE } from "@/globals/enums";
export default {
  name: "PaginationComponent",
  created() {
    this.TOTAL_VISIBLE = TOTAL_VISIBLE;
  },
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {},
  methods: {
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
  },
};
</script>
<style lang="scss">
.custom-pagination {
  .v-pagination__list {
    .v-pagination__item {
      color: #d0d9df;
    }
    .v-pagination__item--is-active {
      button {
        background-color: #8d9caa;
        color: white;
      }
    }
  }
  .v-pagination__next {
    .v-btn {
      .icon-ico_next::before {
        font-size: 15px;
      }
    }
  }
  .v-pagination__prev {
    .v-btn {
      .icon-ico_prev::before {
        font-size: 15px;
      }
    }
  }
}
.custom-active__next {
  .v-pagination__next {
    .v-btn {
      .icon-ico_next::before {
        color: #4c7495 !important;
      }
    }
  }
}
.custom-active__prev {
  .v-pagination__prev {
    button {
      .icon-ico_prev::before {
        color: #4c7495 !important;
      }
    }
  }
}
</style>
