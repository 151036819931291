<template>
  <div class="footer-login" v-if="isLoginComponent">
    <img :src="require('@/assets/images/home.png')" width="100" height="100" />
    <div class="content">
      <div class="content-top">ご利用方法</div>
      <div class="content-bottom">サービスの概要についてご案内いたします。</div>
    </div>
    <a href="/help" target="_blank">
      <img
        :src="require('@/assets/images/home-button-right.png')"
        width="55"
        height="55"
        class="right-button"
      />
    </a>
  </div>
  <div class="footer">
    <p>{{ $domain.COPYRIGHT.footer }}</p>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  computed: {
    isLoginComponent() {
      return this.$route?.name === "LoginComponent";
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 70px;
  padding: 25px 52px;
  background-color: var(--footer-bgcolor);

  p {
    font-size: 10px;
    color: #7c8185;
    width: 100%;
  }
  &-login {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e7edef;
    padding: 25px auto;
    .content {
      margin: 0 30px 0 18px;
      &-top {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      &-bottom {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .right-button {
      vertical-align: bottom;
    }
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    z-index: 1;
    font-family: Arial;
    font-size: 10px;
    font-weight: normal;
    background-color: #f3f6f7;
    padding: 24px 20px;
    text-align: center;
    height: 59px;
    &-login {
      padding: 25px;
      .content {
        margin: 0 8px;
        &-top {
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 5px;
        }
        &-bottom {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
