import { ALERT_DATES_OPTIONS, ALERT_MAIL_ACTIVE } from "@/globals/enums";

export const currentAlertDate = (data) => {
  const findingValue = ALERT_DATES_OPTIONS.find((type) => type.VALUE === data);
  return {
    value: findingValue?.VALUE,
    label: findingValue?.LABEL || "-",
  };
};

export const convertAlertDateOptions = (data) => {
  const result = [];
  for (let i = 1; i <= data; i++) {
    result.push({
      label: `${i}日`,
      value: i,
    });
  }
  return result;
};

export const currentMailAddress = (mailList, displayOrder) => {
  const findingValue = mailList.find(
    (type) => type.display_order === displayOrder
  );
  return findingValue;
};

export const currentPhoneNumber = (phoneNumber, displayOrder) => {
  const findingValue = phoneNumber.find(
    (type) => type.display_order === displayOrder
  );
  return findingValue;
};

const checkUnActiveEmails = (listEmail) => {
  return !listEmail.length || !listEmail.find((element) => element.active_flg);
};

export const currentFlag = (data) => {
  const findingValue = ALERT_MAIL_ACTIVE.find((type) => type.VALUE === data);
  return {
    value: findingValue?.VALUE,
    label: findingValue?.LABEL || "",
    type: findingValue?.TYPE || "",
    class: findingValue?.CLASS || "",
  };
};

export const convertGetAlertInfo = (data) => {
  const phoneNumber = currentPhoneNumber(data?.phone_number_list || [], 1);
  const mailList = {
    mail1: currentMailAddress(data.email_list, 1),
    mail2: currentMailAddress(data.email_list, 2),
    mail3: currentMailAddress(data.email_list, 3),
  };
  return {
    defaultAlertOptions: Math.min(3, data.alert_conditions_max || 9),
    alertDate: currentAlertDate(data.alert_conditions),
    alertDateOptions: convertAlertDateOptions(data.alert_conditions_max),
    mailAddress1: mailList.mail1?.email || "＜未登録＞",
    showButton1: !!mailList.mail1?.email,
    rawMailAddress1: mailList.mail1?.email,
    mailAddressId1: mailList.mail1?.mail_id,
    altFlag1: currentFlag(mailList.mail1?.active_flg),
    mailAddress2: mailList.mail2?.email || "＜未登録＞",
    showButton2: !!mailList.mail2?.email,
    mailAddressId2: mailList.mail2?.mail_id,
    rawMailAddress2: mailList.mail2?.email,
    altFlag2: currentFlag(mailList.mail2?.active_flg),
    mailAddress3: mailList.mail3?.email || "＜未登録＞",
    showButton3: !!mailList.mail3?.email,
    mailAddressId3: mailList.mail3?.mail_id,
    rawMailAddress3: mailList.mail3?.email,
    altFlag3: currentFlag(mailList.mail3?.active_flg),
    isUnActiveEmail: checkUnActiveEmails(data.email_list),
    phoneNumberId: phoneNumber?.phone_number_id,
    ...(phoneNumber
      ? {
          phoneNumberGroup: [
            {
              name: "firstPathNumbers",
              value: phoneNumber.phone_number1,
              type: "telephoneGroup",
              pattern: "[0-9]+",
              maxlength: "3",
            },
            {
              name: "secondPathNumbers",
              value: phoneNumber.phone_number2,
              type: "telephoneGroup",
              pattern: "[0-9]+",
              maxlength: "4",
            },
            {
              name: "thirtPathNumbers",
              value: phoneNumber.phone_number3,
              type: "telephoneGroup",
              pattern: "[0-9]+",
              maxlength: "4",
            },
          ],
          parsePhoneNumber:
            phoneNumber.phone_number1 +
            "-" +
            phoneNumber.phone_number2 +
            "-" +
            phoneNumber.phone_number3,
        }
      : {
          phoneNumberGroup: [
            {
              name: "firstPathNumbers",
              value: "070",
              type: "telephoneGroup",
              pattern: "[0-9]+",
              maxlength: "4",
            },
            {
              name: "secondPathNumbers",
              value: "",
              type: "telephoneGroup",
              pattern: "[0-9]+",
              maxlength: "4",
            },
            {
              name: "thirtPathNumbers",
              value: "",
              type: "telephoneGroup",
              pattern: "[0-9]+",
              maxlength: "4",
            },
          ],
          parsePhoneNumber: "ー",
        }),
  };
};

export const convertUpdateAlertInfo = (data) => {
  let result = {
    alert_conditions: data.alertDate,
    ...(data.phoneNumberId &&
      data.secondPathNumbers &&
      data.thirtPathNumbers &&
      data.parsePhoneNumber !== data.sendingParsePhoneNumber && {
        update_phone_number_list: [
          {
            phone_number_id: data.phoneNumberId,
            phone_number1: data.firstPathNumbers,
            phone_number2: data.secondPathNumbers,
            phone_number3: data.thirtPathNumbers,
          },
        ],
      }),
  };
  result = {
    ...result,
    ...(!data.phoneNumberId &&
      data.secondPathNumbers &&
      data.thirtPathNumbers && {
        create_phone_number_list: [
          {
            display_order: 1,
            phone_number1: data.firstPathNumbers,
            phone_number2: data.secondPathNumbers,
            phone_number3: data.thirtPathNumbers,
          },
        ],
      }),
  };
  const createEmailResult = [];
  const updateEmailResult = [];
  const deleteEmailResult = [];
  const rawEmailList = [
    {
      email: data.mailAddress1,
      mailAddressId: data.mailAddressId1,
      rawMailAddress: data.rawMailAddress1,
      order: 1,
    },
    {
      email: data.mailAddress2,
      mailAddressId: data.mailAddressId2,
      rawMailAddress: data.rawMailAddress2,
      order: 2,
    },
    {
      email: data.mailAddress3,
      mailAddressId: data.mailAddressId3,
      rawMailAddress: data.rawMailAddress3,
      order: 3,
    },
  ];
  rawEmailList.forEach((element) => {
    if (element?.mailAddressId) {
      element.email?.length > 0 &&
        element.rawMailAddress !== element.email &&
        updateEmailResult.push({
          email: element.email,
          mail_id: element.mailAddressId,
        });
      element.email?.length === 0 &&
        deleteEmailResult.push({
          mail_id: element.mailAddressId,
        });
    } else if (element.email?.length > 0) {
      createEmailResult.push({
        email: element.email,
        display_order: element.order,
      });
    }
  });
  return {
    ...result,
    create_email_list: createEmailResult,
    update_email_list: updateEmailResult,
    delete_email_list: deleteEmailResult,
  };
};
