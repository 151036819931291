<template>
  <div class="RelativeEditComponent">
    <TitleComponent class="RelativeEditComponent-title">
      <template v-slot:icon
        ><img
          :src="require('@/assets/images/qms/t_ico_profile.svg')"
          class="image"
      /></template>
      <template v-slot:content> プロフィール情報 </template>
    </TitleComponent>
    <Form
      ref="editForm"
      v-slot="{ values, setFieldValue, meta, validateField }"
      @submit="submit"
      :validation-schema="schema"
    >
      <CardComponent
        v-if="relativeInfo"
        class="main"
        :width="'960px'"
        :height="'auto'"
      >
        <TitleComponent :small="true">
          <template v-slot:icon><span class="icon-t_ico_shinzoku" /></template>
          <template v-slot:content> <span>親族様など</span> </template>
        </TitleComponent>
        <div class="group">
          <div class="group_title">プロフィール情報</div>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> 氏名 </template>
            <template v-slot:content>
              <div class="two-input-content">
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="lastNameKanji"
                    placeholder="山田"
                    inputFilter="fullWidth"
                    :defaultValue="relativeInfo.lastNameKanji"
                    maxlength="10"
                  />
                </div>
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="firstNameKanji"
                    placeholder="太朗"
                    inputFilter="fullWidth"
                    :defaultValue="relativeInfo.firstNameKanji"
                    maxlength="10"
                  />
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> 氏名（カナ）</template>
            <template v-slot:content>
              <div class="two-input-content">
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="lastNameKana"
                    placeholder="ヤマダ"
                    inputFilter="fullWidth"
                    :defaultValue="relativeInfo.lastNameKana"
                    maxlength="20"
                  />
                </div>
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="firstNameKana"
                    :defaultValue="relativeInfo.firstNameKana"
                    placeholder="タロウ"
                    inputFilter="fullWidth"
                    maxlength="20"
                  />
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> 郵便番号</template>
            <template v-slot:content>
              <ZipCodeSearchComponent
                :defaultValue="relativeInfo.zipCode1 + relativeInfo.zipCode2"
                name="zipCode"
                :isDisabledButton="
                  !values.zipCode || values.zipCode.length !== 7
                "
                :currentValue="values?.zipCode"
                addressPrefectureInput="addressPrefecture"
                addressCityInput="addressCity"
                :setFieldValue="setFieldValue"
              />
            </template>
          </GroupContentComponent>
          <GroupContentComponent
            :required="true"
            :isTitleTop="true"
            class="group-big"
          >
            <template v-slot:title> 住所</template>
            <template v-slot:content>
              <div class="address-group">
                <SelectComponent
                  name="addressPrefecture"
                  :options="relativeInfo?.prefectureOptions"
                  :initialValue="relativeInfo?.addressPrefecture"
                />
                <InputComponent
                  :small="true"
                  :required="true"
                  name="addressCity"
                  :defaultValue="relativeInfo.addressCity"
                  :placeholder="$domain.PLACEHOLDER.address"
                  inputFilter="fullWidth"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> 携帯電話番号</template>
            <template v-slot:content>
              <div class="flex">
                <div class="telephone">
                  <TelephoneInputComponent
                    :groupInput="relativeInfo.phoneData.phoneNumberGroup"
                    :isSelect="true"
                    :options="relativeInfo.phoneData.telephone3Digits"
                    :defaultFirst3Digits="
                      relativeInfo.phoneData.firstPhoneNumber
                    "
                    :validateField="validateField"
                  />
                </div>
                <span class="telephone-memo"
                  >※携帯番号宛てにSMSでログインパスワードをお知らせします。</span
                >
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal">
            <template v-slot:title>
              <p class="relationship-title">みまもり対象者との関係</p></template
            >
            <template v-slot:content>
              <div class="relationship">
                <InputComponent
                  name="relationship"
                  :small="true"
                  :defaultValue="relativeInfo.relationship"
                  placeholder="例：子"
                />
              </div>
            </template>
          </GroupContentComponent>
          <!-- #59427 Hide 特記事項 field -->
          <GroupContentComponent
            class="group-normal"
            :isTitleTop="true"
            v-if="false"
          >
            <template v-slot:title> 特記事項</template>
            <template v-slot:content>
              <div class="notices">
                <TextAreaComponent
                  name="notices"
                  :value="
                    relativeInfo.notices !== 'ー' ? relativeInfo.notices : ''
                  "
                  placeholder="特記事項ありましたらご入力ください"
                  :rows="2"
                />
              </div>
            </template>
          </GroupContentComponent>
          <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
          <div class="action-group">
            <ButtonComponent
              width="150px"
              height="50px"
              type="outline"
              color="#8d9caa"
              buttonType="Button"
              @click="cancel"
              >キャンセル</ButtonComponent
            >
            <ButtonComponent
              width="150px"
              height="50px"
              color="#8d9caa"
              class="btn-submit"
              :disabled="!meta.valid"
              >登録</ButtonComponent
            >
          </div>
        </div>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Form } from "vee-validate";
import UserService from "@/services/user.service";
import { STATUS } from "@/globals/enums";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import { updateRelativeSchema } from "@/validations/schemas/updateProfileInfoSchema";

export default {
  name: "RelativeEditComponent",
  components: {
    Form,
  },
  computed: {
    ...mapState({
      relativeInfo: (state) => state.settings.relativeInfo,
      mimamoriTargetPersonId: (state) =>
        state.global?.globalData?.mimamoriTargetPersonId,
    }),
  },
  data: () => ({
    userService: new UserService(),
    confirmDialogService: new ConfirmDialogService(),
    schema: updateRelativeSchema,
    errorDetail: null,
  }),
  methods: {
    cancel() {
      this.$router.push({ name: "ProfileDetailComponent" });
    },
    async submit(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.userService.updateRelative(
        this.mimamoriTargetPersonId,
        data
      );
      await this.userService.getRalativeInfo(this.mimamoriTargetPersonId);
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result.status === STATUS.SUCCESS) {
        this.confirmDialogService.openConfirmDialog(
          "プロフィール情報を編集しました。",
          null,
          () => {
            this.$router.push({ name: "ProfileDetailComponent" });
          }
        );
      }
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.RelativeEditComponent {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: var(--default-distance-card);
  &-title {
    width: 960px;
    margin: 0 auto;
    display: flex;
    color: #000000 !important;
    .icon-ico_user::before {
      color: #000000;
    }
  }
  .main {
    padding: 30px 40px;
    margin-bottom: 40px;
    .flex {
      display: flex;
      align-items: center;
    }
    &_title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      font-weight: 500;
    }
    &_userInfo {
      padding: 13px 0 30px;
      margin-left: 21px;
      display: flex;
      flex-direction: column;
      gap: 7px;
      .fullName {
        font-size: 20px;
        font-weight: 500;
        gap: 13px;
        margin-right: 12px;
        &-small {
          font-size: 14px;
          gap: 10px;
        }
      }
      .address {
        gap: 19px;
      }
    }
    .group {
      &_title {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
        border-bottom: solid 2px #d0d9df;
      }
      .telephone {
        width: 240px;
        &-memo {
          margin-left: 50px;
          font-size: 12px;
          font-weight: normal;
          color: #7c8185;
        }
      }
      .status {
        margin-left: auto;
        font-size: 12px;
        width: 99px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .orange {
        color: #eba447;
        border: solid 1px #eba447;
      }
      .blue {
        color: #328dc2;
        border: solid 1px #328dc2;
      }
      &-big {
        height: 165px;
        padding: 35px 10px;
      }
      &-normal {
        height: 100px;
      }
      &-oversize {
        padding: 35px 10px;
        .notices {
          width: 600px;
          height: 60px;
        }
      }
      .action-group {
        margin-top: 30px;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
      }
    }
    &_bottom {
      margin: 30px 0 40px;
    }
    .fullName-small {
      gap: 10px;
    }
  }
  .two-input-content {
    display: flex;
    gap: 20px;
    .input-content {
      width: 170px;
    }
  }
  .address-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .relationship {
    &-title {
      width: 120px;
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .RelativeEditComponent {
    height: 100%;
    display: block;
    margin: 0;
    &-title {
      width: 300px;
      margin: 0 20px;
    }
    .main {
      width: 100% !important;
      height: 100% !important;
      padding: 30px 20px;
      margin-bottom: 0;
      .group-normal {
        height: 100%;
      }
      .group-big {
        height: 100%;
        padding: 15px 10px;
      }
      .two-input-content {
        display: flex;
        flex-direction: column;
        gap: 0;
        width: 100%;
        .input-content {
          width: 100%;
        }
      }
      .address-group {
        margin-top: 20px;
        gap: 30px;
        width: 100%;
      }
      .relationship {
        width: 100%;
        &-title {
          width: 100%;
        }
      }
      .notices {
        margin-top: 20px;
      }
      .flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        gap: 20px;
        .telephone-memo {
          margin-left: 0;
        }
      }
      .success-content {
        margin: 20px 0 60px;
        text-align: center;
        width: 310px;
      }
      .action-group {
        flex-direction: column-reverse;
        gap: 0 !important;
        .btn-submit {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
