<template>
  <v-card-title
    class="TitleComponent"
    :class="{ className, underline: hasUnderLine, small }"
  >
    <div class="left-content">
      <span v-if="hasIcon" class="icon-title"><slot name="icon"></slot></span>
      <slot name="content"></slot>
    </div>
    <div class="right-content">
      <slot name="button"></slot>
    </div>
  </v-card-title>
</template>

<script>
export default {
  name: "TitleComponent",
  props: {
    hasUnderLine: {
      type: Boolean,
      default: false,
    },
    className: {
      type: [String],
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIcon() {
      return !!this.$slots.icon;
    },
  },
};
</script>
<style lang="scss" scoped>
.TitleComponent {
  display: flex;
  justify-content: space-between;
  padding: 8px 0 !important;
  &.underline {
    padding: 0 5px;
    border-bottom: solid 2px #d0d9df;
  }
  .left-content {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &.small {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 !important;
    .icon-title {
      line-height: unset;
    }
  }
}
@media screen and (max-width: $tablet-breakpoint) {
  .TitleComponent {
    width: 90% !important;
  }
  .fitContent {
    width: fit-content !important;
  }
}
</style>
