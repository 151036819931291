<template>
  <div class="MimamoriHistoryComponent">
    <TitleComponent class="MimamoriHistoryComponent-title">
      <template v-slot:icon>
        <i class="icon icon-t_ico_seijyo"></i>
      </template>
      <template v-slot:content>正常・異常判定結果</template>
    </TitleComponent>
    <CardComponent class="filter-ui-card" :width="'960px'" :height="'auto'">
      <Form
        ref="formSearch"
        @submit="submit"
        v-slot="{ setFieldValue, validateField }"
        :validation-schema="schema"
      >
        <section class="mimamori-record-filter">
          <GroupContentComponent
            class="mimamori-record-filter__item"
            :isNoBorderBottom="true"
          >
            <template v-slot:title>判定結果</template>
            <template v-slot:content>
              <SelectComponent
                name="judgmentResult"
                :options="listSelect"
                initialValue=""
              />
            </template>
          </GroupContentComponent>
          <GroupContentComponent
            class="mimamori-record-filter__item"
            :isNoBorderBottom="true"
          >
            <template v-slot:title>期間を指定</template>
            <template v-slot:content>
              <div class="d-flex group-filter-date">
                <span class="d-flex align-center">
                  <DateTimeInputGroupComponent
                    v-if="dataForm"
                    :yearOptions="analysisEnums.startOptions.years"
                    :monthOptions="analysisEnums.startOptions.months"
                    :dateOptions="analysisEnums.startOptions.dates"
                    :initYear="dataForm?.startYear || 0"
                    :initMonth="dataForm?.startMonth || 0"
                    :initDate="dataForm?.startDate || 0"
                    yearNameSelect="startYear"
                    monthNameSelect="startMonth"
                    dateNameSelect="startDate"
                    :setFieldValue="setFieldValue"
                    :disabled="false"
                    :disabledList="[]"
                    :validateField="validateField"
                    :isDisplayTime="false"
                  />
                  <DateTimeInputGroupComponent
                    v-else
                    :yearOptions="analysisEnums.startOptions.years"
                    :monthOptions="analysisEnums.startOptions.months"
                    :dateOptions="analysisEnums.startOptions.dates"
                    :initYear="0"
                    :initMonth="0"
                    :initDate="0"
                    yearNameSelect="startYear"
                    monthNameSelect="startMonth"
                    dateNameSelect="startDate"
                    :setFieldValue="setFieldValue"
                    :disabled="false"
                    :disabledList="[]"
                    :validateField="validateField"
                    :isDisplayTime="false"
                  />
                  <span class="mimamori-record-filter__interfix">～</span>
                </span>
                <DateTimeInputGroupComponent
                  v-if="dataForm"
                  :yearOptions="analysisEnums.endOptions.years"
                  :monthOptions="analysisEnums.endOptions.months"
                  :dateOptions="analysisEnums.endOptions.dates"
                  :initYear="dataForm?.endYear || 0"
                  :initMonth="dataForm?.endMonth || 0"
                  :initDate="dataForm?.endDate || 0"
                  yearNameSelect="endYear"
                  monthNameSelect="endMonth"
                  dateNameSelect="endDate"
                  :setFieldValue="setFieldValue"
                  :disabled="false"
                  :disabledList="[]"
                  :validateField="validateField"
                  :isDisplayTime="false"
                />
                <DateTimeInputGroupComponent
                  v-else
                  :yearOptions="analysisEnums.endOptions.years"
                  :monthOptions="analysisEnums.endOptions.months"
                  :dateOptions="analysisEnums.endOptions.dates"
                  :initYear="0"
                  :initMonth="0"
                  :initDate="0"
                  yearNameSelect="endYear"
                  monthNameSelect="endMonth"
                  dateNameSelect="endDate"
                  :setFieldValue="setFieldValue"
                  :disabled="false"
                  :disabledList="[]"
                  :validateField="validateField"
                  :isDisplayTime="false"
                />
              </div>
            </template>
          </GroupContentComponent>
          <ButtonComponent
            class="mimamori-record-filter__button"
            width="130px"
            height="30px"
            >絞り込み</ButtonComponent
          >
        </section>
      </Form>
    </CardComponent>
    <CardComponent
      class="record-table-card"
      :isEmpty="analysisList && !analysisList.length"
      :width="'960px'"
      v-if="dataForm && analysisList"
      :height="analysisList.length > 0 ? 'auto' : '460px'"
      :emptyText="'該当するみまもり対象者はございませんでした。'"
    >
      <section class="mimamori-record-group">
        <h1 class="mimamori-record-group__header">正常・異常判定結果</h1>
        <p class="mimamori-record-group__remark">
          （正常・異常判定は、記載の日付の電気使用量に対する結果を表示しています。）
        </p>
        <div class="mimamori-record-group__count-item">
          <strong
            v-tableRange="{
              currentPage: pagination?.page || 0,
              currentTotal: analysisList?.length || 0,
            }"
          />
          件/ {{ total }}件
        </div>
        <table class="mimamori-record-group__main table-container">
          <tr>
            <TableHeaderComponent
              :sort="true"
              :state="
                orderSort === ANALYSIS_SORT?.ASC
                  ? SORT_ORDER.ASC
                  : SORT_ORDER.DESC
              "
              @changeOrderSort="changeOrder($event)"
            >
              日付
            </TableHeaderComponent>
            <TableHeaderComponent :alignStart="true">
              判定結果
            </TableHeaderComponent>
            <TableHeaderComponent> アラート発報 </TableHeaderComponent>
            <TableHeaderComponent>確認日</TableHeaderComponent>
            <TableHeaderComponent>確認結果</TableHeaderComponent>
            <TableHeaderComponent>確認者</TableHeaderComponent>
            <TableHeaderComponent></TableHeaderComponent>
            <TableHeaderComponent></TableHeaderComponent>
          </tr>
          <tbody class="table-row-group">
            <TableRowComponent
              @click="
                editAnalysis(analysis.analysisResultId, analysis.pauseStart)
              "
              :class="{
                'highlight-row': highlightAnalysis(analysis),
              }"
              v-for="analysis in analysisList"
              :key="analysis.analysisResultId"
            >
              <TableCellComponent>
                <span
                  class="content-cell"
                  v-formatDateTimeJanpanese="analysis.analysisDate"
                ></span>
              </TableCellComponent>
              <TableCellComponent>
                <AnalysisStatusComponent
                  :pauseStart="analysis?.pauseStart"
                  :analysisResultCode="analysis?.analysisResultCode"
                  :confirmStatus="analysis?.confirmStatus"
                  :alertFlag="analysis?.alertFlag"
                />
              </TableCellComponent>
              <TableCellComponent>
                <span
                  class="content-cell"
                  :class="{ 'highlight-text': highlightAnalysis(analysis) }"
                >
                  {{ analysis.alertFlag ? "アラートが発報されました" : "" }}
                </span>
              </TableCellComponent>
              <TableCellComponent>
                <span
                  class="content-cell"
                  v-formatDateTimeJanpanese="analysis.safetyCheckUpdate"
                ></span>
              </TableCellComponent>
              <TableCellComponent>
                <span class="content-cell">
                  {{ analysis.safetyCheckResult }}
                </span>
              </TableCellComponent>
              <TableCellComponent>
                <span class="content-cell">
                  {{ analysis.safetyCheckPerson }}
                </span>
              </TableCellComponent>
              <TableCellComponent>
                <AnalysisLabelComponent
                  v-if="!analysis.pauseStart"
                  :analysisResultCode="analysis.analysisResultCode"
                  :confirmStatus="analysis.confirmStatus"
                  :alertFlag="analysis?.alertFlag"
                />
              </TableCellComponent>
              <TableCellComponent
                :class="{ 'mimamori-record__marker': !analysis.pauseStart }"
              >
              </TableCellComponent>
            </TableRowComponent>
          </tbody>
        </table>

        <table
          v-if="analysisList?.length > 0"
          class="mimamori-record-group__main-mobile table-container"
        >
          <tr>
            <TableHeaderComponent
              :sort="true"
              :state="
                orderSort === ANALYSIS_SORT?.ASC
                  ? SORT_ORDER.ASC
                  : SORT_ORDER.DESC
              "
              :alignStart="true"
              @changeOrderSort="changeOrder($event)"
            >
              日付
            </TableHeaderComponent>
            <TableHeaderComponent :alignStart="true" class="content-col">
              判定結果
            </TableHeaderComponent>
            <TableHeaderComponent class="label-col" />
          </tr>
          <tbody class="table-row-group">
            <TableRowComponent
              v-for="(analysis, index) of analysisList"
              :class="{ 'highlight-row': highlightAnalysis(analysis) }"
              :key="index"
              @click="
                editAnalysis(analysis.analysisResultId, analysis.pauseStart)
              "
            >
              <TableCellComponent>
                <div class="d-flex flex-column justify-center color-content">
                  <p
                    class="message-content content-cell"
                    :class="{ 'mb-5': analysis.alertFlag }"
                    v-formatDateTimeJanpanese="analysis?.analysisDate"
                  ></p>
                  <p
                    class="message-content content-cell"
                    :class="{ 'highlight-text': highlightAnalysis(analysis) }"
                    v-if="analysis.alertFlag"
                  >
                    アラートが発報されました
                  </p>
                  <div class="mt-5">
                    <p
                      class="content-safety mb-2"
                      v-if="analysis.safetyCheckUpdate !== 'ー'"
                    >
                      <span class="content-cell small-content">確認日</span>
                      <span
                        class="content-cell"
                        v-formatDateTimeJanpanese="analysis.safetyCheckUpdate"
                      ></span>
                    </p>
                    <p
                      class="content-safety mb-2"
                      v-if="analysis.safetyCheckResult !== 'ー'"
                    >
                      <span class="content-cell small-content">確認結果</span>
                      <span class="content-cell">
                        {{ analysis.safetyCheckResult }}
                      </span>
                    </p>
                    <p
                      class="content-safety mb-2"
                      v-if="analysis.safetyCheckPerson !== 'ー'"
                    >
                      <span class="content-cell small-content">確認者</span>
                      <span class="content-cell">
                        {{ analysis.safetyCheckPerson }}
                      </span>
                    </p>
                  </div>
                </div>
              </TableCellComponent>
              <TableCellComponent class="content-label">
                <div
                  class="d-flex flex-column justify-center align-center content-status"
                >
                  <AnalysisStatusComponent
                    class="mb-2"
                    :pauseStart="!!analysis.pauseStart"
                    :analysisResultCode="analysis.analysisResultCode"
                    :confirmStatus="analysis.confirmStatus"
                    :alertFlag="analysis.alertFlag"
                  />
                  <AnalysisLabelComponent
                    v-if="!analysis.pauseStart"
                    :analysisResultCode="analysis.analysisResultCode"
                    :confirmStatus="analysis.confirmStatus"
                    :alertFlag="analysis.alertFlag"
                  />
                </div>
              </TableCellComponent>
              <TableCellComponent class="content-label">
                <div
                  :class="{ 'mimamori-record__marker': !analysis.pauseStart }"
                ></div>
              </TableCellComponent>
            </TableRowComponent>
          </tbody>
        </table>
        <div class="mimamori-record-group__footer">
          <PaginationComponent
            :total-pages="pagination.totalPage"
            :current-page="pagination.page"
            v-if="pagination.totalPage > 1"
            @pagechanged="onPageChange"
          />
        </div>
      </section>
    </CardComponent>
  </div>
</template>

<script>
import AnalysisService from "@/services/analysis.service";
import {
  STATUS,
  SORT_ORDER,
  WEB_STORAGE_KEY,
  ANALYSIS_RESULT_CODE,
  CONFIRM_STATUS,
  ANALYSIS_SORT,
} from "@/globals/enums";
import { mapState } from "vuex";
import { convertAnalysisEnums } from "@/store/converter/analysis.converter";
import WebStorageService from "@/services/web-storage.service";
import { Form } from "vee-validate";
import { miamoriSearchSchema } from "@/validations/schemas/miamoriSearchSchema";

export default {
  name: "MimamoriHistoryComponent",
  components: {
    Form,
  },
  data: () => ({
    pagination: { page: 1, number: 10, totalPage: 0 },
    analysisService: new AnalysisService(),
    webStorageService: new WebStorageService(),
    analysisList: null,
    orderSort: ANALYSIS_SORT.DESC,
    dataForm: null,
    total: 0,
    listSelect: [
      {
        label: "全て",
        value: "",
      },
      {
        label: "正常",
        value: "00",
      },
      {
        label: "異常",
        value: "10",
      },
      {
        label: "エラー",
        value: "90",
      },
    ],
    schema: miamoriSearchSchema,
  }),
  created() {
    this.analysisEnums = convertAnalysisEnums();
    this.SORT_ORDER = SORT_ORDER;
    this.ANALYSIS_SORT = ANALYSIS_SORT;
  },

  computed: {
    ...mapState({
      mimamoriTargetPersonId: (state) =>
        state.global?.globalData?.mimamoriTargetPersonId,
    }),
  },
  mounted() {
    const queryParams = this.webStorageService.getSessionStorage(
      WEB_STORAGE_KEY.SEARCH_MIMAMORI
    );
    if (this.$route.meta.autoFill && queryParams) {
      this.dataForm = queryParams;
      this.pagination = {
        page: queryParams?.page || 1,
        number: 10,
        totalPage: 0,
      };
      this.orderSort = queryParams.sort || ANALYSIS_SORT.DESC;
      const customDataForm = this.customDataMapForm();
      this.$refs.formSearch.setValues({ ...customDataForm });
    } else {
      this.dataForm = {};
      const customDataForm = this.customDataMapForm();
      this.$refs.formSearch.setValues({ ...customDataForm });
      this.webStorageService.setSessionStorage(
        WEB_STORAGE_KEY.SEARCH_MIMAMORI,
        { ...this.dataForm, page: this.pagination.page, sort: this.sortOrder }
      );
    }

    this.onSearchMimamoriHistory(
      this.dataForm,
      this.pagination,
      this.orderSort,
      true
    );
  },

  methods: {
    async onPageChange(page) {
      this.pagination.page = page;
      this.webStorageService.setSessionStorage(
        WEB_STORAGE_KEY.SEARCH_MIMAMORI,
        { ...this.dataForm, page: this.pagination.page, sort: this.orderSort }
      );
      await this.onSearchMimamoriHistory(
        this.dataForm,
        this.pagination,
        this.orderSort
      );
    },

    navigatePath(name, params = {}) {
      this.$router.push({ name, params: params });
    },

    async changeOrder(order) {
      switch (order) {
        case SORT_ORDER.ASC:
          this.orderSort = ANALYSIS_SORT.ASC;
          break;
        case SORT_ORDER.DESC:
          this.orderSort = ANALYSIS_SORT.DESC;
          break;

        default:
          break;
      }
      this.webStorageService.setSessionStorage(
        WEB_STORAGE_KEY.SEARCH_HISTORY_MIMAMORI,
        {
          ...this.dataForm,
          page: this.pagination?.page,
          sort: this.orderSort || 1,
        }
      );
      await this.onSearchMimamoriHistory(
        this.dataForm,
        this.pagination,
        this.orderSort,
        false
      );
    },

    editAnalysis(analysisId, canNotEdit) {
      if (canNotEdit) {
        return;
      }
      this.$router.push(
        `/mimamori/${analysisId}?mimamoriTargetPersonId=${this.mimamoriTargetPersonId}`
      );
    },

    onSearchMimamoriHistory(dataFilter, pagination, sort, loading) {
      if (loading) {
        this.isSearching = true;
        this.$store.dispatch("global/updateLoadingStatus", true);
      }
      this.analysisService
        .getAnalysis(dataFilter, pagination, sort)
        .then((analysisData) => {
          this.$store.dispatch("global/updateLoadingStatus", false);
          if (analysisData.status === STATUS.SUCCESS) {
            this.analysisList = analysisData.data.analysisResultList;
            this.total = analysisData.data.total;
            this.pagination = {
              page: analysisData.data.page,
              totalPage: analysisData.data.totalPage,
            };
          }
        });
    },

    highlightAnalysis(analysis) {
      return (
        analysis.analysisResultCode === ANALYSIS_RESULT_CODE.ABNORMAL.VALUE &&
        (analysis.confirmStatus === CONFIRM_STATUS.UNCONFIRMED.VALUE ||
          analysis.confirmStatus === CONFIRM_STATUS.CHECKING.VALUE) &&
        analysis.alertFlag
      );
    },

    submit(data) {
      this.dataForm = { ...data };
      this.pagination.page = 1;
      const customDataForm = this.customDataMapForm();
      this.$refs.formSearch.setValues({ ...customDataForm });
      this.webStorageService.setSessionStorage(
        WEB_STORAGE_KEY.SEARCH_MIMAMORI,
        { ...this.dataForm, page: this.pagination.page, sort: this.sortOrder }
      );
      this.onSearchMimamoriHistory(
        this.dataForm,
        this.pagination,
        this.orderSort,
        true
      );
    },

    customDataMapForm() {
      return {
        ...this.dataForm,
        endDate: this.dataForm.endDate || 0,
        endMonth: this.dataForm.endMonth || 0,
        endYear: this.dataForm.endYear || 0,
        startDate: this.dataForm.startDate || 0,
        startMonth: this.dataForm.startMonth || 0,
        startYear: this.dataForm.startYear || 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.MimamoriHistoryComponent {
  display: flex;
  margin: 30px auto;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-title {
    display: flex;
    width: 960px;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    .icon {
      line-height: 1.5;
      margin-right: 0.25em;
      vertical-align: middle;
    }
  }
}

.filter-ui-card {
  padding: 0 40px;
  margin-bottom: 30px;
}

.record-table-card {
  padding: 0 40px;
  margin-bottom: 40px;
}

.mimamori-record-filter {
  margin: 30px auto;

  // Element (Nested Override)
  &__item.GroupContentComponent {
    grid-template-columns: 100px 1fr;
    padding: 10px 0;
  }
  // Elements
  &__interfix {
    padding: 0 10px;
  }
  &__button {
    text-align: right;
  }
}

.mimamori-record-group {
  margin: 30px auto;

  &__header {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    float: left;
  }
  &__remark {
    color: var(--color-label);
    font-size: var(--font-size-small);
    position: absolute;
    padding-top: 0.3em;
    margin-left: 155px;
  }
  &__count-item {
    text-align: right;
  }
  &__main {
    display: table;
    width: 100%;
    &-mobile {
      display: none;
    }
  }
  &__footer {
    text-align: center;
    margin: 30px auto;
  }
  .table-row-group {
    .icon-arrow {
      padding: 13px 10px;
    }
    .TableRowComponent {
      cursor: pointer;
      &:hover {
        background: var(--soft-bgcolor);
      }
    }
    .highlight-row {
      background: #fef7f6 !important;
      &:hover {
        background: var(--alert-bgcolor-2) !important;
      }
    }
    .TableCellComponent {
      padding: 13px 0;
      text-align: center;
      .highlight-text {
        color: #c51f1f !important;
      }
      .content-cell {
        color: #7c8185;
      }
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) and (min-width: $mobile-breakpoint) {
  .MimamoriHistoryComponent {
    &-title {
      width: 90%;
    }
  }
  .v-card {
    width: 100% !important;
  }

  .group-filter-date {
    flex-direction: column;
    * > * {
      margin-bottom: 20px;
    }
  }
  .mimamori-record-group__remark {
    position: relative;
    margin-left: 0;
    left: -150px;
    top: 1.5em;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .MimamoriHistoryComponent {
    &-title {
      width: 90%;
    }
  }
  .v-card {
    width: 100% !important;
    padding: 0 20px;
  }
  .mimamori-record-filter__button {
    text-align: center;
    margin-top: 20px;
  }
  .group-filter-date {
    flex-direction: column;
    * > * {
      margin-bottom: 20px;
    }
  }

  .mimamori-record-group__remark {
    position: static;
    margin-left: 0;
    clear: both;
  }

  .mimamori-record-group__main {
    display: none;
    &-mobile {
      display: table;
      width: 100%;
      .content-label {
        vertical-align: top !important;
        .mimamori-record__marker {
          width: 30px;
          height: 30px;
        }
      }
      .label-col {
        width: 30px;
      }
      .content-col {
        width: 80px;
      }
      .TableCellComponent {
        text-align: unset !important;
        vertical-align: middle;
        .content-safety {
          display: grid;
          grid-template-columns: 70px 1fr;
          .small-content {
            font-size: 12px;
          }
        }

        .content-status {
          width: max-content;
        }
      }
    }
  }
}
</style>
