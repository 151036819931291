import { STATUS } from "@/globals/enums";
import {
  getAlertInfo,
  updateAlertInfo,
  activateEmail,
  sendEmail,
} from "./api/alert.service";
import {
  convertGetAlertInfo,
  convertUpdateAlertInfo,
} from "@/store/converter/alert.converter";

export default class AlertService {
  async getAlertInfo(id) {
    try {
      const result = await getAlertInfo(id);
      return {
        status: STATUS.SUCCESS,
        data: convertGetAlertInfo(result),
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error.response,
      };
    }
  }

  async updateAlertInfo(data) {
    try {
      await updateAlertInfo(convertUpdateAlertInfo(data));
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: {
          ...error.response,
          ...(error?.response?.code === "CUSTOM-OUT_RANGE_ALERT_CONDITIONS" && {
            detail: "異常判定日数の値を小さくしてください。",
          }),
          ...(error?.response?.code ===
            "CUSTOM-DUPLICATE_REQUEST_NOTIFICATION_EMAIL_ERROR" && {
            detail: "アラート通知先のメールアドレスが重複しています。",
          }),
          ...(error?.response?.code ===
            "CUSTOM-DUPLICATE_REQUEST_NOTIFICATION_PHONE_NUMBER_ERROR" && {
            detail: "アラート通知先の電話番号が重複しています。",
          }),
        },
      };
    }
  }

  async sendEmail(mailId) {
    try {
      await sendEmail({
        mail_id: mailId,
      });
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: {
          ...error.response,
          ...(error?.response?.code === "CUSTOM-VERIFIED_EMAIL" && {
            detail: "既に有効なメールアドレスです。",
          }),
          ...(error?.response?.code === "CUSTOM-RECORD_NOT_FOUND_ERROR" && {
            detail: "配信先の情報が見つかりません。",
          }),
        },
      };
    }
  }

  async activateEmail(sessionId) {
    try {
      await activateEmail(sessionId);
      return {
        status: STATUS.SUCCESS,
        response: {
          title: "アラート通知先メールアドレスを有効化しました",
          detail: null,
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: {
          title: "アラート通知先メールアドレスの有効化に失敗しました。",
          detail: "暫くたってから再度お試しください。",
          ...error.response,
          ...(error?.response?.code === "CUSTOM-VERIFIED_EMAIL" && {
            title: "アラート通知先メールアドレスの有効化に失敗しました。",
            detail: "既に有効なメールアドレスです。",
          }),
          ...(error?.response?.code === "CUSTOM-SESSION_ID_ERROR" && {
            title: "アラート通知先メールアドレスの有効化に失敗しました。",
            detail: "セッションIDが無効であるか、有効期限が切れています。",
          }),
          ...(error?.response?.code === "CUSTOM-RECORD_NOT_FOUND_ERROR" && {
            title: "アラート通知先メールアドレスの有効化に失敗しました。",
            detail: "担当する情報が見つかりません。",
          }),
        },
      };
    }
  }
}
