import { STATUS } from "@/globals/enums";
import { getAddress, getInquiry } from "./api/common.service";
export default class CommonService {
  async getAddress(zipCode) {
    try {
      const result = await getAddress({
        post_code: zipCode,
      });
      const data = result.address_list.find(
        (element) => element.post_code === zipCode
      );
      return {
        status: STATUS.SUCCESS,
        data: {
          addressPrefecture: data?.address_prefecture,
          addressCode: data?.address_code,
          addressCity: data?.address_city,
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async getInquiry(username) {
    try {
      const result = await getInquiry(username);
      return {
        status: STATUS.SUCCESS,
        data: {
          inquiryName: result?.inquiry_name,
          inquiryPhoneNumber1: result?.inquiry_phone_number1,
          inquiryPhoneNumber2: result?.inquiry_phone_number2,
          inquiryPhoneNumber3: result?.inquiry_phone_number3,
          inquiryReceptionTime: result?.inquiry_reception_time,
          inquiryNotices: result?.inquiry_notices,
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }
}
