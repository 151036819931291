<template>
  <v-expansion-panels
    class="PanelsComponent"
    :multiple="multiple"
    :class="{
      nested: nested,
    }"
    ><slot></slot
  ></v-expansion-panels>
</template>

<script>
export default {
  name: "PanelsComponent",
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.PanelsComponent {
  display: flex;

  // Default Style - 1
  :not(&.nested) {
    gap: 20px;
  }
  // Nested Child Style - 1
  &.nested {
    gap: 0;
  }
}
</style>
