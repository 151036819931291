import { get, put, post } from "../https/http.service";

export const getAlertInfo = (id, params) => {
  return get(`relatives/alert/configuration/${id}`, params);
};

export const updateAlertInfo = (params) => {
  return put(`relatives/alert/configuration`, params);
};

export const sendEmail = (params) => {
  return post(`relatives/email/check`, params);
};

export const activateEmail = (id) => {
  return put(`relatives/email/check/${id}`);
};
