<template>
  <div
    ref="container1"
    class="ProfileCreateComponent"
    v-show="currentStep === currentSteps.First"
  >
    <StepsComponent :active="currentSteps.First" :steps="steps" />
    <TitleComponent class="ProfileCreateComponent-title">
      <template v-slot:icon
        ><img
          :src="require('@/assets/images/qms/t_ico_profile.svg')"
          class="image"
      /></template>
      <template v-slot:content> プロフィール情報登録 </template>
    </TitleComponent>
    <Form
      v-if="relativeData"
      ref="editForm"
      :validation-schema="profileSchema"
      v-slot="{ values, setFieldValue, meta }"
      @submit="submit"
    >
      <CardComponent class="main" :width="'960px'" :height="'auto'">
        <div class="group">
          <div class="group_title">【親族様など】プロフィール情報</div>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> 氏名 </template>
            <template v-slot:content>
              <div class="two-input-content">
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="lastNameKanji"
                    placeholder="山田"
                    maxlength="10"
                    inputFilter="fullWidth"
                    :defaultValue="relativeData.lastNameKanji"
                  />
                </div>
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="firstNameKanji"
                    placeholder="太朗"
                    maxlength="10"
                    inputFilter="fullWidth"
                    :defaultValue="relativeData.firstNameKanji"
                  />
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> 氏名（カナ）</template>
            <template v-slot:content>
              <div class="two-input-content">
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="lastNameKana"
                    placeholder="ヤマダ"
                    maxlength="20"
                    inputFilter="fullWidth"
                    :defaultValue="relativeData.lastNameKana"
                  />
                </div>
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="firstNameKana"
                    placeholder="タロウ"
                    maxlength="20"
                    inputFilter="fullWidth"
                    :defaultValue="relativeData.firstNameKana"
                  />
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> 郵便番号</template>
            <template v-slot:content>
              <ZipCodeSearchComponent
                name="zipCode"
                :isDisabledButton="
                  !values.zipCode || values.zipCode.length !== 7
                "
                :currentValue="values?.zipCode"
                addressPrefectureInput="addressPrefecture"
                addressCityInput="addressCity"
                :setFieldValue="setFieldValue"
              />
            </template>
          </GroupContentComponent>
          <GroupContentComponent
            :required="true"
            :isTitleTop="true"
            class="group-big"
          >
            <template v-slot:title> 住所</template>
            <template v-slot:content>
              <div class="address-group">
                <SelectComponent
                  name="addressPrefecture"
                  :options="profileEnumsData.prefectureOptions"
                />
                <InputComponent
                  :small="true"
                  :required="true"
                  name="addressCity"
                  :placeholder="$domain.PLACEHOLDER.address"
                  inputFilter="fullWidth"
                  maxlength="20"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal">
            <template v-slot:title>
              <p class="relationship-title">みまもり対象者との関係</p></template
            >
            <template v-slot:content>
              <div class="relationship">
                <InputComponent
                  name="relationship"
                  :small="true"
                  placeholder="例：子"
                  maxlength="10"
                  inputFilter="fullWidth"
                  :defaultValue="relativeData.relationship"
                />
              </div>
            </template>
          </GroupContentComponent>
          <!-- #59427 Hide 特記事項 field -->
          <GroupContentComponent
            class="group-normal"
            :isTitleTop="true"
            v-if="false"
          >
            <template v-slot:title> 特記事項</template>
            <template v-slot:content>
              <div class="notices">
                <TextAreaComponent
                  name="notices"
                  placeholder="特記事項ありましたらご入力ください"
                  :rows="2"
                  maxlength="500"
                />
              </div>
            </template>
          </GroupContentComponent>
          <div class="action-group">
            <ButtonComponent
              width="150px"
              height="50px"
              color="#8d9caa"
              :disabled="!(meta.valid && meta.dirty)"
              >次へ</ButtonComponent
            >
          </div>
          <LinkComponent :link="$domain.HELP_CONTENT_LINK['2-1-e']">
            <span class="d-flex align-center justify-center black">
              <i class="icon icon-help"></i>
              操作方法がご不明な場合
            </span>
          </LinkComponent>
        </div>
        <InquiryBannerComponent class="mb-5" />
      </CardComponent>
    </Form>
  </div>
  <div
    ref="container2"
    class="ProfileCreateComponent"
    v-show="currentStep === currentSteps.Second"
  >
    <StepsComponent :active="currentSteps.Second" :steps="steps" />
    <TitleComponent class="ProfileCreateComponent-title">
      <template v-slot:icon><i class="icon icon-t_ico_alert"></i></template>
      <template v-slot:content> アラート通知先・条件設定 </template>
    </TitleComponent>
    <Form
      v-if="relativeData && relativeInfo"
      ref="alertForm"
      @submit="submitAll"
      :validation-schema="alertEmailSchema"
      v-slot="{ meta, validateField }"
    >
      <CardComponent class="main" :width="'960px'" :height="'auto'">
        <div class="group">
          <div class="group_title">アラート発報する異常判定日数</div>
          <GroupContentComponent class="group-normal" v-if="isAlertLoaded">
            <template v-slot:title> 異常判定日数</template>
            <template v-slot:content>
              <div class="flex">
                <SelectComponent
                  name="alertDate"
                  :isSelectDateRange="true"
                  :options="alertOptions"
                  :initialValue="defaultAlertOptions"
                  @trigle-change="errorDetail = null"
                />
                <span class="telephone-memo telephone-memo-rp">
                  ※本システムの異常判定にて、異常判定の連続日数が設定値となった場合、<br />下記で設定したメールアドレスおよび電話番号にアラートを通知いたします。
                </span>
                <span class="telephone-memo telephone-memo-rp-mobile">
                  ※本システムの異常判定にて、異常判定の連続日数が設定値となった場合、下記で設定したメールアドレスおよび電話番号にアラートを通知いたします。
                </span>
              </div>
            </template>
          </GroupContentComponent>
        </div>
        <div class="group group-marginTop">
          <div class="group_title group_title--noborder">
            アラート通知先 メールアドレス
          </div>
          <p class="mailAddress-memo mailAddress-memo-rp">
            ※メールアドレス２、３の登録・修正を実施する場合は、<br />登録ボタン押下後に「アラート通知先・条件設定」画面を表示して「テストメール配信」ボタンを押下し、メールアドレスの有効化を行ってください。
          </p>
          <p class="mailAddress-memo mailAddress-memo-rp-mobile">
            ※メールアドレス２、３の登録・修正を実施する場合は、登録ボタン押下後に「アラート通知先・条件設定」画面を表示して「テストメール配信」ボタンを押下し、メールアドレスの有効化を行ってください。
          </p>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> メールアドレス&emsp;1</template>
            <template v-slot:content>
              <div class="mailAddress">
                <InputComponent
                  name="mailAddress1"
                  :small="true"
                  placeholder="メールアドレスを入力"
                  maxlength="100"
                  :defaultValue="relativeInfo?.relativesEmail || ''"
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal">
            <template v-slot:title> メールアドレス&emsp;2</template>
            <template v-slot:content>
              <div class="mailAddress">
                <InputComponent
                  name="mailAddress2"
                  :small="true"
                  placeholder="メールアドレスを入力"
                  maxlength="100"
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal">
            <template v-slot:title> メールアドレス&emsp;3</template>
            <template v-slot:content>
              <div class="mailAddress">
                <InputComponent
                  name="mailAddress3"
                  :small="true"
                  placeholder="メールアドレスを入力"
                  maxlength="100"
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
        </div>
        <div class="group group-marginTop">
          <div class="group_title">アラート通知先 電話番号（SMS送信先）</div>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> 携帯電話番号</template>
            <template v-slot:content>
              <div class="flex">
                <TelephoneInputComponent
                  :groupInput="relativeData.phoneData.phoneNumberGroup"
                  :isSelect="true"
                  :options="profileEnumsData.telephone3Digits"
                  :defaultFirst3Digits="relativeData.phoneData.firstPhoneNumber"
                  :validateField="validateField"
                />
                <span class="telephone-memo"
                  >※半角数字で入力してください。</span
                >
              </div>
            </template>
          </GroupContentComponent>
        </div>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="action-group">
          <ButtonComponent
            width="150px"
            height="50px"
            color="#8d9caa"
            type="outline"
            buttonType="button"
            @click="cancel"
            >もどる</ButtonComponent
          >
          <ButtonComponent
            width="150px"
            height="50px"
            color="#8d9caa"
            :disabled="!meta.valid"
            class="btn-submit"
            >次へ</ButtonComponent
          >
        </div>
        <LinkComponent :link="$domain.HELP_CONTENT_LINK['2-1-f']">
          <span class="d-flex align-center justify-center black">
            <i class="icon icon-help"></i>
            操作方法がご不明な場合
          </span>
        </LinkComponent>
        <InquiryBannerComponent class="mb-5" />
      </CardComponent>
    </Form>
  </div>
  <div
    ref="container3"
    class="ProfileCreateComponent"
    v-show="currentStep === currentSteps.Last"
  >
    <StepsComponent :active="currentSteps.Last" :steps="steps" />
    <CardComponent class="main main-success" :width="'960px'" :height="'455px'">
      <span class="success-content">
        <strong>テストメール配信</strong> を押してください。<br />
        メールアドレスが有効であるかを、テストメールを配信して確認してください。
      </span>
      <div class="action-group action-group-success">
        <ButtonComponent
          width="250px"
          height="50px"
          color="#8d9caa"
          @click="testEmail"
          >テストメール配信</ButtonComponent
        >
      </div>
      <LinkComponent :link="$domain.HELP_CONTENT_LINK['2-1-g']">
        <span class="d-flex align-center justify-center black">
          <i class="icon icon-help"></i>
          操作方法がご不明な場合
        </span>
      </LinkComponent>
    </CardComponent>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { mapState } from "vuex";
import { Form } from "vee-validate";
import UserService from "@/services/user.service";
import AlertService from "@/services/alert.service";
import HomeService from "@/services/home.service";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import {
  convertProfileCreateSteps,
  convertFirstProfileCreate,
} from "@/store/converter/profile.convert";
import { STATUS } from "@/globals/enums";
import {
  createProfileSchema,
  createAlertEmailSchema,
} from "@/validations/schemas/createProfileSchema";

export default {
  name: "ProfileCreateComponent",
  components: {
    Form,
  },
  created() {
    this.steps = convertProfileCreateSteps();
    this.profileEnumsData = convertFirstProfileCreate();
    this.currentSteps = {
      First: 1,
      Second: 2,
      Last: 3,
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState({
      mimamoriTargetPersonId: (state) =>
        state.global?.globalData?.mimamoriTargetPersonId,
    }),
  },
  data: () => ({
    homeService: new HomeService(),
    userService: new UserService(),
    alertService: new AlertService(),
    confirmDialogService: new ConfirmDialogService(),
    currentStep: 1,
    profileData: null,
    errorDetail: null,
    profileSchema: createProfileSchema,
    alertEmailSchema: createAlertEmailSchema,
    alertInfo: null,
    alertOptions: [],
    defaultAlertOptions: 3,
    relativeData: null,
    relativeInfo: null,
    isAlertLoaded: false,
  }),
  methods: {
    async getHome() {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.homeService.getHome();
      if (result.status === STATUS.SUCCESS) {
        this.relativeInfo = result.data;
      }
      const alertResult = await this.alertService.getAlertInfo(
        this.mimamoriTargetPersonId
      );
      if (result.status === STATUS.SUCCESS) {
        this.alertInfo = alertResult.data;
        this.alertOptions = alertResult.data.alertDateOptions;
        this.defaultAlertOptions = alertResult.data.defaultAlertOptions;
      }
      this.isAlertLoaded = true;
      this.$store.dispatch("global/updateLoadingStatus", false);
    },
    async getData(loading) {
      loading && this.$store.dispatch("global/updateLoadingStatus", true);
      if (this.mimamoriTargetPersonId) {
        const relativeInfoResult = await this.userService.getRalativeInfo(
          this.mimamoriTargetPersonId
        );
        if (relativeInfoResult.status === STATUS.SUCCESS) {
          this.relativeData = relativeInfoResult?.data;
        }
      }
      loading && this.$store.dispatch("global/updateLoadingStatus", false);
    },
    async cancel() {
      this.errorDetail = null;
      this.currentStep = this.currentSteps.First;

      // Scroll to the top of the page after the step changes.
      await nextTick();
      const $container = this.$refs.container1;
      $container.scrollIntoView();
    },
    async submit(data) {
      await this.getHome();
      this.profileData = data;
      this.currentStep = this.currentSteps.Second;

      // Scroll to the top of the page after the step changes.
      await nextTick();
      const $container = this.$refs.container2;
      $container.scrollIntoView();
    },
    async submitAll(data) {
      const sendingData = {
        ...data,
        ...this.profileData,
      };
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.userService.createProfileInfo(sendingData);
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
      if (result.status === STATUS.SUCCESS) {
        this.currentStep = this.currentSteps.Last;

        // Scroll to the top of the page after the step changes.
        await nextTick();
        const $container = this.$refs.container3;
        $container.scrollIntoView();
      }
    },
    async testEmail() {
      /**
       * Get the latest information again.
       */
      await this.getHome();
      /**
       * Verify the first (required) email address.
       */
      const result = await this.alertService.sendEmail(
        this.alertInfo?.mailAddressId1
      );
      if (result.status === STATUS.SUCCESS) {
        this.confirmDialogService.openConfirmDialogWithEmphasis(
          "テストメールを送信しました。",
          null,
          "メールに記載のURLにアクセスすれば\n登録完了となります。",
          /**
           * #69313
           * After successfully sending a test email, go to the home screen.
           */
          () => this.$router.push({ name: "HomeComponent" })
        );
      }
      if (result?.status === STATUS.ERROR && result.response?.detail) {
        this.confirmDialogService.openConfirmDialogWithEmphasis(
          "テストメールの配信に失敗しました。",
          result.response.detail,
          "次の画面で、アラート通知先のメールアドレスを変更し、テストメール配信を押してください。",
          () => this.$router.push({ name: "AlertPreviewComponent" })
        );
      }
    },
  },
  watch: {
    mimamoriTargetPersonId: function () {
      this.getData(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.ProfileCreateComponent {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  &-title {
    width: 960px;
    margin: 30px auto 0;
    display: flex;
    color: #000000 !important;
    .icon-ico_user::before {
      color: #000000;
    }
  }
  .main {
    padding: 30px 40px;
    margin-bottom: 40px;
    &-success {
      margin-top: 61px;
      flex-direction: column;
      align-items: center;
      display: flex;
      .success-content {
        margin: 20px 0 15px;
        text-align: center;
      }
    }
    .flex {
      display: flex;
      align-items: center;
    }
    .group {
      &-marginTop {
        margin-top: 31px;
      }
      &_title {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
        border-bottom: solid 2px #d0d9df;
      }
      &_title--noborder {
        padding-bottom: 0;
        border-bottom: none;
      }
      .mailAddress {
        width: 400px;
      }
      .mailAddress-memo {
        font-size: 12px;
        font-weight: normal;
        color: #7c8185;
        padding-top: 0.5em;
        padding-right: 10px;
        padding-bottom: 15px;
        padding-left: 1em;
        text-indent: -1em;
        border-bottom: solid 2px #d0d9df;
        &-rp {
          display: block;
          &-mobile {
            display: none;
          }
        }
      }
      .telephone-memo {
        margin-left: 50px;
        font-size: 12px;
        font-weight: normal;
        color: #7c8185;
        padding-left: 1em;
        text-indent: -1em;
        &-rp {
          display: block;
          &-mobile {
            display: none;
          }
        }
      }
      &-big {
        height: 165px;
        padding: 35px 10px;
      }
      &-normal {
        height: 100px;
      }
    }
  }
  .two-input-content {
    display: flex;
    gap: 20px;
    .input-content {
      width: 170px;
    }
  }
  .address-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .action-group {
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
  .action-group-success {
    margin-bottom: 0;
  }
  .relationship-title {
    width: 100px;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .ProfileCreateComponent {
    &-title {
      width: 300px;
      margin: 0 auto 0 20px;
    }
    form {
      height: 100%;
      width: 100%;
    }
    .main {
      width: 100% !important;
      height: 100% !important;
      padding: 30px 20px;
      margin-bottom: 0;
      .flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        gap: 20px;
      }
      .group {
        .mailAddress-memo {
          padding-left: calc(1em + 10px);
          &-rp {
            display: none;
            &-mobile {
              display: block;
            }
          }
        }
        .telephone-memo {
          margin-left: 0;
          width: 100%;
          &-rp {
            display: none;
            &-mobile {
              display: block;
            }
          }
        }
        &-big {
          height: 100%;
          padding: 15px 10px;
        }
        &-normal {
          height: 100%;
        }
      }
      .two-input-content {
        display: flex;
        flex-direction: column;
        gap: 0;
        width: 100%;
        .input-content {
          width: 100%;
        }
      }
      .address-group {
        margin-top: 20px;
        gap: 30px;
        width: 100%;
      }
      .action-group {
        flex-direction: column-reverse;
        gap: unset;
        .btn-submit {
          margin-bottom: 20px;
        }
      }
      .relationship {
        width: 100%;
        &-title {
          width: 100%;
        }
      }
      .notices {
        margin-top: 20px;
      }
      .success-content {
        margin: 20px 0 60px;
        text-align: center;
        width: 310px;
      }
    }
  }
}
</style>
