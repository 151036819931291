<template>
  <div class="AlertPreviewComponent">
    <TitleComponent class="AlertPreviewComponent-title">
      <template v-slot:icon>
        <i class="icon icon-t_ico_alert"></i>
      </template>
      <template v-slot:content>アラート通知先・条件設定</template>
    </TitleComponent>
    <CardComponent
      class="main"
      :width="'960px'"
      :height="'auto'"
      v-if="alertInfo"
    >
      <section class="message-group" v-if="alertInfo.showMessage">
        <div class="message-group-title">
          サービス利用開始にはメールアドレスの有効性確認が必要です。
        </div>
        <div class="message-group-content">
          メールアドレスが有効であるかを、テストメールを配信して確認してください。
        </div>
        <div class="message-group-inquiry">
          <p class="inquiry-title">
            テストメールが配信されない場合は、以下のお問い合わせ先へご連絡下さい。
          </p>
          <InquiryBannerComponent
            class="inquiry-content"
            :defaultStyle="false"
          />
        </div>
      </section>
      <section>
        <div class="form-groups">
          <div class="form-groups-title">アラート発報する異常判定日数</div>
          <GroupContentComponent>
            <template v-slot:title> 異常判定日数 </template>
            <template v-slot:content>
              <p class="marginTop10">{{ alertInfo?.alertDate?.label }}</p>
            </template>
          </GroupContentComponent>
        </div>
      </section>
      <section>
        <div class="form-groups">
          <div class="form-groups-title">アラート通知先 メールアドレス</div>
          <GroupContentComponent
            class="mail-activation"
            :class="alertInfo?.altFlag1?.class"
          >
            <template v-slot:title> メールアドレス 1 </template>
            <template v-slot:content>
              <div class="flex">
                <p class="mr-auto">{{ alertInfo?.mailAddress1 }}</p>
                <div class="button-group">
                  <ButtonComponent
                    width="190px"
                    height="30px"
                    v-if="alertInfo?.showButton1 && !alertInfo?.altFlag1?.value"
                    @click="testEmail(alertInfo?.mailAddressId1)"
                    >テストメール配信</ButtonComponent
                  >
                  <StatusChipComponent
                    v-if="alertInfo?.altFlag1?.label"
                    :type="alertInfo?.altFlag1?.type"
                    >{{ alertInfo?.altFlag1?.label }}</StatusChipComponent
                  >
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent
            class="mail-activation"
            :class="alertInfo?.altFlag2?.class"
          >
            <template v-slot:title> メールアドレス 2 </template>
            <template v-slot:content>
              <div class="flex">
                <p class="mr-auto">{{ alertInfo?.mailAddress2 }}</p>
                <div class="button-group">
                  <ButtonComponent
                    width="190px"
                    height="30px"
                    v-if="alertInfo?.showButton2 && !alertInfo?.altFlag2?.value"
                    @click="testEmail(alertInfo?.mailAddressId2)"
                    >テストメール配信</ButtonComponent
                  >
                  <StatusChipComponent
                    v-if="alertInfo?.altFlag2?.label"
                    :type="alertInfo?.altFlag2?.type"
                    >{{ alertInfo?.altFlag2?.label }}</StatusChipComponent
                  >
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent
            class="mail-activation mail-activation_empty"
            :class="alertInfo?.altFlag3?.class"
          >
            <template v-slot:title> メールアドレス 3 </template>
            <template v-slot:content>
              <div class="flex">
                <p class="mr-auto">{{ alertInfo?.mailAddress3 }}</p>
                <div class="button-group">
                  <ButtonComponent
                    width="190px"
                    height="30px"
                    v-if="alertInfo?.showButton3 && !alertInfo?.altFlag3?.value"
                    @click="testEmail(alertInfo?.mailAddressId3)"
                    >テストメール配信</ButtonComponent
                  >
                  <StatusChipComponent
                    v-if="alertInfo?.altFlag3?.label"
                    :type="alertInfo?.altFlag3?.type"
                    >{{ alertInfo?.altFlag3?.label }}</StatusChipComponent
                  >
                </div>
              </div>
            </template>
          </GroupContentComponent>
        </div>
      </section>
      <section>
        <div class="form-groups">
          <div class="form-groups-title">
            アラート通知先 電話番号（SMS送信先）
          </div>
          <GroupContentComponent>
            <template v-slot:title> 携帯電話番号 </template>
            <template v-slot:content>
              <p class="marginTop10">{{ alertInfo.parsePhoneNumber }}</p>
            </template>
          </GroupContentComponent>
        </div>
      </section>
      <div class="action-group">
        <ButtonComponent @click="goEditPage" width="100px" height="30px"
          >編集</ButtonComponent
        >
      </div>
    </CardComponent>
  </div>
</template>

<script>
import AlertService from "@/services/alert.service";
import { STATUS } from "@/globals/enums";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";

export default {
  name: "AlertPreviewComponent",
  data: () => ({
    alertService: new AlertService(),
    confirmDialogService: new ConfirmDialogService(),
  }),
  props: {
    alertInfo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    goEditPage() {
      this.$router.push({ name: "AlertEditComponent" });
    },
    async testEmail(mailId) {
      if (!mailId) return;
      const result = await this.alertService.sendEmail(mailId);
      if (result.status === STATUS.SUCCESS) {
        this.confirmDialogService.openConfirmDialog(
          "テストメールを送信しました。",
          "メールが受信されていることをご確認ください。"
        );
      }
      if (result?.status === STATUS.ERROR && result.response?.detail) {
        this.confirmDialogService.openConfirmDialog(
          "テストメールの配信に失敗しました。",
          result.response.detail
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.AlertPreviewComponent {
  display: flex;
  margin: 30px auto;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-title {
    display: flex;
    width: 960px;
    font-size: 20px;
    font-weight: 500;

    // TODO: Refactoring
    .icon {
      line-height: 1.5;
      margin-right: 0.25em;
      vertical-align: middle;
    }
  }

  .main {
    padding: 30px 40px;

    .form-groups {
      margin: 0 0 30px;
      &-title {
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-medium);
        padding-bottom: 5px;
        border-bottom: solid 3px #d0d9df;
      }
    }
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .button-group {
        display: flex;
      }
    }
  }

  // Blocks
  .mail-activation {
    // Nested Override
    .ButtonComponent {
      margin-right: 20px;
    }

    // Modifiers
    &_valid {
    }
    &_invalid {
      // TODO: Refactoring
      background: var(--default-color);
    }
    &_empty {
    }
  }

  .action-group {
    text-align: right;
  }
  .message-group {
    display: flex;
    background-color: var(--alert-bgcolor);
    padding: 13px 20px 18px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    &-title {
      font-size: 20px;
      font-weight: 500;
      color: var(--alert-text-color);
      margin-bottom: 10px;
    }
    &-content {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-black-text);
      margin-bottom: 20px;
      padding: 0 1em;
    }
    &-inquiry {
      padding: 15px;
      background-color: var(--white-color);
      .inquiry-title {
        color: var(--color-black-text);
        font-size: var(--font-size-default);
        font-weight: var(--font-weight-medium);
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--valley-smoke-color);
      }
      .inquiry-content {
        display: flex;
        justify-content: center;
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .AlertPreviewComponent {
    width: 100%;
    margin: 0;
    &-title {
      width: 300px;
      margin: 0 auto 0 20px;
    }
    form {
      height: 100%;
      width: 100%;
    }
    .main {
      width: 100% !important;
      padding: 30px 20px;
      .message-group {
        text-align: center;
        padding: 20px 8px;
      }
      .flex {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .button-group {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .StatusChipComponent {
            margin-left: auto;
          }
        }
      }
      .marginTop10 {
        margin-top: 10px;
      }
      .action-group {
        text-align: center;
      }
    }
  }
}
</style>
