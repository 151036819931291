import { get, put, post } from "../https/http.service";

export const getProfile = (id) => {
  return get(`mimamori/information/${id}`);
};

export const getRelative = (id) => {
  return get(`relatives/information/${id}`);
};

export const updateAutoCallTelephone = (id, params) => {
  return put(`mimamori/information/${id}`, params);
};

export const updateRelative = (id, params) => {
  return put(`relatives/information/${id}`, params);
};

export const updateRelativeEmail = (params) => {
  return put("relatives/email/", params);
};

export const createProfileInfo = (params) => {
  return post("relatives/information", params);
};

export const checkPhoneNumberMimamori = (id) => {
  return post(`mimamori/phone/check/${id}`);
};
