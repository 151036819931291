<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    :content-class="`ConfirmEmailDialog modal-content ${className}`"
    @click-outside="onClose()"
  >
    <span class="modal__title">
      <span class="icon-title icon-ico_info"></span>
      <div>
        サービス利用開始には<br />メールアドレスの有効性確認が必要です。
      </div>
    </span>

    <div class="modal__content">
      <p>
        メールアドレスが有効であるかを、<br />
        テストメールを配信して確認してください。
      </p>
    </div>
    <ButtonComponent
      width="250px"
      height="50px"
      @click="
        close();
        onClose();
      "
    >
      テストメール配信へ
    </ButtonComponent>
  </vue-final-modal>
</template>

<script>
export default {
  name: "ConfirmEmailDialog",
  props: {
    title: {
      type: [String, Number],
      default: null,
    },
    content: {
      type: [String, Number],
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    className: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.ConfirmEmailDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 40px 40px 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      border: 1px solid #888;
      border-radius: 5px;
      width: 500px;
      height: 257px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: left;
          min-width: 224px;
          display: flex;
          align-items: center;
          .icon-title {
            font-size: 30px;
            margin-right: 10px;
            &::before {
              color: #000000;
            }
          }
        }
        &__content {
          font-size: 14px;
          text-align: left;
          margin-left: 50px;
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
      }
    }
    &-403 {
      width: 430px;
      padding: 30px 20px;
    }
  }
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: $mobile-breakpoint) {
  ::v-deep(.ConfirmEmailDialog) {
    &.modal {
      &-content {
        width: 320px;
        height: 290px;
        padding: 20px;
        .modal {
          &__title {
            align-items: start;
            font-size: 16px;
          }
          &__content {
            margin-left: 45px;
          }
        }
      }
    }
  }
}
</style>
