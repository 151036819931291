<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    :content-class="`ConfirmDialog modal-content ${className}`"
    @click-outside="onClose()"
  >
    <button
      class="modal__close"
      @click="
        close();
        onClose();
      "
    >
      <span class="icon-ico_closedialog"></span>
    </button>

    <span class="modal__title">{{ title }}</span>

    <div
      class="modal__content"
      :class="{
        hasContent: content,
      }"
    >
      {{ content }}
    </div>
    <ButtonComponent
      width="98px"
      height="30px"
      color="#8d9caa"
      @click="
        close();
        onClose();
      "
    >
      OK
    </ButtonComponent>
  </vue-final-modal>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    title: {
      type: [String, Number],
      default: null,
    },
    content: {
      type: [String, Number],
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    className: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.ConfirmDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 40px 40px 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      border: 1px solid #888;
      border-radius: 5px;
      width: 420px;
      min-height: 170px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: center;
          min-width: 224px;
          white-space: pre-wrap;
        }
        &__content {
          font-size: 14px;
          text-align: center;
          white-space: pre-wrap;
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
      }
    }
    &-403 {
      width: 430px;
      padding: 30px 20px;
    }
    &-resetPassword {
      width: 396px;
      height: 218px;
      padding: 43px 40px 29px 40px;
      .modal__title {
        font-size: 16px;
        margin-left: 10px;
      }
      .modal__content {
        text-align: left;
      }
    }
  }
}
.hasContent {
  margin: 20px 0;
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: $mobile-breakpoint) {
  ::v-deep(.ConfirmDialog) {
    &.modal {
      &-content {
        width: 320px;
        height: 170px;
        padding: 30px 20px;
        .modal__title {
          font-size: 16px;
        }
        .modal__content {
          text-align: left;
        }
        .modal__close {
          top: 3px;
          right: 10px;
          .icon-ico_closedialog {
            font-size: 15px;
          }
        }
      }
      &-resetPassword {
        width: 320px;
        height: 210px;
        padding: 30px 20px;
        .modal__title {
          font-size: 16px;
        }
        .modal__content {
          text-align: left;
        }
      }
    }
  }
  .hasContent {
    margin: 0;
  }
}
</style>
