import * as Yup from "yup";
import {
  validateTelephoneNormal,
  validateNotices,
  validateName,
  validateNameRequiredKana,
  validateZipCode,
  validateSelectString,
  validateAddressCity,
  validateRelationship,
  validateTelephoneGroupSelect,
} from "../common";

export const updateAutoCallSchema = Yup.object().shape({
  phoneNumber: validateTelephoneNormal,
  notices: validateNotices,
});

export const updateRelativeSchema = Yup.object().shape({
  lastNameKanji: validateName.lastname(),
  firstNameKanji: validateName.firstname(),
  lastNameKana: validateNameRequiredKana.lastname(),
  firstNameKana: validateNameRequiredKana.lastname(),
  zipCode: validateZipCode,
  addressPrefecture: validateSelectString(0),
  addressCity: validateAddressCity,
  firstPathNumbers: validateTelephoneGroupSelect.firstPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  secondPathNumbers: validateTelephoneGroupSelect.secondPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  thirtPathNumbers: validateTelephoneGroupSelect.lastPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  relationship: validateRelationship,
  notices: validateNotices,
});
