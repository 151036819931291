<template>
  <div class="AlertEditComponent">
    <TitleComponent class="AlertEditComponent-title">
      <template v-slot:icon>
        <i class="icon icon-t_ico_alert"></i>
      </template>
      <template v-slot:content>アラート通知先・条件設定</template>
    </TitleComponent>
    <Form
      ref="alertForm"
      @submit="editAlertInfo"
      v-if="alertInfo"
      v-slot="{ meta, validateField }"
      :validation-schema="schema"
    >
      <CardComponent class="main" :width="'960px'" :height="'auto'">
        <div class="group">
          <div class="group_title">アラート発報する異常判定日数</div>
          <GroupContentComponent class="group-normal">
            <template v-slot:title> 異常判定日数</template>
            <template v-slot:content>
              <div class="flex">
                <SelectComponent
                  name="alertDate"
                  :isSelectDateRange="true"
                  :options="alertInfo.alertDateOptions"
                  :initialValue="alertInfo?.alertDate?.value"
                  @trigle-change="errorDetail = null"
                />
                <span class="telephone-memo telephone-memo-rp">
                  ※本システムの異常判定にて、異常判定の連続日数が設定値となった場合、<br />下記で設定したメールアドレスおよび電話番号にアラートを通知いたします。
                </span>
                <span class="telephone-memo telephone-memo-rp-mobile">
                  ※本システムの異常判定にて、異常判定の連続日数が設定値となった場合、下記で設定したメールアドレスおよび電話番号にアラートを通知いたします。
                </span>
              </div>
            </template>
          </GroupContentComponent>
        </div>
        <div class="group group-marginTop">
          <div class="group_title group_title--noborder">
            アラート通知先 メールアドレス
          </div>
          <p class="mailAddress-memo mailAddress-memo-rp">
            ※メールアドレスの登録・修正を実施した場合は、次の画面で該当アドレスの「テストメール配信」ボタンを押下し、<br />メールアドレスの有効化を行ってください。
          </p>
          <p class="mailAddress-memo mailAddress-memo-rp-mobile">
            ※メールアドレスの登録・修正を実施した場合は、次の画面で該当アドレスの「テストメール配信」ボタンを押下し、メールアドレスの有効化を行ってください。
          </p>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> メールアドレス&emsp;1</template>
            <template v-slot:content>
              <div class="mailAddress">
                <InputComponent
                  name="mailAddress1"
                  :small="true"
                  placeholder="メールアドレスを入力"
                  maxlength="100"
                  :defaultValue="
                    alertInfo.showButton1 ? alertInfo.mailAddress1 : ''
                  "
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal">
            <template v-slot:title> メールアドレス&emsp;2</template>
            <template v-slot:content>
              <div class="mailAddress">
                <InputComponent
                  name="mailAddress2"
                  :small="true"
                  placeholder="メールアドレスを入力"
                  maxlength="100"
                  :defaultValue="
                    alertInfo.showButton2 ? alertInfo.mailAddress2 : ''
                  "
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-normal">
            <template v-slot:title> メールアドレス&emsp;3</template>
            <template v-slot:content>
              <div class="mailAddress">
                <InputComponent
                  name="mailAddress3"
                  :small="true"
                  placeholder="メールアドレスを入力"
                  maxlength="100"
                  :defaultValue="
                    alertInfo.showButton3 ? alertInfo.mailAddress3 : ''
                  "
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
        </div>
        <div class="group group-marginTop">
          <div class="group_title">アラート通知先 電話番号（SMS送信先）</div>
          <GroupContentComponent class="group-normal" :required="true">
            <template v-slot:title> 携帯電話番号</template>
            <template v-slot:content>
              <div class="flex">
                <TelephoneInputComponent
                  :groupInput="alertInfo.phoneNumberGroup"
                  :isSelect="true"
                  :options="profileEnumsData.telephone3Digits"
                  :defaultFirst3Digits="alertInfo.phoneNumberGroup[0].value"
                  :validateField="validateField"
                />
                <span class="telephone-memo"
                  >※半角数字で入力してください。</span
                >
              </div>
            </template>
          </GroupContentComponent>
        </div>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="action-group">
          <ButtonComponent
            width="150px"
            height="50px"
            color="#8d9caa"
            type="outline"
            buttonType="button"
            @click="cancel"
            >キャンセル</ButtonComponent
          >
          <ButtonComponent
            width="150px"
            height="50px"
            color="#8d9caa"
            class="btn-submit"
            :disabled="!meta.valid"
            >登録</ButtonComponent
          >
        </div>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import AlertService from "@/services/alert.service";
import { Form } from "vee-validate";
import { convertFirstProfileCreate } from "@/store/converter/profile.convert";
import { STATUS } from "@/globals/enums";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import { createAlertEmailSchema } from "@/validations/schemas/createProfileSchema";

export default {
  name: "AlertEditComponent",
  components: {
    Form,
  },
  created() {
    this.profileEnumsData = convertFirstProfileCreate();
  },
  data: () => ({
    alertService: new AlertService(),
    confirmDialogService: new ConfirmDialogService(),
    errorDetail: null,
    schema: createAlertEmailSchema,
  }),
  props: {
    alertInfo: {
      type: Object,
      default: () => {},
    },
    getAgain: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    async editAlertInfo(data) {
      const sendingData = {
        ...this.alertInfo,
        ...data,
        sendingParsePhoneNumber:
          data.firstPathNumbers +
          "-" +
          data.secondPathNumbers +
          "-" +
          data.thirtPathNumbers,
      };
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.alertService.updateAlertInfo(sendingData);
      if (result.status === STATUS.SUCCESS) {
        await this.getAgain(false);
        this.confirmDialogService.openConfirmDialog(
          "アラート通知条件を編集しました。",
          null,
          () => this.$router.push({ name: "AlertPreviewComponent" })
        );
      }
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
      this.$store.dispatch("global/updateLoadingStatus", false);
    },
    cancel() {
      this.$router.push({ name: "AlertPreviewComponent" });
    },
  },
};
</script>

<style scoped lang="scss">
.AlertEditComponent {
  display: flex;
  margin: 30px auto;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-title {
    display: flex;
    width: 960px;
    font-size: 20px;
    font-weight: 500;

    // TODO: Refactoring
    .icon {
      line-height: 1.5;
      margin-right: 0.25em;
      vertical-align: middle;
    }
  }

  .main {
    padding: 30px 40px;
    margin-bottom: 40px;
    .flex {
      display: flex;
      align-items: center;
    }
    .group {
      &-marginTop {
        margin-top: 31px;
      }
      &_title {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
        border-bottom: solid 2px #d0d9df;
      }
      &_title--noborder {
        padding-bottom: 0;
        border-bottom: none;
      }
      .mailAddress {
        width: 400px;
      }
      .mailAddress-memo {
        font-size: 12px;
        font-weight: normal;
        color: #7c8185;
        padding-top: 0.5em;
        padding-right: 10px;
        padding-bottom: 15px;
        padding-left: 1em;
        text-indent: -1em;
        border-bottom: solid 2px #d0d9df;
        &-rp {
          display: block;
          &-mobile {
            display: none;
          }
        }
      }
      .telephone-memo {
        margin-left: 50px;
        font-size: 12px;
        font-weight: normal;
        color: #7c8185;
        padding-left: 1em;
        text-indent: -1em;
        &-rp {
          display: block;
          &-mobile {
            display: none;
          }
        }
      }
      &-normal {
        height: 100px;
      }
    }
  }
  .action-group {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .AlertEditComponent {
    width: 100%;
    margin: 0;
    &-title {
      width: 300px;
      margin: 0 auto 0 20px;
    }
    form {
      height: 100%;
      width: 100%;
    }
    .main {
      width: 100% !important;
      padding: 30px 20px;
      margin-bottom: 0;
      .flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        gap: 20px;
      }
      .group {
        .mailAddress-memo {
          padding-left: calc(1em + 10px);
          &-rp {
            display: none;
            &-mobile {
              display: block;
            }
          }
        }
        .telephone-memo {
          margin-left: 0;
          width: 100%;
          &-rp {
            display: none;
            &-mobile {
              display: block;
            }
          }
        }
        &-normal {
          height: 100%;
        }
      }
      .action-group {
        flex-direction: column-reverse;
        gap: 0 !important;
        .btn-submit {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
