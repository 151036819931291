<template>
  <div class="HomeComponent">
    <CardComponent class="mimamori-dashboard" :width="'960px'" :height="'auto'">
      <section class="mimamori-target mimamori-dashboard__status-current">
        <h1 class="mimamori-target__title">
          <span class="mimamori-target__name-label">みまもり対象者：</span>
          <em class="mimamori-target__name-full">
            {{ homeData?.mimamoriInfo?.lastNameKanji || "" }}
            {{ homeData?.mimamoriInfo?.firstNameKanji || "" }}
          </em>
          <span class="mimamori-target__name-postfix">様</span>
        </h1>

        <!-- pause with service status 3 4 -->
        <!-- service_status === 3 or service_status === 4 -->
        <!-- Case B -->
        <template
          v-if="
            homeData?.mimamoriInfo?.serviceStatus === 3 ||
            homeData?.mimamoriInfo?.serviceStatus === 4
          "
        >
          <h2
            class="mimamori-target__status-mark mimamori-target__status-mark_status-fuzai"
          >
            <span class="text-large">サービス準備中</span>
          </h2>
          <p class="mimamori-target__status-date">
            <span>
              <time
                v-formatDateTimeJanpanese="
                  homeData?.mimamoriInfo?.serviceStartDate
                "
              ></time
              >より
              <br />
              <span>サービス開始</span>
            </span>
          </p>
        </template>

        <!-- service_status === 9 -->
        <template v-if="homeData?.mimamoriInfo?.serviceStatus === 9">
          <h2
            class="mimamori-target__status-mark mimamori-target__status-mark_status-contract-error"
          >
            <span class="text-xxlarge">電気契約エラー</span>
            <br />
            <span class="text-large">です</span>
          </h2>
          <p class="mimamori-target__status-date"></p>
        </template>

        <!-- (service_status === 5) or (service_ status === 6 and current_status === null) -->
        <!-- Case C -->
        <template
          v-if="
            homeData?.mimamoriInfo?.serviceStatus === 5 ||
            (homeData?.mimamoriInfo?.serviceStatus === 6 &&
              !homeData?.currentStatus)
          "
        >
          <div>
            <h2
              class="mimamori-target__status-mark mimamori-target__status-mark_status-fuzai"
            >
              <span class="text-large">サービス準備中</span>
            </h2>
            <p class="mimamori-target__status-date">
              <span>
                <time
                  v-formatDateTimeJanpanese="
                    homeData?.mimamoriInfo?.serviceStartDate
                  "
                ></time
                >より
                <br />
                <span>サービス開始</span>
              </span>
            </p>
          </div>
        </template>

        <!-- (service_ status === 6 and current_status !== null) or (service_status === 7) -->
        <!-- Case D -->
        <div
          v-if="
            (homeData?.mimamoriInfo?.serviceStatus === 6 &&
              homeData?.currentStatus) ||
            homeData?.mimamoriInfo?.serviceStatus === 7
          "
        >
          <!-- complete -->
          <template
            v-if="
              homeData?.currentStatus === 1 ||
              homeData?.currentStatus === 2 ||
              homeData?.currentStatus === 3
            "
          >
            <h2
              class="mimamori-target__status-mark mimamori-target__status-mark_status-seijyo"
            >
              <em class="text-xxxlarge">みまもり中</em>
              <br />
              <span class="text-large">です</span>
            </h2>
            <p class="mimamori-target__status-date">
              <time
                v-formatDateTimeJanpanese="homeData?.currentStatusDate"
              ></time>
            </p>
          </template>
          <!-- alert -->
          <template v-if="homeData?.currentStatus === 4">
            <h2
              class="mimamori-target__status-mark mimamori-target__status-mark_status-alert"
            >
              <em class="text-xxxlarge">アラート</em><br /><span
                class="text-large"
                >が発報されました</span
              >
            </h2>
            <p class="mimamori-target__status-date">
              <time
                v-formatDateTimeJanpanese="homeData?.currentStatusDate"
              ></time>
            </p>
          </template>
          <!-- pause -->
          <!-- current_status === 5 -->
          <template v-if="homeData?.currentStatus === 5">
            <div>
              <h2
                class="mimamori-target__status-mark mimamori-target__status-mark_status-fuzai"
              >
                <span class="text-large">不在期間</span>
              </h2>
              <p class="mimamori-target__status-date">
                <time
                  v-formatDateTimeJanpanese="
                    homeData?.servicePauseCalendar?.pauseStartDate
                  "
                ></time>
                <span class="mimamori-target__interfix">～</span><br />
                <time
                  v-if="homeData?.servicePauseCalendar?.pauseEndDate"
                  v-formatDateTimeJanpanese="
                    homeData?.servicePauseCalendar?.pauseEndDate
                  "
                ></time>
              </p>
            </div>
          </template>
        </div>

        <p class="mimamori-target__admin">
          管理：{{ homeData?.mimamoriInfo?.estateName }}
        </p>
      </section>
      <section class="mimamori-record-group mimamori-dashboard__status-history">
        <h1 class="mimamori-record-group__header">正常・異常判定結果</h1>
        <p class="mimamori-record-group__remark">
          （正常・異常判定は、記載の日付の電気使用量に対する結果を表示しています。）
        </p>
        <table
          v-if="homeData?.analysisResultList?.length > 0"
          class="mimamori-record-group__main table-container"
        >
          <tbody class="table-row-group">
            <TableRowComponent
              class="row-analysis"
              v-for="(analysis, index) of homeData?.analysisResultList"
              :class="{ 'highlight-row': highlightAnalysis(analysis) }"
              :key="index"
              @click="onDetailMimamori(analysis)"
            >
              <TableCellComponent>
                <p
                  class="message-content"
                  v-formatDateTimeJanpanese="analysis?.analysisDate"
                ></p>
              </TableCellComponent>
              <TableCellComponent>
                <AnalysisStatusComponent
                  :pauseStart="!!analysis.pauseStart"
                  :analysisResultCode="analysis.analysisResultCode"
                  :confirmStatus="analysis.confirmStatus"
                  :alertFlag="analysis.alertFlag"
                />
              </TableCellComponent>
              <TableCellComponent>
                <p
                  class="message-content"
                  :class="{ 'highlight-text': highlightAnalysis(analysis) }"
                  v-if="analysis.alertFlag"
                >
                  アラートが発報されました
                </p>
              </TableCellComponent>
              <TableCellComponent>
                <AnalysisLabelComponent
                  v-if="!analysis.pauseStart"
                  :analysisResultCode="analysis.analysisResultCode"
                  :confirmStatus="analysis.confirmStatus"
                  :alertFlag="analysis.alertFlag"
                />
              </TableCellComponent>
            </TableRowComponent>
          </tbody>
        </table>
        <table
          v-if="homeData?.analysisResultList?.length > 0"
          class="mimamori-record-group__main-mobile table-container"
        >
          <tbody class="table-row-group">
            <TableRowComponent
              v-for="(analysis, index) of homeData?.analysisResultList"
              class="row-analysis"
              :class="{ 'highlight-row': highlightAnalysis(analysis) }"
              :key="index"
              @click="onDetailMimamori(analysis)"
            >
              <TableCellComponent>
                <div class="d-flex flex-column justify-center color-content">
                  <p
                    class="message-content"
                    :class="{ 'mb-5': analysis.alertFlag }"
                    v-formatDateTimeJanpanese="analysis?.analysisDate"
                  ></p>
                  <p
                    class="message-content"
                    :class="{ 'highlight-text': highlightAnalysis(analysis) }"
                    v-if="analysis.alertFlag"
                  >
                    アラートが発報されました
                  </p>
                </div>
              </TableCellComponent>
              <TableCellComponent>
                <div
                  class="d-flex flex-column justify-center align-center content-status"
                >
                  <AnalysisStatusComponent
                    class="mb-2"
                    :pauseStart="!!analysis.pauseStart"
                    :analysisResultCode="analysis.analysisResultCode"
                    :confirmStatus="analysis.confirmStatus"
                    :alertFlag="analysis.alertFlag"
                  />
                  <AnalysisLabelComponent
                    v-if="!analysis.pauseStart"
                    :analysisResultCode="analysis.analysisResultCode"
                    :confirmStatus="analysis.confirmStatus"
                    :alertFlag="analysis.alertFlag"
                  />
                </div>
              </TableCellComponent>
            </TableRowComponent>
          </tbody>
        </table>
        <div v-else class="mimamori-record-group__no-data">
          <h3 v-if="this.isBeforeServiceStart()">
            現在、サービス準備中のため
            <br />
            正常・異常判定結果はありません。
          </h3>
        </div>
        <div class="mimamori-record-group__footer">
          <ButtonComponent
            width="210px"
            height="30px"
            :disabled="!(homeData?.analysisResultList?.length > 0)"
            @click="navigatePath('MimamoriHistoryComponent')"
            >正常・異常判定結果一覧</ButtonComponent
          >
        </div>
      </section>
    </CardComponent>

    <div class="main-footer">
      <section class="announcement">
        <h1 class="announcement__header">お知らせ</h1>
        <table class="announcement__main table-container">
          <GroupContentComponent
            class="content-notification"
            v-for="(
              notification, index
            ) of homeData.notificationInformationList"
            :key="index"
          >
            <template v-slot:title>
              <span
                v-formatDateTimeJanpanese="notification.notificationDisplayDate"
              ></span>
            </template>
            <template v-slot:content>
              <span class="notificationDetail">{{
                notification.notificationDetail
              }}</span>
            </template>
          </GroupContentComponent>
        </table>
      </section>
      <aside class="inquiry-banner">
        <section class="banner-content">
          <div class="banner-content_caption">
            <h1 class="title">ご利用方法</h1>
            <p class="note">サービスの概要についてご案内いたします。</p>
          </div>
          <div class="banner-content_btn">
            <a href="/help" target="_blank">
              <img
                :src="require('@/assets/images/home-button-right.png')"
                width="55"
                height="55"
                class="right-button"
              />
            </a>
          </div>
        </section>
      </aside>
      <aside class="inquiry-banner">
        <section class="banner-content">
          <div class="banner-content_caption">
            <h1 class="title">お問い合わせ</h1>
            <p class="note">
              サービスに関するご相談・お問い合わせは、お電話にてご連絡ください。
            </p>
          </div>
          <div class="banner-content_detail">
            <!-- For Estate Inquiries -->
            <div class="banner-content_detail-inner">
              <p class="contact-information">
                （サービスに関するお問い合わせ）
              </p>
              <p class="contact-information">
                <span class="information-item">■お問い合わせ先：</span>
                <span class="information-item">
                  {{ estateInfo?.inquiryName || "ー" }}
                </span>
              </p>
              <p class="contact-information">
                <span class="information-item">電話番号：</span>
                <span class="information-item">
                  <span
                    v-if="
                      estateInfo?.inquiryPhoneNumber1 &&
                      estateInfo?.inquiryPhoneNumber2 &&
                      estateInfo?.inquiryPhoneNumber3
                    "
                    class="phone-number"
                  >
                    {{
                      estateInfo?.inquiryPhoneNumber1 +
                      "-" +
                      estateInfo?.inquiryPhoneNumber2 +
                      "-" +
                      estateInfo?.inquiryPhoneNumber3
                    }}
                  </span>
                  <span class="phone-number" v-else>ー</span>
                </span>
              </p>
              <p class="contact-information">
                <span class="information-item">受付時間：</span>
                <span class="information-item">
                  {{ estateInfo?.inquiryReceptionTime || "ー" }}
                </span>
              </p>
              <p v-if="estateInfo?.inquiryNotices" class="contact-information">
                <span class="information-item">
                  {{ estateInfo?.inquiryNotices }}
                </span>
              </p>
            </div>
            <!-- For Admin Inquiries -->
            <div class="banner-content_detail-inner">
              <p class="contact-information">
                （システムに関するお問い合わせ）
              </p>
              <p class="contact-information">
                <span class="information-item">■お問い合わせ先：</span>
                <span class="information-item">
                  {{ adminInfo?.inquiryName || "ー" }}
                </span>
              </p>
              <p class="contact-information">
                <span class="information-item">電話番号：</span>
                <span class="information-item">
                  <span
                    v-if="
                      adminInfo?.inquiryPhoneNumber1 &&
                      adminInfo?.inquiryPhoneNumber2 &&
                      adminInfo?.inquiryPhoneNumber3
                    "
                    class="phone-number"
                  >
                    {{
                      adminInfo?.inquiryPhoneNumber1 +
                      "-" +
                      adminInfo?.inquiryPhoneNumber2 +
                      "-" +
                      adminInfo?.inquiryPhoneNumber3
                    }}
                  </span>
                  <span class="phone-number" v-else>ー</span>
                </span>
              </p>
              <p class="contact-information">
                <span class="information-item">受付時間：</span>
                <span class="information-item">
                  {{ adminInfo?.inquiryReceptionTime || "ー" }}
                </span>
              </p>
              <p v-if="adminInfo?.inquiryNotices" class="contact-information">
                <span class="information-item">
                  {{ adminInfo?.inquiryNotices }}
                </span>
              </p>
            </div>
          </div>
        </section>
      </aside>
    </div>
  </div>
</template>

<script>
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import { mapState } from "vuex";
import { ANALYSIS_RESULT_CODE, CONFIRM_STATUS, STATUS } from "@/globals/enums";
import HomeService from "@/services/home.service";
export default {
  name: "HomeComponent",
  data: () => ({
    confirmDialogService: new ConfirmDialogService(),
    homeService: new HomeService(),
  }),
  computed: {
    ...mapState({
      homeData: (state) => state?.global?.globalData,
      adminInfo: (state) => state?.global?.globalData.inquiryAdminInformation,
      estateInfo: (state) => state?.global?.globalData.inquiryEstateInformation,
    }),
  },
  mounted() {
    this.initStateHome();
  },
  methods: {
    navigatePath(name, params = {}, query = {}) {
      this.$router.push({ name, params: params, query: query });
    },

    openConfirmEmail() {
      this.confirmDialogService.openConfirmEmailDialog("", "", () => {
        this.$router.push({ name: "AlertPreviewComponent" });
      });
    },
    openConfirmSafety() {
      this.confirmDialogService.openConfirmSafetyDialog(
        "",
        this.homeData.currentStatusDate,
        () => {
          this.navigatePath(
            "EditMimamoriComponent",
            { analysisResultId: this.homeData.alertInfo.analysisResultId },
            { mimamoriTargetPersonId: this.homeData.mimamoriTargetPersonId }
          );
        }
      );
    },
    highlightAnalysis(analysis) {
      return (
        analysis.analysisResultCode === ANALYSIS_RESULT_CODE.ABNORMAL.VALUE &&
        (analysis.confirmStatus === CONFIRM_STATUS.UNCONFIRMED.VALUE ||
          analysis.confirmStatus === CONFIRM_STATUS.CHECKING.VALUE) &&
        analysis.alertFlag
      );
    },

    onDetailMimamori(analysis) {
      if (analysis.pauseStart) {
        return;
      }
      this.navigatePath(
        "EditMimamoriComponent",
        { analysisResultId: analysis.analysisResultId },
        {
          mimamoriTargetPersonId: this.homeData.mimamoriTargetPersonId,
        }
      );
    },

    initStateHome() {
      this.$store.dispatch("global/updateLoadingStatus", true);
      this.homeService.getHome().then((homeData) => {
        this.$store.dispatch("global/updateLoadingStatus", false);
        if (homeData.status !== STATUS.SUCCESS) {
          return;
        }
        if (
          this.homeData?.mimamoriInfo?.serviceStatus ||
          this.homeData?.currentStatus
        ) {
          const serviceStatus = this.homeData?.mimamoriInfo?.serviceStatus;
          const currentStatus = this.homeData?.currentStatus;
          if (serviceStatus === 4 || serviceStatus === 3) {
            this.openConfirmEmail();
          }
          if (currentStatus === 4) {
            this.openConfirmSafety();
          }
        }
      });
    },

    /**
     * Returns True if the same conditions are met as when displaying message "サービス準備中".
     * (#65372: The message "現在、サービス準備中のため..." should only be displayed when the service is not started.)
     */
    isBeforeServiceStart() {
      let isBeforeStart = false;

      switch (this.homeData?.mimamoriInfo?.serviceStatus) {
        case 3: // 親族等登録手続き完了中
        case 4: // 親族等登録手続き完了
        case 5: // サービス準備中
          isBeforeStart = true;
          break;
        case 6: // サービス利用中
          {
            if (!this.homeData?.currentStatus) {
              // The current_status value of IF-HOME-001 is null
              isBeforeStart = true;
            }
          }
          break;
        default:
          break;
      }
      return isBeforeStart;
    },
  },
};
</script>

<style lang="scss" scoped>
.HomeComponent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mimamori-dashboard {
  display: flex;
  margin: 30px auto;

  // Elements
  &__status-current {
    padding: 0 30px;
    width: 360px;

    border-right: 1px solid var(--border-color);
  }
  &__status-history {
    padding: 0 40px;
    flex: 1;
  }
}

.mimamori-target {
  text-align: center;

  // Elements
  &__title {
    margin: 30px auto;
  }
  &__name-label {
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-regular);
    color: var(--color-label);
  }
  &__name-full {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
  }
  &__name-postfix {
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-medium);
    padding-left: 10px;
  }
  &__status-mark {
    padding-top: 120px;
    background: no-repeat center top;

    margin: 40px auto;

    // Modifiers
    &_status-alert {
      background-image: url(@/assets/images/qms/ma_alert.svg);
      color: var(--alert-text-color);
    }
    &_status-fuzai {
      background-image: url(@/assets/images/qms/ma_fuzai.svg);
    }
    &_status-seijyo {
      background-image: url(@/assets/images/qms/ma_seijyo.svg);
    }
    &_status-contract-error {
      background-image: url(@/assets/images/qms/ma_contract_error.svg);
      color: var(--alert-text-color);
      padding: 136px 0 50px;
    }
  }
  &__status-date {
    margin: 40px auto;
    color: var(--color-label);
    text-align: initial;
    width: max-content;
  }
  &__admin {
    margin: 30px auto;
    color: var(--color-label);
  }
}

.mimamori-record-group {
  // Elements
  &__header {
    padding-left: 30px;
    background: url(@/assets/images/qms/t_ico_seijyo.svg) no-repeat left 6px;

    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);

    margin: 30px auto 0;
    padding-bottom: 0.3em;
  }
  &__remark {
    color: var(--color-label);
    font-size: var(--font-size-small);
    position: relative;
    padding-bottom: 0.5em;
    border-bottom: 2px solid var(--border-color);
  }
  &__main {
    display: table;
    width: 100%;
    &-mobile {
      display: none;
    }
    .row-analysis {
      cursor: pointer;
      &:hover {
        background: var(--soft-bgcolor);
      }
    }
    .highlight-row {
      background: #fef7f6 !important;
      &:hover {
        background: var(--alert-bgcolor-2) !important;
      }
    }

    .TableCellComponent {
      padding: 13px 5px;
      .highlight-text {
        color: #c51f1f !important;
      }
    }
    .message-content {
      color: #7c8185;
      font-size: var(--font-size-default);
    }
  }
  &__no-data {
    height: 300px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__footer {
    text-align: right;

    margin: 30px auto;
  }
}

.main-footer {
  background: var(--main-footer-bgcolor);
  width: 100%;
  flex: 1;
}

.announcement {
  width: 860px; // NOTE: To be responsive
  margin: 30px auto 40px;

  // Elements
  &__header {
    padding-left: 30px;
    background: url(@/assets/images/qms/t_ico_news.svg) no-repeat left center;

    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);

    margin: 0 auto 10px;
  }
  &__main {
    display: table;
    width: 100%;

    border-top: 2px solid var(--border-color);

    // Nested Override
    .content-notification {
      padding: 20px;
      border-bottom: 1px dotted var(--border-dark-color);
    }
  }
}

.notificationDetail {
  white-space: pre-wrap;
}

.inquiry-banner {
  width: 860px; // NOTE: To be responsive
  margin: 30px auto 40px;
  padding: 20px 40px;
  background-color: #f3f6f7;

  .banner-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .banner-content_caption {
    flex: 1;
    .title {
      float: left;

      font-size: var(--font-size-large);
      border-bottom: 4px solid #d0d9df;
      width: fit-content;
    }
    .note {
      padding-top: 13px;
      padding-left: 165px;

      color: #7c8185;
    }
  }
  .banner-content_btn {
    flex: initial;
    padding-left: 15px;
    .right-button {
      vertical-align: bottom;
    }
  }
  .banner-content_detail {
    flex: initial;
    display: flex;
    width: 100%;
    font-weight: var(--font-weight-medium);

    &-inner {
      flex: 1;
      margin-top: 20px;
    }
    .contact-information {
      color: #000000;
      font-weight: var(--font-weight-medium);
      margin-bottom: 5px;
      padding-right: 10px;
    }
    .information-item {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      white-space: pre-wrap;
      word-break: break-all;
    }
    .phone-number {
      font-size: var(--font-size-large);
      font-weight: var(--font-weight-bold);
      color: #000000;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) and (min-width: $mobile-breakpoint) {
  .HomeComponent {
    .v-card {
      width: unset !important;
    }
  }
  .mimamori-dashboard {
    &__status-current {
      padding: 0 20px;
      width: 300px;
    }
    &__status-history {
      padding: 0 5px;
    }
  }
  .main-footer {
    padding: 0 30px;

    .announcement,
    .inquiry-banner {
      width: auto;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .HomeComponent {
    display: flow-root;
    background: white;
    .mimamori-target {
      &__status-mark {
        &_status-contract-error {
          padding-bottom: 0;
          margin-bottom: 52px;
        }
      }
    }
  }
  .mimamori-dashboard {
    display: unset !important;
    &__status {
      &-current {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #d0d9df;
      }
    }
    &__status-history {
      padding: 0 20px;
    }
  }
  .mimamori-record-group {
    &__main {
      display: none;
      &-mobile {
        display: table;
        width: 100%;
        .TableCellComponent {
          padding: 13px 5px;
          vertical-align: middle;
          .color-content {
            color: #7c8185;
          }

          .content-status {
            width: max-content;
          }
        }
      }
    }
    &__no-data {
      height: 120px;
    }
    &__footer {
      text-align: center;
    }
  }
  .main-footer {
    padding: 30px 20px 40px;

    .announcement {
      width: auto;
      margin: 0 auto 40px;
    }
    .inquiry-banner {
      width: auto;
      margin: 30px auto 0;
      padding: 20px;
      .banner-content_caption {
        .title {
          margin-bottom: 10px;
        }
        .note {
          clear: left;
          padding: 0;
        }
      }
      .banner-content_detail {
        display: block;

        &-inner {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
