<template>
  <div class="TelephoneInputComponent" v-if="!isSelect">
    <div
      class="TelephoneInputComponent_item"
      v-for="(item, index) in groupInput"
      :key="index"
    >
      <InputComponent
        :required="index === 0 && required"
        :name="item.name"
        :type="item.type"
        :value="item.value"
        :pattern="item.pattern"
        :maxlength="item.maxlength"
        inputFilter="number"
        @trigle-change="validate"
      />
      <p v-if="index !== groupInput.length - 1">ー</p>
    </div>
  </div>
  <div class="TelephoneInputComponent" v-else>
    <div
      class="TelephoneInputComponent_item small"
      v-for="(item, index) in groupInput"
      :key="index"
    >
      <InputComponent
        v-if="index !== 0"
        :name="item.name"
        :type="item.type"
        :value="item.value"
        :pattern="item.pattern"
        :maxlength="item.maxlength"
        inputFilter="number"
        :errorSecondInputClass="index === 1"
        @trigle-change="validate"
      />
      <SelectComponent
        v-else
        :name="item.name"
        :value="item.value"
        :options="options"
        :isSelectDateRange="true"
        :initialValue="defaultFirst3Digits"
        errorMessageClass="firstTelephone"
        @trigle-change="validate"
      />
      <p class="dash" v-if="index !== groupInput.length - 1">ー</p>
    </div>
  </div>
</template>

<script>
import SelectComponent from "./SelectComponent.vue";
export default {
  components: { SelectComponent },
  name: "TelephoneInputComponent",
  props: {
    groupInput: {
      type: Array,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    defaultFirst3Digits: {
      type: String,
      default: "",
    },
    validateField: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    validate() {
      this.groupInput.forEach((item) => {
        this.validateField(item?.name);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.TelephoneInputComponent {
  display: flex;
  gap: 8px;
  align-items: center;
  &_item {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .small {
    .InputComponent-telephone {
      width: 60px;
    }
  }
  .dash {
    color: #7c8185;
  }
}
</style>
