import {
  createPeriodAbsence,
  getPeriodAbsence,
  updatePeriodAbsence,
} from "./api/absence.service";
import { STATUS } from "@/globals/enums";
import {
  convertAbsencePreviod,
  convertBodyPeriod,
} from "@/store/converter/absence.convert";

export default class AbsenceService {
  async getPeriodAbsence(userId) {
    try {
      const result = await getPeriodAbsence(userId);
      return {
        status: STATUS.SUCCESS,
        data: convertAbsencePreviod(result),
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        response: {
          ...error.response,
          ...(error?.response?.code === "CUSTOM-USER_NOT_FOUND" && {
            showModal: true,
            title: "エラー",
            detail:
              "みまもり対象者の情報が見つかりません。\n暫くたってから再度お試しください。",
          }),
        },
      };
    }
  }

  async createPeriodAbsence(id, body) {
    try {
      await createPeriodAbsence(id, convertBodyPeriod(body));
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        response: {
          ...error.response,
          ...(error?.response?.code === "CUSTOM-INVALID_REQUEST_ERROR" && {
            detail:
              "不在期間の設定に誤りがあります。不在期間の設定を変更してください。",
          }),
          ...(error?.response?.code === "CUSTOM-USER_NOT_FOUND" && {
            detail: "不在期間を設定する対象者が見つかりませんでした。",
          }),
          ...(error?.response?.code === "CUSTOM-DUPLICATE_PERIOD" && {
            detail: "不在期間の設定が重複しています。",
          }),
        },
      };
    }
  }

  async updatePeriodAbsence(id, body) {
    try {
      const ids = {
        mimamori_target_person_id: id.mimamoriTargetPersonId,
        calendar_id: id.calenderId,
      };
      await updatePeriodAbsence(ids, convertBodyPeriod(body));
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        response: {
          ...error.response,
          ...(error?.response?.code === "CUSTOM-INVALID_REQUEST_ERROR" && {
            detail:
              "不在期間の設定に誤りがあります。不在期間の設定を変更してください。",
          }),
          ...(error?.response?.code === "CUSTOM-USER_NOT_FOUND" && {
            detail: "不在期間を設定する対象者が見つかりませんでした。",
          }),
          ...(error?.response?.code === "CUSTOM-DUPLICATE_PERIOD" && {
            detail: "不在期間の設定が重複しています。",
          }),
        },
      };
    }
  }
}
