<template>
  <div class="loader" ref="loader" tabindex="0">
    <div
      v-if="loading"
      ref="loaderBackdrop"
      class="loader_backdrop"
      :class="{
        'loader_backdrop-center': center,
        'loader_backdrop-fixed': absolute,
      }"
    >
      <div class="loading_center">
        <span class="loading_circle loading_circle-one"></span>
        <span class="loading_circle loading_circle-two"></span>
        <span class="loading_circle loading_circle-three"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    absolute: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    document.body.style.height = "100%";
    document.documentElement.style.overflow = "hidden";

    /**
     * Remove focus from the UI (button)
     * that triggered the loading to be displayed
     * and prevent a series of button presses by the keyboard.
     *
     * NOTE:
     * To prevent screen scrolling from occurring, focus on the .loader_backdrop element if it exists.
     * If the .loader_backdrop element does not exist, focus on the parent .loader element instead.
     */
    if (this.loading) {
      this.$refs.loaderBackdrop.focus();
    } else {
      // Unavoidable scrolling may occur.
      this.$refs.loader.focus();
    }
  },
  unmounted() {
    document.body.style.height = "100%";
    document.documentElement.style.overflow = "auto";
  },
};
</script>

<style lang="scss" scoped>
.loader {
  &_backdrop {
    width: 100%;
    height: 100vh;
    position: fixed;
    // top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff94;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 50px 0 0 130px;
    &-fixed {
      position: fixed;
    }
    &-center {
      padding: 0;
    }
    .loading {
      top: 0;
      left: 0;
      background-color: #fff;
      width: 100%;
      visibility: visible;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      z-index: 1;
      border-radius: 10px;
      box-shadow: 0 1px 6px 0 rgba(51, 51, 51, 0.1);
      &_center {
        height: 15px;
        width: 80px;
        display: flex;
        justify-content: space-around;
      }
      &_circle {
        display: block;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        animation-duration: 1.5s;
        animation-name: fades;
        animation-iteration-count: infinite;
        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
        &-one {
          background-color: #3f8ea7;
        }
        &-two {
          background-color: #97d4e7;
        }
        &-three {
          background-color: #d9eff6;
        }
      }
      @keyframes fades {
        0%,
        100% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
      }
    }
  }
  &-enter-active,
  &-leave-active {
    transition-duration: 200ms;
    transition-property: height, opacity;
    transition-timing-function: ease;
  }

  &-enter,
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
