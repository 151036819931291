<template>
  <div class="MimamoriTargetListComponent">
    <TitleComponent class="MimamoriTargetListComponent-title">
      <template v-slot:icon>
        <i class="icon icon-t_ico_seijyo"></i>
      </template>
      <template v-slot:content>みまもり対象者・親族等情報</template>
    </TitleComponent>
    <CardComponent class="filter-ui-card" :width="'960px'" :height="'auto'">
      <Form>
        <section class="mimamori-record-filter">
          <GroupContentComponent class="mimamori-record-filter__item">
            <template v-slot:title>みまもり対象者</template>
            <template v-slot:content>
              <InputComponent :small="true" placeholder="氏名の一部等を入力" />
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="mimamori-record-filter__item">
            <template v-slot:title>利用状況</template>
            <template v-slot:content>
              <div class="checkbox-group">
                <div class="checkbox-wrapper">
                  <InputComponent
                    class="checkbox-wrapper__checkbox"
                    type="checkbox"
                  />
                  申込手続き中
                </div>
                <div class="checkbox-wrapper">
                  <InputComponent
                    class="checkbox-wrapper__checkbox"
                    type="checkbox"
                  />
                  親族等登録手続き中
                </div>
                <div class="checkbox-wrapper">
                  <InputComponent
                    class="checkbox-wrapper__checkbox"
                    type="checkbox"
                  />
                  サービス利用中・準備中
                </div>
                <div class="checkbox-wrapper">
                  <InputComponent
                    class="checkbox-wrapper__checkbox"
                    type="checkbox"
                  />
                  サービス一時停止中
                </div>
                <div class="checkbox-wrapper">
                  <InputComponent
                    class="checkbox-wrapper__checkbox"
                    type="checkbox"
                  />
                  解約
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <ButtonComponent
            class="mimamori-record-filter__button"
            width="130px"
            height="30px"
            >絞り込み</ButtonComponent
          >
        </section>
      </Form>
    </CardComponent>

    <CardComponent class="record-table-card" width="960px" height="auto">
      <section class="mimamori-record-group">
        <h1 class="mimamori-record-group__header">みまもり対象者・親族等</h1>
        <div class="mimamori-record-group__count-item">
          <strong>1-10</strong> 件/ 99件
        </div>
        <table class="mimamori-record-group__main table-container">
          <tr>
            <TableHeaderComponent class="text-left"
              >登録年月日</TableHeaderComponent
            >
            <TableHeaderComponent class="text-left"
              >みまもり対象者</TableHeaderComponent
            >
            <TableHeaderComponent class="text-left"
              >建物名</TableHeaderComponent
            >
            <TableHeaderComponent class="text-left"
              >部屋番号</TableHeaderComponent
            >
            <TableHeaderComponent class="text-left"
              >利用状況</TableHeaderComponent
            >
            <TableHeaderComponent></TableHeaderComponent>
          </tr>
          <tbody class="table-row-group">
            <TableRowComponent class="mimamori-record" v-for="n in 10" :key="n">
              <TableCellComponent>2022年1月11日</TableCellComponent>
              <TableCellComponent>山田 太郎 様</TableCellComponent>
              <TableCellComponent>クレアガーデン中央</TableCellComponent>
              <TableCellComponent>401号室</TableCellComponent>
              <TableCellComponent>申込み手続き中</TableCellComponent>
              <TableCellComponent class="text-right">
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn flat class="menu-button" v-bind="props">
                      <i class="icon icon-ico_menu"></i>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list density="compact" class="menu-list">
                      <v-list-item
                        class="menu-list__item"
                        v-for="(item, index) in menuItems"
                        :key="index"
                        :value="item"
                      >
                        <v-list-item-title
                          v-text="item.title"
                          class="menu-list__title"
                        ></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </TableCellComponent>
            </TableRowComponent>
          </tbody>
        </table>
        <div class="mimamori-record-group__footer">
          <div class="pagination-wrapper">
            <PaginationComponent
              class="pagination"
              :total-pages="pagination.totalPage"
              :current-page="pagination.page"
              v-if="pagination.totalPage > 1"
            />
          </div>
          <div class="button-wrapper">
            <ButtonComponent class="button" width="150px" height="30px"
              >新規登録</ButtonComponent
            >
          </div>
        </div>
      </section>
    </CardComponent>
  </div>
</template>

<script>
export default {
  name: "MimamoriTargetListComponent",
  data: () => ({
    pagination: { page: 1, number: 10, totalPage: 100 },
    menuItems: [
      { title: "詳細" },
      { title: "みまもり対象者編集" },
      { title: "親族等編集" },
      { title: "一時停止" },
      { title: "解約" },
    ],
  }),
  methods: {
    goHomePage() {
      this.$router.push({ name: "HomeComponent" });
    },
  },
};
</script>

<style scoped lang="scss">
.MimamoriTargetListComponent {
  display: flex;
  margin: 30px auto;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-title {
    display: flex;
    width: 960px;
    font-size: 20px;
    font-weight: 500;

    // TODO: Refactoring
    .icon {
      line-height: 1.5;
      margin-right: 0.25em;
      vertical-align: middle;
    }
  }
}

.filter-ui-card {
  padding: 0 40px;
  margin-bottom: 30px;
}

.record-table-card {
  padding: 0 40px;
  margin-bottom: 40px;
}

.mimamori-record-filter {
  margin: 30px auto;
  border-top: 1px solid var(--border-color);

  // Element (Nested Override)
  &__item.GroupContentComponent {
    // grid-template-columns: 150px 1fr;
  }
  // Elements
  &__button {
    margin-top: 30px;
    text-align: right;
  }
}

.checkbox-wrapper {
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
  line-height: 2;
  margin-right: 60px;

  // Elements
  &__checkbox {
    margin-right: 0.5em;
  }
}

.mimamori-record-group {
  margin: 30px auto;

  &__header {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    float: left;
  }
  &__count-item {
    text-align: right;
  }
  &__main {
    display: table;
    width: 100%;
  }
  &__footer {
    margin: 30px auto; // TODO: refactoring

    display: grid;
    grid-gap: 0;
    grid-template-columns: 220px 1fr 220px;
    grid-template-areas: "... center right";
    align-items: center;

    // Nested Override
    .pagination-wrapper {
      grid-area: center;
      text-align: center;
    }
    .button-wrapper {
      grid-area: right;
      text-align: right;
    }
  }
}

// TODO: Refactoring
.menu-button {
  min-width: 0;
  .icon {
    transform: rotate(-90deg);
  }
}

// TODO: Refactoring
.menu-list {
  &__item {
  }
  &__title {
    color: #294f6e;
    font-size: var(--font-size-small);
  }
}
</style>
