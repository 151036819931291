import {
  ALL_PREFECTURES,
  PROFILE_STEPS,
  ALERT_DATES_OPTIONS,
  PHONE_FIRST_3_DIGITS,
} from "@/globals/enums";
import { formatTelNumber } from "@/utils/utils";
import { parsePhoneNumber } from "@/plugins/parsePhoneNumber";

export const convertProfileCreateSteps = () => {
  return PROFILE_STEPS.map((type) => ({
    value: type.VALUE,
    label: type.LABEL,
  }));
};

export const convertProvidesOptions = () => {
  const findingList = ALL_PREFECTURES;
  return findingList.map((type) => ({
    value: type.VALUE,
    label: type.LABEL,
  }));
};

export const convertAlertDateOptions = () => {
  return ALERT_DATES_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.LABEL,
  }));
};

export const convertTelephone3DigitsOptions = () => {
  return PHONE_FIRST_3_DIGITS.map((type) => ({
    value: type.VALUE,
    label: type.LABEL,
  }));
};

export const convertProfile = (dataProfile) => {
  return {
    firstNameKanji: dataProfile.first_name_kanji || "",
    lastNameKanji: dataProfile.last_name_kanji || "",
    firstNameKana: dataProfile.first_name_kana || "",
    lastNameKana: dataProfile.last_name_kana || "",
    mimamoriTargetPersonId: dataProfile.mimamori_target_person_id,
    moveInDate: dataProfile.move_in_date,
    moveOutDate: dataProfile.move_out_date,
    deathDate: dataProfile.death_date,
    supplyPointIdNumber: dataProfile.supply_point_id_number,
    sex: dataProfile.sex,
    birthday: dataProfile.birthday,
    zipCode1: dataProfile.zip_code1,
    zipCode2: dataProfile.zip_code2,
    zipCode: dataProfile.zip_code1 + "-" + dataProfile.zip_code2,
    addressPrefecture: dataProfile.address_prefecture,
    addressCity: dataProfile.address_city,
    addressStreetNumber: dataProfile.address_street_number,
    addressBuildingName: dataProfile.address_building_name,
    addressRoomNumber: dataProfile.address_room_number,
    address:
      dataProfile.address_prefecture +
      dataProfile.address_city +
      dataProfile.address_street_number +
      dataProfile.address_building_name +
      dataProfile.address_room_number,
    phoneNumber1: dataProfile.phone_number1,
    phoneNumber2: dataProfile.phone_number2,
    phoneNumber3: dataProfile.phone_number3,
    phoneNumber:
      dataProfile.phone_number1 +
      "-" +
      dataProfile.phone_number2 +
      "-" +
      dataProfile.phone_number3,
    parsedPhoneNumber:
      dataProfile.phone_number1 +
      dataProfile.phone_number2 +
      dataProfile.phone_number3,
    ecocuteUseFlg: dataProfile.ecocute_use_flg,
    ecocuteUseTimeFrom: dataProfile.ecocute_use_time_from,
    ecocuteUseTimeTo: dataProfile.ecocute_use_time_to,
    contractPlanCode: dataProfile.contract_plan_code,
    standbyPower: dataProfile.standby_power,
    estateId: dataProfile.estate_id,
    estateName: dataProfile.estate_name,
    notices: dataProfile.notices || "ー",
    alertConditions: dataProfile.alert_conditions,
    contractDate: dataProfile.contract_date,
    serviceStartDate: dataProfile.service_start_date,
    serviceStatusUpdate: dataProfile.service_status_update,
    serviceStatus: dataProfile.service_status,
    mimamoriStatusUpdate: dataProfile.mimamori_status_update,
    mimamoriStatus: dataProfile.mimamori_status,
    alertDayCount: dataProfile.alert_day_count,
    normalDayCount: dataProfile.normal_day_count,
    discountAmount: dataProfile.discount_amount,
    autocallActiveFlg: dataProfile.autocall_active_flg,
    autocallStatus: dataProfile.autocall_active_flg
      ? "有効性確認済"
      : "有効性未確認",
    autocallStatusClass: dataProfile.autocall_active_flg ? "blue" : "orange",
    workFlg: dataProfile.work_flg,
    dataDeleteDate: dataProfile.data_delete_date,
    calendarId: dataProfile.calendar_id,
    pauseStartDate: dataProfile.pause_start_date,
    pauseEndDate: dataProfile.pause_end_date,
    reasonType: dataProfile.reason_type,
    createPerson: dataProfile.create_person,
    pauseStart: !!dataProfile.pause_start_date,
  };
};

export const convertRelative = (dataProfile) => {
  return {
    firstNameKanji: dataProfile.first_name_kanji || "ー",
    lastNameKanji: dataProfile.last_name_kanji || "ー",
    firstNameKana: dataProfile.first_name_kana || "ー",
    lastNameKana: dataProfile.last_name_kana || "ー",
    zipCode1: dataProfile.zip_code1,
    zipCode2: dataProfile.zip_code2,
    zipCode: dataProfile.zip_code1 + "-" + dataProfile.zip_code2,
    addressPrefecture: dataProfile.address_prefecture,
    addressCity: dataProfile.address_city,
    address: dataProfile.address_prefecture + "\n" + dataProfile.address_city,
    phoneNumber1: dataProfile.phone_number1,
    phoneNumber2: dataProfile.phone_number2,
    phoneNumber3: dataProfile.phone_number3,
    phoneNumber:
      dataProfile.phone_number1 +
      "-" +
      dataProfile.phone_number2 +
      "-" +
      dataProfile.phone_number3,
    parsedPhoneNumber:
      dataProfile.phone_number1 +
      dataProfile.phone_number2 +
      dataProfile.phone_number3,
    phoneData: {
      phoneNumberGroup: [
        {
          name: "firstPathNumbers",
          value: dataProfile?.phone_number1 || "",
          type: "telephoneGroup",
          pattern: "[0-9]+",
          maxlength: "3",
        },
        {
          name: "secondPathNumbers",
          value: dataProfile?.phone_number2 || "",
          type: "telephoneGroup",
          pattern: "[0-9]+",
          maxlength: "4",
        },
        {
          name: "thirtPathNumbers",
          value: dataProfile?.phone_number3 || "",
          type: "telephoneGroup",
          pattern: "[0-9]+",
          maxlength: "4",
        },
      ],
      firstPhoneNumber: dataProfile.phone_number1 || "",
      telephone3Digits: convertTelephone3DigitsOptions(),
    },
    relationship: dataProfile.relationship,
    notices: dataProfile.notices || "ー",
    prefectureOptions: convertProvidesOptions(),
  };
};

export const convertAutoCallTelephone = (data) => {
  const convertedTelephone = formatTelNumber(data.phoneNumber);
  if (!convertedTelephone) {
    return {
      notices: data.notices,
    };
  }
  return {
    phone_number1: convertedTelephone[0],
    phone_number2: convertedTelephone[1],
    phone_number3: convertedTelephone[2],
    notices: data.notices,
  };
};

export const convertRelativeEdit = (data) => {
  return {
    last_name_kanji: data.lastNameKanji,
    first_name_kanji: data.firstNameKanji,
    last_name_kana: data.lastNameKana,
    first_name_kana: data.firstNameKana,
    zip_code1: data.zipCode.substring(0, 3),
    zip_code2: data.zipCode.substring(3, 7),
    address_prefecture: data.addressPrefecture,
    address_city: data.addressCity,
    relationship: data.relationship,
    notices: data.notices,
    phone_number1: data.firstPathNumbers,
    phone_number2: data.secondPathNumbers,
    phone_number3: data.thirtPathNumbers,
  };
};

export const convertFirstProfileCreate = () => {
  return {
    alertDateOptions: convertAlertDateOptions(),
    prefectureOptions: convertProvidesOptions(),
    telephone3Digits: convertTelephone3DigitsOptions(),
    phoneNumberGroup: parsePhoneNumber(""),
  };
};

export const convertCreateProfileInfo = (data) => {
  const result = {
    last_name_kanji: data.lastNameKanji,
    first_name_kanji: data.firstNameKanji,
    last_name_kana: data.lastNameKana,
    first_name_kana: data.firstNameKana,
    zip_code1: data.zipCode.substring(0, 3),
    zip_code2: data.zipCode.substring(3, 7),
    address_prefecture: data.addressPrefecture,
    address_city: data.addressCity,
    address_street_number: "",
    relationship: data.relationship,
    notices: data.notices,
    alert_conditions: data.alertDate,
    create_email_list: [
      {
        display_order: 1,
        email: data.mailAddress1,
      },
    ],
    create_phone_number_list: [
      {
        display_order: 1,
        phone_number1: data.firstPathNumbers,
        phone_number2: data.secondPathNumbers,
        phone_number3: data.thirtPathNumbers,
      },
    ],
  };
  const rawEmailList = [
    data.mailAddress1,
    data.mailAddress2,
    data.mailAddress3,
  ];
  const emailResult = [];
  rawEmailList.forEach((element, index) => {
    if (element?.length > 0) {
      emailResult.push({
        email: element,
        display_order: index + 1,
      });
    }
  });
  return {
    ...result,
    create_email_list: emailResult,
  };
};

export const convertRelativeInfo = (data) => {
  return {
    phoneData: {
      phoneNumberGroup: [
        {
          name: "firstPathNumbers",
          value: data?.phone_number1 || "",
          type: "telephoneGroup",
          pattern: "[0-9]+",
          maxlength: "3",
        },
        {
          name: "secondPathNumbers",
          value: data?.phone_number2 || "",
          type: "telephoneGroup",
          pattern: "[0-9]+",
          maxlength: "4",
        },
        {
          name: "thirtPathNumbers",
          value: data?.phone_number3 || "",
          type: "telephoneGroup",
          pattern: "[0-9]+",
          maxlength: "4",
        },
      ],
      firstPhoneNumber: data.phone_number1 || "",
    },
    firstNameKana: data?.first_name_kana || "",
    firstNameKanji: data?.first_name_kanji || "",
    lastNameKana: data?.last_name_kana || "",
    lastNameKanji: data?.last_name_kanji || "",
    relationship: data?.relationship || "",
  };
};
