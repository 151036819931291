import showDialog from "@/services/dialog.service";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import ConfirmDialogWithEmphasis from "@/components/dialogs/ConfirmDialogWithEmphasis";
import ConfirmEmailDialog from "@/components/dialogs/ConfirmEmailDialog.vue";
import ConfirmSafetyDialog from "@/components/dialogs/ConfirmSafetyDialog.vue";
import ConfirmEditAnalysisDialog from "@/components/dialogs/ConfirmEditAnalysisDialog.vue";
export default class ConfirmDialogService {
  openConfirmDialog(title, content, callbackClose, className) {
    showDialog({
      component: ConfirmDialog,
      bind: {
        title,
        content,
        onClose: callbackClose,
        className,
      },
    });
  }

  openConfirmDialogWithEmphasis(
    title,
    content,
    emphasis,
    callbackClose,
    className
  ) {
    showDialog({
      component: ConfirmDialogWithEmphasis,
      bind: {
        title,
        content,
        emphasis,
        onClose: callbackClose,
        className,
      },
    });
  }

  openConfirmEmailDialog(title, content, callbackClose, className) {
    showDialog({
      component: ConfirmEmailDialog,
      bind: {
        title,
        content,
        onClose: callbackClose,
        className,
      },
    });
  }

  openConfirmSafetyDialog(title, content, callbackClose, className) {
    showDialog({
      component: ConfirmSafetyDialog,
      bind: {
        title,
        content,
        onClose: callbackClose,
        className,
      },
    });
  }

  openConfirmEditAnalysis(callbackClose, callbackOnChoise) {
    showDialog({
      component: ConfirmEditAnalysisDialog,
      bind: {
        onClose: callbackClose,
        onChoise: callbackOnChoise,
      },
    });
  }
}
