import { get, post, put } from "../https/http.service";

export const getPeriodAbsence = (id) => {
  return get(`mimamori/service/pause/period/${id}`);
};

export const createPeriodAbsence = (id, params) => {
  return post(`mimamori/service/pause/period/${id}`, params);
};

export const updatePeriodAbsence = (ids, params) => {
  return put(
    `mimamori/service/pause/period/${ids.mimamori_target_person_id}/${ids.calendar_id}`,
    params
  );
};
