import { createRouter, createWebHistory } from "vue-router";

import { WEB_STORAGE_KEY } from "@/globals/enums";
import WebStorageService from "@/services/web-storage.service";

import LoginComponent from "@/views/LoginComponent.vue";
import LoginMFAComponent from "@/views/LoginMFAComponent.vue";
import ReSettingSMSComponent from "@/views/ReSettingSMSComponent.vue";
import HomeComponent from "@/views/HomeComponent.vue";
import MimamoriTargetComponent from "@/views/MimamoriTargetComponent/MimamoriTargetComponent.vue";
import MimamoriTargetListComponent from "@/views/MimamoriTargetComponent/MimamoriTargetListComponent.vue";

import MimamoriComponent from "@/views/MimamoriComponent/MimamoriComponent.vue";
import MimamoriHistoryComponent from "@/views/MimamoriComponent/MimamoriHistoryComponent.vue";
import EditMimamoriComponent from "@/views/MimamoriComponent/EditMimamoriComponent.vue";

import RegistrationComponent from "@/views/RegistrationComponent.vue";
import ReSettingAuthAppComponent from "@/views/ReSettingAuthAppComponent.vue";
import StyleGuideComponent from "@/views/StyleGuideComponent.vue";
import ReConfigMFAComponent from "@/views/ReConfigMFAComponent.vue";
import ResetMFAComponent from "@/views/ResetMFAComponent.vue";
import ForgotPasswordComponent from "@/views/ForgotPasswordComponent.vue";
import ResetPasswordComponent from "@/views/ResetPasswordComponent.vue";
import ChangePasswordComponent from "@/views/ChangePasswordComponent.vue";
import SignUpComponent from "@/views/SignUpComponent";
import SignUpMFAComponent from "@/views/SignUpMFAComponent";

import AlertEditComponent from "@/views/AlertSettingComponent/AlertEditComponent.vue";
import AlertPreviewComponent from "@/views/AlertSettingComponent/AlertPreviewComponent.vue";
import AlertSettingComponent from "@/views/AlertSettingComponent/AlertSettingComponent.vue";
import ErrorComponent from "@/views/ErrorComponent.vue";

import ProfileComponent from "@/views/ProfileComponent/ProfileComponent.vue";
import ProfileDetailComponent from "@/views/ProfileComponent/ProfileDetailComponent.vue";
import AutoCallEditComponent from "@/views/ProfileComponent/AutoCallEditComponent.vue";
import RelativeEditComponent from "@/views/ProfileComponent/RelativeEditComponent.vue";

import ProfileCreateComponent from "@/views/ProfileCreateComponent/ProfileCreateComponent.vue";
import MailRegistrationComponent from "@/views/ProfileCreateComponent/MailRegistrationComponent.vue";
import ActivateEmailComponent from "@/views/AlertSettingComponent/ActivateEmailComponent";

import AbsenceRegistrationDetailComponent from "@/views/AbsenceRegistrationComponent/AbsenceRegistrationDetailComponent.vue";

import UpdateRelativesEmailComponent from "@/views/UpdateRelativesEmailComponent.vue";
import HelpComponent from "@/views/HelpComponent.vue";

const webStorageService = new WebStorageService();
const routes = [
  {
    path: "/login",
    name: "LoginComponent",
    component: LoginComponent,
    meta: { authPage: true },
  },
  {
    path: "/login-mfa",
    name: "LoginMFAComponent",
    component: LoginMFAComponent,
    meta: { authPage: true },
  },
  {
    path: "/home",
    name: "HomeComponent",
    component: HomeComponent,
  },
  {
    path: "/mimamori-target",
    name: "MimamoriTargetComponent",
    component: MimamoriTargetComponent,
    children: [
      {
        path: "list",
        name: "MimamoriTargetListComponent",
        component: MimamoriTargetListComponent,
      },
    ],
  },
  {
    path: "/mimamori",
    name: "MimamoriComponent",
    component: MimamoriComponent,
    children: [
      {
        path: "history",
        name: "MimamoriHistoryComponent",
        component: MimamoriHistoryComponent,
        meta: { autoFill: true },
      },
      {
        path: ":analysisResultId",
        name: "EditMimamoriComponent",
        component: EditMimamoriComponent,
      },
    ],
  },
  {
    path: "/registration",
    name: "RegistrationComponent",
    component: RegistrationComponent,
    meta: { authPage: true },
  },
  {
    path: "/change-password",
    name: "ChangePasswordComponent",
    component: ChangePasswordComponent,
  },
  {
    path: "/re-config-mfa",
    name: "ReConfigMFAComponent",
    component: ReConfigMFAComponent,
  },
  {
    path: "/reset-mfa",
    name: "ResetMFAComponent",
    component: ResetMFAComponent,
    meta: { authPage: true },
  },
  {
    path: "/re-setting-auth-app",
    name: "ReSettingAuthAppComponent",
    component: ReSettingAuthAppComponent,
  },
  {
    path: "/re-setting-sms",
    name: "ReSettingSMSComponent",
    component: ReSettingSMSComponent,
    meta: { authPage: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordComponent",
    component: ForgotPasswordComponent,
    meta: { authPage: true },
  },
  {
    path: "/reset-password",
    name: "ResetPasswordComponent",
    component: ResetPasswordComponent,
    meta: { authPage: true },
  },
  {
    path: "/alert-setting",
    name: "AlertSettingComponent",
    component: AlertSettingComponent,
    children: [
      {
        path: "edit",
        name: "AlertEditComponent",
        component: AlertEditComponent,
      },
      {
        path: "preview",
        name: "AlertPreviewComponent",
        component: AlertPreviewComponent,
        props: true,
      },
    ],
  },
  {
    path: "/:patchMatch(.*)",
    name: "ErrorComponent",
    component: ErrorComponent,
  },
  {
    path: "/profile",
    name: "ProfileComponent",
    component: ProfileComponent,
    children: [
      {
        path: "",
        name: "ProfileDetailComponent",
        component: ProfileDetailComponent,
      },
      {
        path: "auto-call-edit",
        name: "AutoCallEditComponent",
        component: AutoCallEditComponent,
      },
      {
        path: "relative-edit",
        name: "RelativeEditComponent",
        component: RelativeEditComponent,
      },
    ],
  },
  {
    path: "/absence-registration",
    name: "AbsenceRegistrationDetailComponent",
    component: AbsenceRegistrationDetailComponent,
    meta: { isEdit: false },
  },
  {
    path: "/create-profile",
    name: "ProfileCreateComponent",
    component: ProfileCreateComponent,
    meta: { authPage: true },
  },
  {
    path: "/mail-address-registration",
    name: "MailRegistrationComponent",
    component: MailRegistrationComponent,
    meta: { authPage: true },
  },
  {
    path: "/signup/:id+",
    name: "SignUpComponent",
    component: SignUpComponent,
    meta: { authPage: true },
  },
  {
    path: "/signup-mfa",
    name: "SignUpMFAComponent",
    component: SignUpMFAComponent,
    meta: { authPage: true },
  },
  {
    path: "/activate-email/:id",
    name: "ActivateEmailComponent",
    component: ActivateEmailComponent,
    meta: { authPage: true },
  },
  {
    path: "/update-relatives-email",
    name: "UpdateRelativesEmailComponent",
    component: UpdateRelativesEmailComponent,
  },
  {
    path: "/help",
    name: "HelpComponent",
    component: HelpComponent,
    meta: { authPage: true },
  },
];

// Development Purpose Pages
if (process.env.VUE_APP_MODE !== "prod") {
  routes.push({
    path: "/styleguide",
    name: "StyleGuideComponent",
    component: StyleGuideComponent,
  });
}

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (to.name !== from.name) return { top: 0 };
  },
});

const validateNoAuthPath = (fromPath, toPath, fromName, toName) => {
  const isRegisterProcess = toPath === "/registration" && fromPath === "/login";
  const isLoginMFAProcess = toPath === "/login-mfa" && fromPath === "/login";
  const isForgotPassword = toPath === "/forgot-password";
  const isResetPasswordProcess =
    toPath === "/reset-password" && fromPath === "/forgot-password";
  const isResetMFAProcess =
    (toPath === "/reset-mfa" && fromPath === "/login-mfa") ||
    (toPath === "/login-mfa" && fromPath === "/reset-mfa");
  const isReSettingSMS = toPath === "/re-setting-sms";
  const isSignUp = toName === "SignUpComponent";
  const isSignUpProcess =
    (fromName === "SignUpComponent" && toPath === "/signup-mfa") ||
    (fromPath === "/registration" && toPath === "/signup-mfa") ||
    (fromName === "SignUpComponent" && toPath === "/registration");
  const isActivateProcess = toName === "ActivateEmailComponent";
  const isHelp = toName === "HelpComponent";

  return (
    isRegisterProcess ||
    isLoginMFAProcess ||
    isResetPasswordProcess ||
    isForgotPassword ||
    isResetMFAProcess ||
    isReSettingSMS ||
    isSignUp ||
    isSignUpProcess ||
    isActivateProcess ||
    isHelp
  );
};

const autoFillParams = (from, to) => {
  return (
    (from.name === "MimamoriHistoryComponent" ||
      from.name === "EditMimamoriComponent" ||
      !from.name) &&
    to.name === "MimamoriHistoryComponent"
  );
};

router.beforeEach((to, from, next) => {
  const token = webStorageService.getLocalStorage(WEB_STORAGE_KEY.ID_TOKEN);
  const userInfo = webStorageService.getLocalStorage(WEB_STORAGE_KEY.USER_INFO);

  // Development Purpose Pages
  if (process.env.VUE_APP_MODE !== "prod") {
    if (to.path === "/styleguide") {
      return next();
    }
  }

  if ((!token || !userInfo) && to.path !== "/login") {
    return validateNoAuthPath(from.path, to.path, from.name, to.name)
      ? next()
      : next({ name: "LoginComponent" });
  }
  if (token && userInfo && to.path === "/") {
    return next({ name: "HomeComponent" });
  }
  to.meta.autoFill = autoFillParams(from, to);
  const companyInfoValid = webStorageService.getLocalStorage(
    WEB_STORAGE_KEY.COMPANY_INFO_VALID
  );
  if (to.path === "/company-setting/info" && !companyInfoValid) {
    return next({ name: "HomeComponent" });
  }
  return next();
});

export default router;
