<template>
  <div class="ProfileDetailComponent">
    <TitleComponent class="ProfileDetailComponent-title">
      <template v-slot:icon
        ><img
          :src="require('@/assets/images/qms/t_ico_profile.svg')"
          class="image"
      /></template>
      <template v-slot:content> プロフィール情報 </template>
    </TitleComponent>
    <CardComponent
      class="main"
      :width="'960px'"
      :height="'auto'"
      v-if="profileInfo"
    >
      <TitleComponent :small="true">
        <template v-slot:icon><span class="icon-t_ico_service" /></template>
        <template v-slot:content> みまもり対象者 </template>
      </TitleComponent>
      <div class="main_userInfo">
        <div class="flex flex-column-rp">
          <div class="flex fullName">
            <span>{{ profileInfo.lastNameKanji }}</span
            ><span>{{ profileInfo.firstNameKanji }}</span>
          </div>
          <div class="flex fullName fullName-small">
            <span>{{ profileInfo.lastNameKana }}</span
            ><span>{{ profileInfo.firstNameKana }}</span
            >様
          </div>
        </div>
        <div class="flex address flex-column-rp" v-if="profileInfo.zipCode">
          <span>〒{{ profileInfo.zipCode }}</span
          ><span>{{ profileInfo.address }}</span>
        </div>
      </div>
      <div class="group">
        <div class="group_title">オートコールの連絡先</div>
        <GroupContentComponent class="group-big">
          <template v-slot:title> 連絡先電話番号 </template>
          <template v-slot:content>
            <div class="telephone">{{ profileInfo.phoneNumber }}</div>
            <div class="status" :class="profileInfo.autocallStatusClass">
              {{ profileInfo.autocallStatus }}
            </div>
          </template>
        </GroupContentComponent>
        <!-- #59427 Hide 特記事項 field -->
        <GroupContentComponent class="group-normal" v-if="false">
          <template v-slot:title> 特記事項 </template>
          <template v-slot:content> {{ profileInfo.notices }} </template>
        </GroupContentComponent>
        <div class="action-group">
          <ButtonComponent
            width="100px"
            height="30px"
            color="#8d9caa"
            @click="goTo('AutoCallEditComponent')"
            >編集</ButtonComponent
          >
        </div>
      </div>
    </CardComponent>
    <CardComponent
      v-if="relativeInfo"
      class="main main_bottom"
      :width="'960px'"
      :height="'auto'"
    >
      <TitleComponent :small="true">
        <template v-slot:icon><span class="icon-t_ico_shinzoku" /></template>
        <template v-slot:content> 親族様など </template>
      </TitleComponent>
      <div class="group">
        <div class="group_title">プロフィール情報</div>
        <GroupContentComponent class="group-normal">
          <template v-slot:title> 氏名 </template>
          <template v-slot:content>
            <div class="flex fullName-small">
              <span>{{ relativeInfo.lastNameKanji }}</span
              ><span>{{ relativeInfo.firstNameKanji }}</span>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent class="group-normal">
          <template v-slot:title> 氏名（カナ）</template>
          <template v-slot:content>
            <div class="flex fullName-small">
              <span>{{ relativeInfo.lastNameKana }}</span
              ><span>{{ relativeInfo.firstNameKana }}</span>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent class="group-normal">
          <template v-slot:title> 郵便番号</template>
          <template v-slot:content> 〒{{ relativeInfo.zipCode }} </template>
        </GroupContentComponent>
        <GroupContentComponent class="group-big">
          <template v-slot:title> 住所</template>
          <template v-slot:content>
            <div class="address-html">{{ relativeInfo.address }}</div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent class="group-normal">
          <template v-slot:title> 連絡先電話番号</template>
          <template v-slot:content> {{ relativeInfo.phoneNumber }} </template>
        </GroupContentComponent>
        <GroupContentComponent class="group-big group-big-rp">
          <template v-slot:title>
            <p class="relationship-title">みまもり対象者との関係</p></template
          >
          <template v-slot:content> {{ relativeInfo.relationship }} </template>
        </GroupContentComponent>
        <!-- #59427 Hide 特記事項 field -->
        <GroupContentComponent class="group-normal" v-if="false">
          <template v-slot:title> 特記事項</template>
          <template v-slot:content> {{ relativeInfo.notices }} </template>
        </GroupContentComponent>
        <div class="action-group">
          <ButtonComponent
            width="100px"
            height="30px"
            color="#8d9caa"
            @click="goTo('RelativeEditComponent')"
            >編集</ButtonComponent
          >
        </div>
      </div>
    </CardComponent>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProfileDetailComponent",
  computed: {
    ...mapState({
      profileInfo: (state) => state.settings.profileInfo,
      relativeInfo: (state) => state.settings.relativeInfo,
    }),
  },
  methods: {
    goTo(component) {
      this.$router.push({ name: component });
    },
  },
};
</script>

<style lang="scss" scoped>
.ProfileDetailComponent {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: var(--default-distance-card);
  &-title {
    width: 960px;
    margin: 0 auto;
    display: flex;
    color: #000000 !important;
    .icon-ico_user::before {
      color: #000000;
    }
  }
  .main {
    padding: 30px 40px;
    .flex {
      display: flex;
      align-items: center;
    }
    &_title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      font-weight: 500;
    }
    &_userInfo {
      padding: 13px 0 30px;
      margin-left: 21px;
      display: flex;
      flex-direction: column;
      gap: 7px;
      .fullName {
        font-size: 20px;
        font-weight: 500;
        gap: 13px;
        margin-right: 12px;
        &-small {
          font-size: 14px;
          gap: 10px;
        }
      }
      .address {
        gap: 19px;
      }
    }
    .group {
      &_title {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
        border-bottom: solid 2px #d0d9df;
      }
      .telephone {
        font-size: 24px;
        font-weight: 500;
      }
      .status {
        margin-left: auto;
        font-size: 12px;
        width: 99px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .orange {
        color: #eba447;
        border: solid 1px #eba447;
      }
      .blue {
        color: #328dc2;
        border: solid 1px #328dc2;
      }
      &-big {
        height: 80px;
      }
      &-normal {
        min-height: 60px;
      }
      .action-group {
        margin-top: 30px;
        text-align: end;
      }
    }
    &_bottom {
      margin: 30px 0 40px;
    }
    .fullName-small {
      gap: 10px;
    }
    .address-html {
      white-space: pre-wrap;
    }
    .relationship {
      width: 100%;
      &-title {
        width: 120px;
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .ProfileDetailComponent {
    height: 100%;
    display: block;
    margin: 0;
    .main {
      width: 100% !important;
      padding: 30px 20px;
      &_userInfo {
        margin: 0 20px;
      }
      .flex-column-rp {
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
      }
      .group-big {
        height: 112px;
        display: flex;
        flex-direction: column;
        gap: 11px;
        &-rp {
          height: 92px;
        }
      }
      .group-normal {
        min-height: 92px;
        display: flex;
        flex-direction: column;
        gap: 11px;
      }
      .group {
        .action-group {
          text-align: center;
        }
      }
      .relationship-title {
        width: 100%;
      }
    }
    &-title {
      width: 300px;
      margin: 0 20px;
    }
  }
}
</style>
