<template>
  <div class="SignUpComponent">
    <Form @submit="submit" v-slot="{ meta }" :validation-schema="schema">
      <CardComponent class="form-signUp" :width="'960px'" :height="'auto'">
        <TitleComponent
          class="form-signUp_title fitContent"
          :hasUnderLine="true"
        >
          <template v-slot:content>パスワードを入力</template>
        </TitleComponent>
        <p class="form-signUp_description">
          送信された仮パスワードを入力してください。<br />
          パスワード変更後は、変更後のパスワードを入力してください。
        </p>

        <div class="form-signUp_inputGroups">
          <InputComponent
            name="password"
            placeholder="仮パスワードを入力"
            inputFilter="trimPassword"
            :defaultValue="defaultPassword"
            @trigle-change="
              () => {
                errorDetail = null;
              }
            "
          />
        </div>
        <ErrorDetailComponent
          v-if="errorDetail"
          :errorDetail="'仮パスワードが無効です。暫くたってから再度試してください。'"
        />
        <div class="form-action-group">
          <ButtonComponent
            buttonType="submit"
            width="160px"
            :disabled="!(meta.valid && (defaultPassword || meta.dirty))"
          >
            次へ
          </ButtonComponent>
        </div>
        <div class="d-flex flex-column align-center justify-center form-notice">
          <span class="issue-text">
            仮パスワード有効期限は発行から30日となります。
          </span>
          <LinkComponent :link="$domain.HELP_CONTENT_LINK['2-1-a']">
            <span class="d-flex align-center justify-center black">
              <i class="icon icon-help"></i>
              操作方法がご不明な場合
            </span>
          </LinkComponent>
          <p class="memo">
            有効期限切れによりアクセスできない場合は、<br
              class="mobile"
            />下記までお問い合わせください。
          </p>
        </div>
        <InquiryBannerComponent class="mb-5" />
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Form } from "vee-validate";
import LoginService from "@/services/login.service";
import { singupSchema } from "@/validations/schemas/singupSchema";
import { STATUS } from "@/globals/enums";
import { useRoute } from "vue-router";
import { ref } from "@vue/reactivity";

export default {
  name: "SignUpComponent",
  setup() {
    const route = useRoute();
    const store = useStore();
    const defaultPassword = ref(route.params.id[1] || "");

    /**
     * #78325
     * The username {relatives_account_id} is in the URL parameter "id[0]".
     * This value is referenced by the "InquiryBannerComponent.vue" on each screen before logging in.
     *
     * DETAILS:
     * Before logging in, the value of "username" is being stored also in the "tempUser" state of the "settings" store.
     * However, the property name of the object that contains the "username" value is "emailAddress", which is confusing,
     * so we have prepared a dedicated state (tempUsername) to store the "username" value.
     *
     * NOTE:
     * After logging in, the value of "username" is stored in the "USER_INFO" localStorage or "tempUser.currentUser".
     * (Alternatively, you may use Auth.currentAuthenticatedUser() to get the user information.)
     */
    store.dispatch("settings/changeTempUsername", route.params.id[0]);

    return {
      defaultPassword,
    };
  },
  components: {
    Form,
  },
  data: () => ({
    schema: singupSchema,
    loginService: new LoginService(),
    errorDetail: null,
  }),
  methods: {
    async submit(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.loginService.signIn(
        this.$route.params.id[0],
        data.password,
        null,
        null,
        false,
        true
      );
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
      this.$store.dispatch("global/updateLoadingStatus", false);
    },
    async cancel(e) {
      e.preventDefault();
      await this.loginService.logout();
    },
  },
};
</script>

<style scoped lang="scss">
.SignUpComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-signUp {
      padding: 30px 40px;
      margin: 40px 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &_description {
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0 40px;
        text-align: center;
      }
      &_inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 220px 20px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          font-weight: bold;
          color: #7c8185;
        }
        .InputComponent {
          margin: 0;
        }
      }
    }
    &-action-group {
      display: flex;
      gap: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    &-notice {
      margin: 50px auto 0;
      .issue-text {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.memo {
  text-align: center;
  font-weight: normal;
  margin-bottom: 1em;
  color: var(--color-label);
}
.memo .mobile {
  display: none;
}
@media only screen and (max-width: $mobile-breakpoint) {
  .memo .mobile {
    display: initial;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .SignUpComponent {
    height: 100%;
    display: block;
    .v-card {
      width: 100% !important;
      height: 100% !important;
    }
    form {
      height: 100%;
    }
    .form {
      &-signUp {
        padding: 30px 20px;
        margin: 0;

        &_title {
          font-size: 20px;
          font-weight: 500;
        }
        &_description {
          font-size: 14px;
          font-weight: 500;
          margin: 20px 0 40px;
          text-align: center;
        }
        &_inputGroups {
          display: flex;
          flex-direction: column;
          gap: 40px;
          padding: 0;
          width: 100%;
          .InputComponent {
            width: 100% !important;
          }
        }
      }
      &-action-group {
        margin-top: 30px;
      }
    }
  }
}
</style>
