<template>
  <div class="ReConfigMFAComponent">
    <TitleComponent>
      <template v-slot:icon><span class="icon-ico_guard"></span></template>
      <template v-slot:content>セキュリティ設定</template>
    </TitleComponent>
    <CardComponent
      class="card"
      :width="'960px'"
      :height="'auto'"
      v-if="currentUserMFAInfo.email.length > 0"
    >
      <p class="card__description">
        不正アクセス対策のため、パスワードに加え、登録されているデバイスの認証コードを確認します。
      </p>
      <div class="card__body">
        <div class="card__body_title">セキュリティ設定</div>
        <Form
          @submit="updateMFA"
          :validation-schema="schema"
          v-slot="{ meta, values }"
        >
          <div class="form__radioGroupInput">
            <div class="form__radioGroupInput-left">
              <InputComponent
                type="radio"
                name="typeMFA"
                id="typeMFA_SMS"
                value="SMS"
                label="電話番号に認証コードを送信する"
                :defaultValue="currentUserMFAInfo.MFAType"
                placeholder="電話番号を選択"
                @trigle-change="handleChangeType"
              />
            </div>
            <div class="form__radioGroupInput-right">
              <p class="form__radioGroupInput-right_title">電話番号</p>
              <div class="form__radioGroupInput-right_description">
                <InputComponent
                  name="telephone"
                  :small="true"
                  :defaultValue="currentUserMFAInfo.phoneNumber"
                  inputFilter="number"
                  maxlength="11"
                  placeholder="電話番号を入力"
                  :disabled="values.typeMFA === TYPE_MFA.TOTP"
                  @trigle-change="errorDetail = null"
                />
                <p class="desc">
                  ※半角数字、ハイフンなしで入力してください。<br />
                  ※電話番号はアカウントのセキュリティのために使用されます。
                </p>
              </div>
            </div>
          </div>
          <div class="form__radioGroupInput form__radioGroupInput-totp">
            <div class="form__radioGroupInput-left">
              <InputComponent
                type="radio"
                name="typeMFA"
                id="typeMFA_TOTP"
                value="TOTP"
                label="Authenticatorアプリの認証コードを使用する"
                :defaultValue="currentUserMFAInfo.MFAType"
                @trigle-change="handleChangeType"
              />
            </div>
            <div class="form__radioGroupInput-right auto-left">
              <ButtonComponent
                width="100%"
                height="30px"
                className="textTransformNone"
                :disabled="values.typeMFA === TYPE_MFA.SMS"
                @click="updateAuthApp"
              >
                Authenticatorアプリの設定
              </ButtonComponent>
            </div>
          </div>
          <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
          <div class="form_actionGroup">
            <ButtonComponent
              :disabled="
                !(meta.valid && meta.dirty) || values.typeMFA === TYPE_MFA.TOTP
              "
              buttonType="submit"
            >
              保存
            </ButtonComponent>
          </div>
        </Form>
      </div>
    </CardComponent>
  </div>
</template>

<script>
import { TYPE_MFA, STATUS } from "../globals/enums.js";
import LoginService from "@/services/login.service";
import { Form } from "vee-validate";
import { mapState } from "vuex";
import { reConfigMFASchema } from "../validations/schemas/reConfigMFASchema";

export default {
  name: "ReConfigMFAComponent",
  data: () => ({
    loginService: new LoginService(),
    type: TYPE_MFA,
    schema: reConfigMFASchema,
    userChoiseValue: null,
    errorDetail: null,
  }),
  components: {
    Form,
  },
  created() {
    this.TYPE_MFA = TYPE_MFA;
  },
  async mounted() {
    this.$store.dispatch("global/updateLoadingStatus", true);
    await this.loginService.getCognitoUserData();
    this.$store.dispatch("global/updateLoadingStatus", false);
  },
  computed: {
    ...mapState({
      currentUserMFAInfo: (state) => state?.settings?.currentUserMFAInfo,
      isUserUsingTOTP: (state) =>
        state?.settings?.currentUserMFAInfo.MFAType === TYPE_MFA.TOTP,
    }),
  },
  methods: {
    updateAuthApp() {
      this.$router.push({ name: "ReSettingAuthAppComponent" });
    },
    async updateMFA(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result =
        data.typeMFA === TYPE_MFA.SMS // NOTE: The 'typeMFA' is always TYPE_MFA.SMS
          ? await this.loginService.registerSMSAgain(data.telephone)
          : await this.loginService.setPreferredTOTP(); // NOTE: Will NOT be called

      /**
       * NOTE:
       * Even if user data could not be retrieved by getCognitoUserData(),
       * there is no need to display an error message based on the result
       * because the data has already been successfully updated
       * and just before leaving the screen.
       */
      await this.loginService.getCognitoUserData();

      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR) {
        this.errorDetail = result.response?.detail;
      }
    },
    handleChangeType(e) {
      this.userChoiseValue = e.target.value;
      this.errorDetail = null;
    },
  },
};
</script>

<style scoped lang="scss">
.ReConfigMFAComponent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--default-distance-card);
  .TitleComponent {
    width: 960px;
    text-align: left;
    color: #000;
    padding: 0 0 11px 0;
    .icon-ico_guard {
      &::before {
        color: #000;
        font-size: 29px;
      }
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    padding: 30px 40px 65px;
    &__description {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    &__body {
      &_title {
        padding-bottom: 5px;
        text-align: left;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        border-bottom: solid 2px #d0d9df;
      }
      .form {
        &__radioGroupInput {
          padding: 33px 20px 33px 10px;
          display: flex;
          gap: 58px;
          border-bottom: solid 1px #d0d9df;
          &-left {
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.7px;
            label {
              font-size: 14px;
              font-weight: 600;
            }
          }
          &-right {
            display: flex;
            gap: 20px;
            margin-top: -3px;
            &.auto-left {
              margin: 0 auto;
              .ButtonComponent {
                width: 260px;
                button {
                  padding: 4px 36px 6px;
                }
              }
            }
            &_title {
              color: #7c8185;
              width: 100px;
              font-size: 14px;
              font-weight: normal;
              padding-top: 5px;
            }
            &_description {
              color: #7c8185;
              p {
                margin-top: 29px;
                font-size: 12px;
                line-height: 1.5;
                color: #7c8185;
              }
            }
            .InputComponent {
              margin: 0;
              width: 240px;
            }
          }
          &-totp {
            padding: 35px 20px 35px 10px;
            .form__radioGroupInput-left {
              margin-top: 3px;
            }
          }
        }
        &_actionGroup {
          padding-top: 30px;
          .ButtonComponent {
            text-align: center;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .ReConfigMFAComponent {
    height: 100%;
    align-items: flex-start;
    margin: 0;
    .v-card {
      width: 100% !important;
      height: 100% !important;
    }
    form {
      height: 100%;
    }
    .TitleComponent {
      width: 300px;
      margin-left: 20px;
    }
    .card {
      display: flex;
      flex-direction: column;
      padding: 30px 20px;
      &__description {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      &__body {
        &_title {
          padding-bottom: 5px;
          text-align: left;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          border-bottom: solid 2px #d0d9df;
        }
        .form {
          &__radioGroupInput {
            padding: 33px 20px 33px 10px;
            display: flex;
            flex-direction: column;
            gap: 0;
            border-bottom: solid 1px #d0d9df;
            &-left {
              font-weight: 500;
              font-size: 14px;
              line-height: 0.86;
              letter-spacing: 0.7px;
              label {
                font-size: 14px;
                font-weight: 600;
              }
            }
            &-right {
              display: flex;
              flex-direction: column;
              gap: 20px;
              &.auto-left {
                margin-left: auto;
                .ButtonComponent {
                  width: 260px;
                  button {
                    padding: 4px 36px 6px;
                  }
                }
              }
              &_title {
                color: #7c8185;
                width: 100px;
                font-size: 14px;
                font-weight: normal;
              }
              &_description {
                color: #7c8185;
                .InputComponent {
                  width: 100% !important;
                }
                p {
                  margin-top: 28px;
                  font-size: 12px;
                  line-height: 1.5;
                  color: #7c8185;
                }
              }
              .InputComponent {
                margin-bottom: 10px;
                width: 240px;
              }
            }
          }
          &_actionGroup {
            padding-top: 30px;
            .ButtonComponent {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
