<template>
  <div class="ButtonComponent">
    <!-- default -->
    <v-btn
      v-if="type === 'default'"
      :width="width"
      :height="height"
      :size="size"
      :type="buttonType"
      :rounded="rounded"
      :color="color"
      :text-color="textColor"
      flat
      :disabled="disabled"
      @click="onClick"
      :class="className"
    >
      <slot></slot>
    </v-btn>

    <!-- outlined -->
    <v-btn
      v-else-if="type === 'outline'"
      :width="width"
      :height="height"
      :size="size"
      :type="buttonType"
      :color="color"
      :rounded="rounded"
      :text-color="textColor"
      flat
      :variant="disabled ? 'contained' : 'outlined'"
      :disabled="disabled"
      @click="onClick"
      :class="className"
      class="outlinedBg"
    >
      <slot></slot>
    </v-btn>

    <a
      class="v-btn"
      v-else-if="type === 'link'"
      :width="width"
      :height="height"
      :size="size"
      :type="buttonType"
      :color="color"
      :rounded="rounded"
      :text-color="textColor"
      flat
      variant="outlined"
      :disabled="disabled"
      @click="onClick"
      border="2"
      :class="className"
    >
      <slot></slot>
    </a>
  </div>
</template>

<script>
import styles from "@/assets/scss/vars.modules.scss";
export default {
  name: "ButtonComponent",
  props: {
    type: {
      type: String,
      default: "default",
    },
    buttonType: {
      type: String,
      default: "default",
    },
    width: {
      type: [String, Number],
      default: 150,
    },
    height: {
      type: [String, Number],
      default: 50,
    },
    className: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    size: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: styles["button-base-color"],
    },
    textColor: {
      type: String,
      default: styles["button-base-text-color"],
    },
    rounded: {
      type: String,
      default: "pill",
    },
  },
};
</script>

<style lang="scss" scoped>
.ButtonComponent {
  .smallFontsize {
    font-size: var(--font-size-default);
    font-weight: var(--font-weight-medium);
  }
  .v-btn--disabled {
    background-color: var(--color-disabled) !important;
    color: rgb(255, 255, 255) !important;
    border: var(--color-disabled);
  }
  .v-btn--variant-outlined {
    border: 2px solid currentColor;
  }
  .noRounded {
    border-radius: 0 !important;
    height: auto !important;
    width: 100% !important;
    padding: 3px 6px 4px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
  }
  a {
    background-color: var(--button-base-color);
    color: var(--button-base-text-color);
    caret-color: var(--button-base-text-color);
    height: 50px;
    width: 150px;
    &.disabled {
      pointer-events: none;
      background-color: var(--color-disabled) !important;
    }
  }
  &-fontbold {
    font-weight: bold;
  }
  .outlinedBg {
    background-color: var(--button-outlined-color);
  }
  .textTransformNone {
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 0.43;
    letter-spacing: 0.7px;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .ButtonComponent {
    .max-width-responsive {
      width: 100% !important;
    }
  }
}
</style>
