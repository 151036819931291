import { filterMFAInfo } from "../converter/settings";
import { convertProfile, convertRelative } from "../converter/profile.convert";
const state = {
  currentUserMFAInfo: {
    email: "",
    phoneNumber: "",
    MFAType: "NO_MFA",
  },
  tempUser: null,
  tempUsername: null,
  QRcode: null,
  profileInfo: null,
  relativeInfo: null,
};

// Getter functions
const getters = {
  getCurrentUserMFAInfo() {
    return state.currentUserMFAInfo;
  },
  getTempUser() {
    return state.tempUser;
  },
  getTempUsername() {
    return state.tempUsername;
  },
  getQRcode() {
    return state.QRcode;
  },
  getProfileInfo() {
    return state.profileInfo;
  },
};

// Mutations
const mutations = {
  setCurrentUserMFAInfo(state, data) {
    state.currentUserMFAInfo = data;
  },
  setTempUser(state, data) {
    state.tempUser = data;
  },
  setTempUsername(state, data) {
    state.tempUsername = data;
  },
  setQRCode(state, data) {
    state.QRcode = data;
  },
  setProfileInfo(state, data) {
    state.profileInfo = data;
  },
  setRelativeInfo(state, data) {
    state.relativeInfo = data;
  },
  resetState(state) {
    state.currentUserMFAInfo = {
      email: "",
      phoneNumber: "",
      MFAType: "NO_MFA",
    };
    state.tempUser = null;
    state.QRcode = null;
    state.profileInfo = null;
    state.relativeInfo = null;
  },
};

// Actions
const actions = {
  changeCurrentUserMFAInfo({ commit }, data) {
    commit("setCurrentUserMFAInfo", filterMFAInfo(data));
  },
  changeTempUser({ commit }, data) {
    commit("setTempUser", data);
  },
  changeTempUsername({ commit }, data) {
    commit("setTempUsername", data);
  },
  changeQRCode({ commit }, data) {
    commit("setQRCode", data);
  },
  changeProfileInfo({ commit }, data) {
    commit("setProfileInfo", convertProfile(data));
  },
  changeRelativeInfo({ commit }, data) {
    commit("setRelativeInfo", convertRelative(data));
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
