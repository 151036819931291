<template>
  <div class="LoginComponent">
    <div class="LoginComponent__content">
      <div class="LoginComponent__content_header">
        <img
          :src="$domain.LOGIN_LOGO.src"
          :width="$domain.LOGIN_LOGO.width"
          :height="$domain.LOGIN_LOGO.height"
          :alt="$domain.LOGIN_LOGO.alt"
          class="logo"
        />
      </div>
      <Form
        @submit="login"
        :validation-schema="schema"
        v-slot="{ meta }"
        class="form"
      >
        <CardComponent
          class="form-login"
          :width="'auto'"
          :height="errorDetail ? '348px' : '313px'"
        >
          <div class="form-login-inner">
            <div class="login-input">
              <InputComponent
                name="email"
                type="email"
                :bigBorder="true"
                placeholder="ID"
                leftIcon="icon-ico_user"
                :maxWidth="true"
                maxlength="100"
                @trigle-change="
                  () => {
                    errorDetail = null;
                  }
                "
              />
            </div>
            <div class="login-input">
              <InputComponent
                name="password"
                type="password"
                :bigBorder="true"
                :maxWidth="true"
                placeholder="パスワード"
                leftIcon="icon-ico_key"
                maxlength="256"
                inputFilter="trimPassword"
                @trigle-change="
                  () => {
                    errorDetail = null;
                  }
                "
              />
            </div>
            <ErrorDetailComponent
              v-if="errorDetail"
              :errorDetail="errorDetail"
            />
            <ButtonComponent
              buttonType="submit"
              :width="'360px'"
              :disabled="!(meta.valid && meta.touched)"
              className="max-width-responsive"
              >ログイン
            </ButtonComponent>
            <div class="text-caption-forget-password" @click="forgotPassword()">
              ID・パスワードをお忘れですか？
            </div>
          </div>
        </CardComponent>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import LoginService from "@/services/login.service";
import UserService from "@/services/user.service";
import { loginSchema } from "@/validations/schemas/loginSchema";
import { STATUS } from "@/globals/enums";
export default {
  name: "LoginComponent",
  components: {
    Form,
  },
  data: () => ({
    loginService: new LoginService(),
    userService: new UserService(),
    schema: loginSchema,
    errorDetail: null,
  }),
  methods: {
    async login(userInfo) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.loginService.signIn(
        userInfo.email,
        userInfo.password
      );
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
    forgotPassword() {
      this.$router.push({ name: "ForgotPasswordComponent" });
    },
  },
};
</script>

<style scoped lang="scss">
.LoginComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    vertical-align: bottom;
  }
  &__content {
    display: flex;
    flex-direction: column;
    &_header {
      text-align: center;
      padding: $login-logo-header-padding;
      background-color: #fff;
    }
    .form {
      &-login {
        width: 480px !important;
      }
      &-login-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .login-input {
          width: 360px;
        }
        .InputComponent {
          margin-bottom: 0;
        }
        .ButtonComponent {
          font-size: 18px;
          font-weight: bold;
        }
        .ErrorDetailComponent {
          margin: 0 15px;
        }
      }
    }
  }
  .text-caption-forget-password {
    cursor: pointer;
    margin-bottom: 35px;
    font-size: 12px;
    font-weight: normal;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .LoginComponent {
    display: block;
    background-color: #fff;
    .logo {
      width: $login-logo-mobile-width;
      height: $login-logo-mobile-height;
    }
    &__content {
      height: 100%;
      &_header {
        flex: none;
        height: 60px;
        padding: 0 20px;
        text-align: left;
        background-color: #f3f6f7;
        display: flex;
        align-items: center;
      }
      .form {
        flex: auto;
        display: grid;
        place-items: center;
        &-login {
          padding: 30px;
          width: 100% !important;
          height: auto !important;
          .login-input {
            width: 100%;
          }
          .InputComponent {
            margin-bottom: 40px;
          }
          .ButtonComponent {
            width: 100%;
          }
          .ErrorDetailComponent {
            margin: 0 15px 15px;
          }
        }
      }
    }
    .text-caption-forget-password {
      margin: 30px 0 35px;
    }
  }
}
</style>
