<template>
  <a
    :href="link"
    :target="target"
    :rel="rel"
    class="LinkComponent"
    :class="className"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "LinkComponent",
  props: {
    link: {
      type: String,
      default: "#",
    },
    target: {
      type: String,
      default: "_blank",
    },
    rel: {
      type: String,
      default: "noopener",
    },
    className: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.LinkComponent {
  text-decoration: none;
  color: var(--color-black-text);
}
</style>
