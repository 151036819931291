<template>
  <Datepicker
    v-model="currentDate"
    locale="ja"
    ref="datePickerRef"
    :enableTimePicker="false"
    :minDate="minDate"
    :maxDate="maxDate"
    :disabled="disabled"
    :month-year-component="monthYear"
    autoApply
    @update:modelValue="selectDate"
    :disabledDates="disabledList"
  >
    <template #trigger>
      <span
        class="icon-ico_calendar clickable-text"
        :class="iconClassName"
      ></span>
    </template>
    <template #action-select="{ value }">
      <ButtonComponent
        width="50px"
        height="25px"
        color="#328dc2"
        className="ButtonComponent-fontbold"
        class="custom-select"
        @click="selectDate(value)"
        >選択</ButtonComponent
      >
    </template>
  </Datepicker>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import { START_RANGE_TIME } from "@/globals/enums";
import { ref, defineAsyncComponent, computed } from "vue";
const MonthYear = defineAsyncComponent(() =>
  import("./MonthYearCustomComponent.vue")
);
export default {
  name: "DatePickerComponent",
  setup(props) {
    const date = props.initDate ? ref(props.initDate) : null;
    const minDate = props?.minDateSearch
      ? ref(props.minDateSearch)
      : new Date();
    const maxDate = ref(props.maxDateSearch);
    const monthYear = computed(() => MonthYear);
    return {
      date,
      minDate,
      maxDate,
      monthYear,
    };
  },
  props: {
    iconClassName: {
      type: String,
      default: "",
    },
    initDate: {
      type: Date,
      default: new Date(),
    },
    minDateSearch: {
      type: Date,
      default: new Date(
        START_RANGE_TIME.YEAR,
        START_RANGE_TIME.MONTH,
        START_RANGE_TIME.DAY,
        0,
        0,
        0,
        0
      ),
    },
    maxDateSearch: {
      type: Date,
      default: new Date(new Date().getFullYear(), 11, 31, 0, 0, 0, 0),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Datepicker,
  },
  data: () => ({
    currentDate: null,
  }),
  methods: {
    selectDate(value) {
      if (this.$refs.datePickerRef) {
        this.$emit("change-date", value);
        this.$refs.datePickerRef.closeMenu();
      }
    },
  },
  watch: {
    initDate: function (val) {
      this.currentDate = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable-text {
  cursor: pointer;
}
.dp__action_buttons {
  text-align: center;
}
.custom-select {
  cursor: pointer;
  margin-left: auto;
  font-size: 12px;
}
.icon-ico_calendar {
  &::before {
    font-size: 19px;
  }
}
</style>
