import * as Yup from "yup";
import {
  validateSearchStartTimeWithEndTime,
  validateSearchEndTimeWithStartTime,
} from "../common";
import { ERROR_MESSAGES } from "@/globals/error-messages";

export const miamoriSearchSchema = Yup.object().shape({
  startYear: validateSearchStartTimeWithEndTime(
    "startYear",
    "startMonth",
    "startDate",
    "endYear",
    "endMonth",
    "endDate",
    ERROR_MESSAGES.NOT_VALID_TIME,
    ERROR_MESSAGES.PAST_TIME_NOT_VALID,
    ERROR_MESSAGES.FUTURE_TIME_NOT_VALID
  ),
  startMonth: validateSearchStartTimeWithEndTime(
    "startYear",
    "startMonth",
    "startDate",
    "endYear",
    "endMonth",
    "endDate"
  ),
  startDate: validateSearchStartTimeWithEndTime(
    "startYear",
    "startMonth",
    "startDate",
    "endYear",
    "endMonth",
    "endDate"
  ),
  endYear: validateSearchEndTimeWithStartTime(
    "startYear",
    "startMonth",
    "startDate",
    "endYear",
    "endMonth",
    "endDate",
    ERROR_MESSAGES.NOT_VALID_TIME,
    ERROR_MESSAGES.PAST_TIME_NOT_VALID
  ),
  endMonth: validateSearchEndTimeWithStartTime(
    "startYear",
    "startMonth",
    "startDate",
    "endYear",
    "endMonth",
    "endDate"
  ),
  endDate: validateSearchEndTimeWithStartTime(
    "startYear",
    "startMonth",
    "startDate",
    "endYear",
    "endMonth",
    "endDate"
  ),
  judgmentResult: Yup.string().nullable(),
});
