<template>
  <div class="RegistrationComponent">
    <Form
      @submit="createNewPassword"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <CardComponent
        class="form-registration"
        :width="'960px'"
        :height="'auto'"
      >
        <TitleComponent
          class="form-registration_title fitContent"
          :hasUnderLine="true"
        >
          <template v-slot:content>パスワード設定</template>
        </TitleComponent>
        <p class="form-registration-description">
          今後使用するパスワードを設定してください。
        </p>

        <div class="form-registration-inputGroups">
          <div class="form-registration-inputGroup"></div>
          <div class="form-registration-inputGroup">
            <InputComponent
              name="password"
              type="password"
              maxlength="256"
              :validateSteps="validateSteps"
              inputFilter="trimPassword"
              placeholder="新しいパスワードを入力"
              @trigle-change="
                () => {
                  errorDetail = null;
                }
              "
            />
          </div>
          <div class="form-registration-inputGroup">
            <InputComponent
              name="passwordConfirmation"
              type="password"
              maxlength="256"
              inputFilter="trimPassword"
              placeholder="新しいパスワードを再入力"
              @trigle-change="
                () => {
                  errorDetail = null;
                }
              "
            />
          </div>
        </div>
        <ErrorDetailComponent
          v-if="errorDetail"
          :errorDetail="'新しいパスワード設定ができませんでした。暫くたってから再度試してください。'"
        />
        <ButtonComponent
          buttonType="submit"
          :width="'160px'"
          :disabled="!(meta.valid && meta.dirty)"
          >登録
        </ButtonComponent>
        <LinkComponent :link="$domain.HELP_CONTENT_LINK['2-1-b']">
          <span class="d-flex align-center justify-center black">
            <i class="icon icon-help"></i>
            操作方法がご不明な場合
          </span>
        </LinkComponent>
        <InquiryBannerComponent class="mb-5" />
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import {
  SPECIAL_STRING,
  VALIDATES,
  MIN_8_CHARACTER,
  STATUS,
} from "@/globals/enums";
import { Form } from "vee-validate";
import { mapState } from "vuex";
import LoginService from "@/services/login.service";
import { registrationPasswordSchema } from "@/validations/schemas/registrationPasswordSchema";

export default {
  name: "RegistrationComponent",
  components: {
    Form,
  },
  data: () => ({
    loginService: new LoginService(),
    validateSteps: [
      {
        message: SPECIAL_STRING.OVER_8_CHARACTERS,
        check: (data) => data.length >= MIN_8_CHARACTER,
      },
      {
        message: SPECIAL_STRING.NUMBER,
        check: (data) => VALIDATES.HAS_NUMBER.test(data),
      },
      {
        message: SPECIAL_STRING.UPPER_DOWNER_CHARACTERS,
        check: (data) => VALIDATES.HAS_DOWNER_CHARACTER.test(data),
      },
    ],
    schema: registrationPasswordSchema,
    prevRoute: null,
    errorDetail: null,
  }),
  computed: mapState({
    tempUser: (state) => state.settings.tempUser,
  }),
  methods: {
    async createNewPassword(value) {
      const result = await this.loginService.signIn(
        this.tempUser.emailAddress,
        this.tempUser.tempPassword,
        value.password,
        null,
        false,
        this.prevRoute?.name === "SignUpComponent"
      );
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style scoped lang="scss">
.RegistrationComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-registration {
      padding: 30px 40px;
      margin: 40px 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &-description {
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0 0;
      }
      &-inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 220px 40px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          // font-weight: bold;
          color: #7c8185;
        }
        .InputComponent {
          margin: 0;
        }
      }
      .ButtonComponent {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .RegistrationComponent {
    height: 100%;
    display: block;
    .v-card {
      width: 100% !important;
      height: 100% !important;
    }
    form {
      height: 100%;
    }
    .form {
      &-registration {
        padding: 30px 20px;
        margin: 0;

        display: flex;
        flex-direction: column;
        align-items: center;

        &_title {
          font-size: 20px;
          font-weight: 500;
        }
        &-description {
          font-size: 14px;
          font-weight: 500;
          margin: 20px 0 0;
          width: 100%;
        }
        &-inputGroups {
          width: 100%;
          gap: 40px;
          padding: 0;
          margin-bottom: 40px;
        }
        &-inputGroup {
          display: flex;
          gap: 30px;
          align-items: center;
          justify-content: space-between;
          &_title {
            font-size: 14px;
            // font-weight: bold;
            color: #7c8185;
          }
          .InputComponent {
            margin: 0;
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
