<template>
  <component :is="helpComponent" />
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  computed: {
    helpComponent() {
      return defineAsyncComponent(() =>
        import(
          `@/__resources/${process.env.VUE_APP_RESOURCE}/views/HelpComponent.vue`
        )
      );
    },
  },
};
</script>
