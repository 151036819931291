<template>
  <div v-if="pauseStart" class="mimamori-record_status_fuzai icon-analysis">
    <p class="mimamori-record__label">ー</p>
  </div>
  <div v-else class="icon-analysis">
    <div
      :class="{
        'mimamori-record_status_seijyo':
          analysisResultCode === ANALYSIS_RESULT_CODE.NORMAL.VALUE,
        'mimamori-record_status_error':
          analysisResultCode === ANALYSIS_RESULT_CODE.ERROR.VALUE,
        'mimamori-record_status_ijyo2':
          analysisResultCode === ANALYSIS_RESULT_CODE.ABNORMAL.VALUE &&
          confirmStatus === CONFIRM_STATUS.CONFIRMED.VALUE,
        'mimamori-record_status_alert':
          analysisResultCode === ANALYSIS_RESULT_CODE.ABNORMAL.VALUE &&
          (confirmStatus === CONFIRM_STATUS.UNCONFIRMED.VALUE ||
            confirmStatus === CONFIRM_STATUS.CHECKING.VALUE) &&
          alertFlag,
        'mimamori-record_status_ijyo':
          analysisResultCode === ANALYSIS_RESULT_CODE.ABNORMAL.VALUE &&
          (confirmStatus === CONFIRM_STATUS.UNCONFIRMED.VALUE ||
            confirmStatus === CONFIRM_STATUS.CHECKING.VALUE) &&
          !alertFlag,
      }"
    >
      <p class="mimamori-record__label">
        <span v-if="analysisResultCode === ANALYSIS_RESULT_CODE.NORMAL.VALUE">
          正常
        </span>
        <span
          v-else-if="analysisResultCode === ANALYSIS_RESULT_CODE.ERROR.VALUE"
        >
          エラー
        </span>
        <span v-else>異常</span>
      </p>
    </div>
  </div>
</template>

<script>
import { ANALYSIS_RESULT_CODE, CONFIRM_STATUS } from "@/globals/enums";
export default {
  name: "AnalysisStatusComponent",
  created() {
    this.ANALYSIS_RESULT_CODE = ANALYSIS_RESULT_CODE;
    this.CONFIRM_STATUS = CONFIRM_STATUS;
  },
  props: {
    pauseStart: {
      type: Boolean,
      default: false,
    },
    analysisResultCode: {
      type: String,
      default: "",
    },
    confirmStatus: {
      type: Number,
      default: 0,
    },
    alertFlag: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-analysis {
  display: flex;
  // justify-content: center;
  .mimamori-record_status_alert {
    background: unset !important;
  }
}
</style>
