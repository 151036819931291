<template>
  <div class="AbsenceRegistrationDetailComponent">
    <TitleComponent class="AbsenceRegistrationDetailComponent-title">
      <template v-slot:icon
        ><img
          :src="require('@/assets/images/qms/t_ico_fuzai.svg')"
          class="image"
      /></template>
      <template v-slot:content> 不在期間設定 </template>
    </TitleComponent>
    <CardComponent
      class="main"
      :width="'960px'"
      :height="'510px'"
      v-if="!isEdit"
    >
      <div class="group" v-if="!!absenceInfo">
        <div class="group_title">不在期間</div>
        <GroupContentComponent class="group-normal">
          <template v-slot:title> 開始日 </template>
          <template v-slot:content>
            <span
              class="marginTop10"
              v-formatDateTimeJanpanese="absenceInfo.startDateTime"
            ></span>
          </template>
        </GroupContentComponent>
        <GroupContentComponent class="group-normal">
          <template v-slot:title> 終了日 </template>
          <template v-slot:content>
            <span
              class="marginTop10"
              v-formatDateTimeJanpanese="absenceInfo.endDateTime"
            ></span>
          </template>
        </GroupContentComponent>
        <GroupContentComponent class="group-normal">
          <template v-slot:title> 不在理由 </template>
          <template v-slot:content>
            <span class="marginTop10">{{
              absenceInfo?.reasonType?.label
            }}</span>
          </template>
        </GroupContentComponent>
        <div class="action-group">
          <span class="action-memo">
            ※不在期間設定の解除を忘れた場合でも、３日間連続で普段どおり電気をご使用いただいた場合、<br />
            みまもり状態（正常）に自動復帰します。
          </span>
          <ButtonComponent
            width="100px"
            height="30px"
            color="#8d9caa"
            @click="setEdit(true)"
            >編集</ButtonComponent
          >
        </div>
      </div>
    </CardComponent>
    <Form
      v-else
      @submit="submit"
      v-slot="{ setFieldValue, validateField }"
      :validation-schema="schema"
    >
      <CardComponent class="main" :width="'960px'" :height="'auto'">
        <div class="group" v-if="!!absenceInfo">
          <div class="group_title">不在期間</div>
          <GroupContentComponent class="group-big" :required="true">
            <template v-slot:title> 開始日 </template>
            <template v-slot:content>
              <div class="marginTop10">
                <DateTimeInputGroupComponent
                  :yearOptions="absenceInfo.startOptions.years"
                  :monthOptions="absenceInfo.startOptions.months"
                  :dateOptions="absenceInfo.startOptions.dates"
                  :initYear="absenceInfo?.startYear || 0"
                  :initMonth="absenceInfo?.startMonth || 0"
                  :initDate="absenceInfo?.startDate || 0"
                  yearNameSelect="startYear"
                  monthNameSelect="startMonth"
                  dateNameSelect="startDate"
                  :setFieldValue="setFieldValue"
                  :minDateSearch="absenceInfo?.startRange?.start"
                  :maxDateSearch="absenceInfo?.startRange?.end"
                  :disabled="absenceInfo?.disabledStartSelect"
                  :disabledList="[]"
                  :validateField="validateField"
                  :isDisplayTime="false"
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-big">
            <template v-slot:title> 終了日 </template>
            <template v-slot:content>
              <div class="marginTop10">
                <DateTimeInputGroupComponent
                  :yearOptions="absenceInfo.endOptions.years"
                  :monthOptions="absenceInfo.endOptions.months"
                  :dateOptions="absenceInfo.endOptions.dates"
                  :initYear="absenceInfo?.endYear || 0"
                  :initMonth="absenceInfo?.endMonth || 0"
                  :initDate="absenceInfo?.endDate || 0"
                  yearNameSelect="endYear"
                  monthNameSelect="endMonth"
                  dateNameSelect="endDate"
                  :setFieldValue="setFieldValue"
                  :minDateSearch="absenceInfo?.endRange?.start"
                  :maxDateSearch="absenceInfo?.endRange?.end"
                  :disabled="false"
                  :disabledList="[]"
                  :validateField="validateField"
                  :isDisplayTime="false"
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-big" :required="true">
            <template v-slot:title> 不在理由 </template>
            <template v-slot:content>
              <div class="input-group marginTop10">
                <InputComponent
                  v-for="(item, index) of absenceInfo.absencesOptions"
                  :key="item.value"
                  type="radio"
                  :id="`absenceType_${item.value}`"
                  name="absenceType"
                  :defaultValue="absenceInfo?.reasonType?.value || 4"
                  :value="item.value"
                  :label="item.label"
                  :showErrorMessage="index === 0"
                  @trigle-change="errorDetail = null"
                />
              </div>
            </template>
          </GroupContentComponent>
          <div class="memo">
            <div class="memo-notice">
              不在期間中はサービスが一時停止となります
            </div>
          </div>
          <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
          <div class="action-group-flex">
            <ButtonComponent
              width="150px"
              height="50px"
              type="outline"
              buttonType="Button"
              @click="setEdit(false)"
              >キャンセル</ButtonComponent
            >
            <ButtonComponent
              buttonType="submit"
              width="150px"
              height="50px"
              class="btn-submit"
              >設定</ButtonComponent
            >
          </div>
        </div>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import AbsenceService from "@/services/absence.service";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import { mapState } from "vuex";
import { STATUS } from "@/globals/enums";
import { Form } from "vee-validate";
import { absenceRegistrationSchema } from "@/validations/schemas/absenceRegistrationSchema";

export default {
  name: "AbsenceRegistrationDetailComponent",
  components: {
    Form,
  },
  computed: {
    ...mapState({
      mimamoriTargetPersonId: (state) =>
        state.global?.globalData?.mimamoriTargetPersonId,
    }),
    schema() {
      return absenceRegistrationSchema(this.absenceInfo?.disabledStartSelect);
    },
  },
  data: () => ({
    absenceService: new AbsenceService(),
    absenceInfo: null,
    isEdit: false,
    confirmDialogService: new ConfirmDialogService(),
    errorDetail: null,
  }),
  methods: {
    goTo(component) {
      this.$router.push({ name: component });
    },
    async getPeriodAbsence(id) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.absenceService.getPeriodAbsence(id);
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result.status === STATUS.SUCCESS) {
        this.absenceInfo = result.data;
      }
      if (result.status === STATUS.ERROR && result.response?.showModal) {
        this.confirmDialogService.openConfirmDialog(
          result?.response?.title,
          result?.response?.detail
        );
      }
    },
    setEdit(edit) {
      this.$router.replace({ query: null });
      this.isEdit = edit;
    },

    createPeriodAbsence(data, callback) {
      this.absenceService
        .createPeriodAbsence(this.mimamoriTargetPersonId, {
          ...data,
          disabledStartSelect: this.absenceInfo?.disabledStartSelect,
        })
        .then((resultCreate) => {
          if (resultCreate.status === STATUS.SUCCESS) {
            callback();
            return;
          }
          if (
            resultCreate.status === STATUS.ERROR &&
            resultCreate.response?.detail
          ) {
            this.errorDetail = resultCreate.response?.detail;
            return;
          }
        });
    },

    editPeriodAbsence(data, callback) {
      const idType = {
        mimamoriTargetPersonId: this.mimamoriTargetPersonId,
        calenderId: this.absenceInfo.calenderId,
      };
      this.absenceService
        .updatePeriodAbsence(idType, {
          ...data,
          disabledStartSelect: this.absenceInfo?.disabledStartSelect,
        })
        .then((resultCreate) => {
          if (resultCreate.status === STATUS.SUCCESS) {
            callback();
            return;
          }
          if (
            resultCreate.status === STATUS.ERROR &&
            resultCreate.response?.detail
          ) {
            this.errorDetail = resultCreate.response?.detail;
            return;
          }
        });
    },

    onConfirmDialog() {
      this.confirmDialogService.openConfirmDialog(
        "不在期間を設定しました。",
        "",
        async () => {
          await this.getPeriodAbsence(this.mimamoriTargetPersonId);
          this.isEdit = false;
        }
      );
    },

    submit(data) {
      if (this.absenceInfo.calenderId) {
        this.editPeriodAbsence(data, this.onConfirmDialog);
        return;
      }
      this.createPeriodAbsence(data, this.onConfirmDialog);
    },
  },
  mounted() {
    !this.mimamoriTargetPersonId &&
      this.$store.dispatch("global/updateLoadingStatus", true);
    if (this.mimamoriTargetPersonId && !this.absenceInfo) {
      this.getPeriodAbsence(this.mimamoriTargetPersonId);
    }
    this.isEdit = this.$route.query.isEdit;
  },
  watch: {
    mimamoriTargetPersonId: function (val) {
      if (val && !this.absenceInfo) this.getPeriodAbsence(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.AbsenceRegistrationDetailComponent {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: var(--default-distance-card);
  &-title {
    width: 960px;
    margin: 0 auto;
    display: flex;
    color: #000000 !important;
    .icon-ico_user::before {
      color: #000000;
    }
  }
  .main {
    padding: 30px 40px;
    .flex {
      display: flex;
      align-items: center;
    }
    &_title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      font-weight: 500;
    }
    &_userInfo {
      padding: 13px 0 30px;
      margin-left: 21px;
      display: flex;
      flex-direction: column;
      gap: 7px;
      .fullName {
        font-size: 20px;
        font-weight: 500;
        gap: 13px;
        margin-right: 12px;
        &-small {
          font-size: 14px;
          gap: 10px;
        }
      }
      .address {
        gap: 19px;
      }
    }
    .group {
      &_title {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5px;
        border-bottom: solid 2px #d0d9df;
      }
      .telephone {
        font-size: 24px;
        font-weight: 500;
      }
      .status {
        margin-left: auto;
        font-size: 12px;
        width: 99px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .orange {
        color: #eba447;
        border: solid 1px #eba447;
      }
      .blue {
        color: #328dc2;
        border: solid 1px #328dc2;
      }
      &-big {
        height: 100px;
        .input-group {
          display: flex;
          gap: 30px;
          .InputComponent {
            margin-bottom: 0;
          }
        }
      }
      &-normal {
        height: 60px;
      }
      .action-group {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .action-memo {
          font-size: 12px;
          font-weight: normal;
          color: #7c8185;
        }
        &-flex {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    &_bottom {
      margin: 30px 0 40px;
    }
    .fullName-small {
      gap: 10px;
    }
    .memo {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      margin: 40px 0px 31px;
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .AbsenceRegistrationDetailComponent {
    height: 100%;
    margin: 0;
    &-title {
      width: 300px;
      margin-left: 20px;
    }
    form {
      height: 100%;
      width: 100%;
    }
    .main {
      width: 100% !important;
      height: 100% !important;
      padding: 30px 20px;
      .group-normal {
        height: 107px;
      }
      .group-big {
        height: auto;
      }
      .input-group {
        gap: 0 !important;
        flex-direction: column;
        .InputComponent {
          margin-bottom: 15px !important;
        }
      }
      .marginTop10 {
        margin-top: 10px;
      }
      .group {
        .memo {
          margin: 38px auto 31px;
          &-notice {
            width: 220px;
            margin: auto;
          }
        }
        .action-group {
          display: block;
          text-align: center;
          .action-memo {
            display: none;
          }
          &-flex {
            flex-direction: column-reverse;
            gap: 0;
            .btn-submit {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
