<template>
  <router-view />
</template>

<script>
export default {
  name: "MimamoriTargetComponent",
};
</script>
<style scoped lang="scss"></style>
