export const convertYearMonthDateToDate = (year, month, day) => {
  const result = new Date(year, month, day, 0, 0, 0, 0);
  if (
    result.getFullYear() != year ||
    result.getMonth() != month ||
    result.getDate() != day
  ) {
    return null;
  }
  return result;
};

export const convertDateToYearMonthDate = (stringDate) => {
  const date = new Date(stringDate);
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    date: date.getDate(),
  };
};
