const state = {
  loadingStatus: false,
  globalData: {},
};

const getters = {
  loadingStatus(state) {
    return state.loadingStatus;
  },
  globalData(state) {
    return state.globalData;
  },
};

const actions = {
  updateLoadingStatus({ commit }, data) {
    commit("loadingStatus", data);
  },
  updateGlobalData({ commit }, data) {
    commit("globalData", data);
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  loadingStatus(state, newLoadingStatus) {
    state.loadingStatus = newLoadingStatus;
  },
  globalData(state, data) {
    state.globalData = data;
  },
  resetState(state) {
    state.globalData = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
