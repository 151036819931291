import { FALSY_VALUE } from "@/globals/enums";
import dayjs from "dayjs";

export const formatDateTimeJanpanese = (el, binding) => {
  const isFalsyValue =
    binding.value ===
    `${FALSY_VALUE.YEAR}-${FALSY_VALUE.MONTH}-${FALSY_VALUE.DAY}`;

  if (!binding.value || isFalsyValue || !dayjs(binding.value).isValid()) {
    el.innerText = "ー";
    return;
  }

  const year = dayjs(binding.value).get("year");
  const month = dayjs(binding.value).get("month") + 1;
  const date = dayjs(binding.value).get("date");
  el.innerText = `${year}年${month}月${date}日`;
};
