import { STATUS } from "@/globals/enums";
import {
  getAnalysis,
  getAnalysisInfo,
  updateSafety,
} from "./api/analysis.service";
import {
  convertAnalysis,
  convertMimamoriInfo,
  convertSafetyCheckHistory,
  convertBodyGetAnalysis,
} from "@/store/converter/analysis.converter";

export default class AnalysisService {
  async getAnalysis(body, pagination, sort) {
    try {
      const result = await getAnalysis(
        convertBodyGetAnalysis(body, pagination, sort)
      );
      return {
        status: STATUS.SUCCESS,
        data: {
          total: result.total,
          page: result.page,
          totalPage: result.total_page || 0,
          analysisResultList: result.analysis_result_list.map((analysis) => {
            return convertAnalysis(analysis);
          }),
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async getAnalysisInfo(body) {
    try {
      const result = await getAnalysisInfo(body);
      return {
        status: STATUS.SUCCESS,
        data: {
          mimamoriInfo: convertMimamoriInfo(result.mimamori_info),
          safetyCheckHistory:
            result.safety_check_history_list.length > 0
              ? convertSafetyCheckHistory(result.safety_check_history_list[0])
              : {
                  safetyCheckUpdate: "ー",
                  safetyCheckResult: "ー",
                  safetyCheckPerson: "ー",
                  safetyCheckId: null,
                },
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async editSafety(id, body) {
    try {
      const dataBody = {
        mimamori_target_person_id: body.mimamoriTargetPersonId,
        safety_check_result: body.analysisResultId,
      };
      await updateSafety(id, dataBody);
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        response: {
          ...error.response,
          ...(error?.response?.code === "CUSTOM-REGISTERED_SAFETY" && {
            detail:
              "状況の確認結果を登録済みです。登録内容を変更することはできません。",
          }),
          ...(error?.response?.code === "CUSTOM-CANNOT_REGISTERED" && {
            detail:
              "状況の確認結果を登録することができませんでした。暫くたってから再度お試しください。",
          }),
        },
      };
    }
  }
}
