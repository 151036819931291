<template>
  <div
    class="HeaderComponent"
    v-if="isHeader"
    :class="{ 'HeaderComponent-hasBackground': hasMenu }"
  >
    <div class="HeaderComponent__titleGroup">
      <img
        :src="$domain.HEADER_LOGO.src"
        :width="$domain.HEADER_LOGO.width"
        :height="$domain.HEADER_LOGO.height"
        :alt="$domain.HEADER_LOGO.alt"
        class="logo"
        @click="goToHome"
      />
    </div>
    <div v-if="hasMenu" class="HeaderComponent__userMenu d-flex">
      <span class="text d-flex align-baseline">
        <div class="text-name">
          {{
            (homeData?.mimamoriInfo?.lastNameKanji || "") +
            "&emsp;" +
            (homeData?.mimamoriInfo?.firstNameKanji || "")
          }}
        </div>
        <span
          v-if="
            homeData?.mimamoriInfo?.lastNameKanji ||
            homeData?.mimamoriInfo?.firstNameKanji
          "
          class="text-sama"
          >様</span
        >
      </span>
      <p class="HeaderComponent__userMenu_user">
        <button class="btn-openMenu" @click="openNav">
          <span class="icon-ico_menu"></span>
        </button>
      </p>
    </div>
  </div>

  <!-- side navigation start -->
  <div ref="backgroundMenu" class="background-menu">
    <div ref="mySidenav" class="sidenav">
      <p class="closebtn" @click="closeNav">
        <span class="icon-ico_menu_close"></span>
      </p>
      <div class="content-menu">
        <h3 class="title">トップページ</h3>
        <a @click="navigatePath('HomeComponent')">
          <span class="icon-ico_menu_arrow">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
          TOP</a
        >
      </div>
      <div class="content-menu">
        <h3 class="title">設定</h3>
        <ul>
          <li>
            <a @click="navigatePath('ProfileDetailComponent')">
              プロフィール情報
            </a>
          </li>
          <li>
            <a @click="navigatePath('AlertPreviewComponent')"
              >アラート通知先・条件設定</a
            >
          </li>
          <li>
            <a @click="navigatePath('AbsenceRegistrationDetailComponent')"
              >不在期間設定</a
            >
          </li>
          <li>
            <a @click="navigatePath('ReConfigMFAComponent')"
              >セキュリティ設定</a
            >
          </li>
          <li>
            <a @click="navigatePath('UpdateRelativesEmailComponent')">
              メールアドレスログイン設定
            </a>
          </li>
          <li>
            <a @click="navigatePath('ChangePasswordComponent')">
              パスワード設定
            </a>
          </li>
        </ul>
      </div>
      <div class="content-menu">
        <h3 class="title">正常・異常判定</h3>
        <ul>
          <li>
            <a @click="navigatePath('MimamoriHistoryComponent')"
              >正常・異常判定一覧</a
            >
          </li>
        </ul>
      </div>
      <div class="content-menu">
        <a href="/help" target="_blank" class="content-help">
          <div class="content-help-icon">
            <img
              :src="require('@/assets/images/qms/icon-menu.svg')"
              alt=""
              srcset=""
            />
            ご利用方法
          </div>
          <p>&#8594;</p>
        </a>
      </div>
      <div class="content-menu">
        <h3 class="title title_border-none">
          <a @click="logout"
            ><span class="icon-ico_logout"></span> ログアウト</a
          >
        </h3>
      </div>
    </div>
  </div>
  <!-- side navigation end -->
</template>

<script>
import LoginService from "@/services/login.service";
import WarningDialogService from "@/services/dialog/warning-dialog.service";
import { mapState } from "vuex";

export default {
  name: "HeaderComponent",
  computed: {
    hasMenu() {
      return !(this.$route.meta && this.$route.meta.authPage);
    },
    isHeader() {
      return this.$route.path !== "/login";
    },
    ...mapState({
      homeData: (state) => state.global.globalData,
    }),
  },
  data() {
    return {
      loginService: new LoginService(),
      warningDialogService: new WarningDialogService(),
    };
  },
  methods: {
    openNav() {
      this.$refs.mySidenav.style.width = "300px";
      this.$refs.mySidenav.style.overflowY = "scroll";
      this.$refs.backgroundMenu.style.width = "100%";
      document.body.classList.add("body-custom");
    },
    closeNav() {
      this.$refs.mySidenav.style.width = "0";
      this.$refs.mySidenav.style.overflowY = "hidden";
      this.$refs.backgroundMenu.style.width = "0";
      document.body.classList.remove("body-custom");
    },
    navigatePath(name) {
      this.$router.push({ name });
      this.closeNav();
    },
    goToHome() {
      if (this.hasMenu) {
        this.$router.push({ name: "HomeComponent" });
      } else {
        // #69453
        // The logo on screens that do not have menus
        // should not respond to clicks.
      }
    },
    onCloseConfirm() {
      this.closeNav();
      this.loginService.logout();
    },
    logout() {
      const title = "ログアウト";
      const content = "ログアウトしますか";
      this.warningDialogService.openWarningDialog(
        title,
        content,
        null,
        this.onCloseConfirm
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.HeaderComponent {
  width: 100%;
  height: 92px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__titleGroup {
    margin-top: 12px;
    > .logo {
      vertical-align: bottom;
    }
  }
  &-hasBackground &__titleGroup {
    // #69453
    // The logo on screens that do not have menus
    // should not respond to clicks.
    > .logo {
      cursor: pointer;
    }
  }

  &__userMenu {
    font-size: 14px;
    color: #7c8185;
    .text {
      margin: 20px 50px 0 0;
      &-sama {
        font-size: 11px;
        margin-left: 5px;
      }
    }
    &_user {
      .text {
        margin-left: 20px;
        font-weight: 500;
      }
    }
  }
}

.btn-openMenu {
  cursor: pointer;
  font-size: 20px;
  padding: 0 15px 0 30px;
  margin-bottom: 15px;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  background-color: var(--sidenav-bgcolor);
  overflow-x: hidden;
  transition: 0.5s;

  .closebtn {
    margin: 15px 40px;
    text-align: right;
    font-size: 20px;
    width: 224px;
    cursor: pointer;
  }

  .content-menu {
    margin: 0 47px 35px 53px;
    min-width: 200px;
    .icon-ico_menu_arrow {
      margin-right: 5px;
    }
    .title {
      min-width: 200px;
      border-bottom: 1px solid #8d9caa;
      padding-bottom: 0.2em;
      margin-bottom: 0.8em;
      font-weight: 500;

      // Modifiers
      &_border-none {
        border-bottom: none;
      }
    }
    a {
      color: var(--color-black-text);
      text-decoration: none;
      display: block;
    }
    ul {
      li {
        list-style: none;
        margin: 12px 0 12px 1em;
        font-weight: 500;
      }
    }
    .content-help {
      width: 200px;
      height: 50px;
      background-color: #8d9caa;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px 0 5px;
      &-icon {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
    }
  }
}

.background-menu {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 99;
  background-color: #ffffff87;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media only screen and (max-width: $mobile-breakpoint) {
  .HeaderComponent {
    padding: 0 20px;
    background-color: var(--default-color);
    height: 60px;
    .logo {
      width: $header-logo-mobile-width;
      height: $header-logo-mobile-height;
    }
    &__titleGroup {
      margin: 0;
    }
    &__userMenu {
      font-size: 14px;
      color: #7c8185;
      align-items: center;
      .text {
        margin: 0;
        font-size: 12px;
        &-name {
          max-width: 80px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
        &-sama {
          font-size: unset;
          margin: unset;
          margin-left: 3px;
        }
      }
      .btn-openMenu {
        margin: 0;
        padding: 0 10px 0 35px;
      }
    }
  }
}
</style>
