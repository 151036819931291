<template>
  <div v-if="analysisResultCode === ANALYSIS_RESULT_CODE.NORMAL.VALUE">
    <StatusChipComponent v-if="confirmStatus === 2" type="confirming">
      確認中
    </StatusChipComponent>
    <StatusChipComponent v-if="confirmStatus === 3" type="confirmed">
      確認済
    </StatusChipComponent>
  </div>

  <div
    v-if="
      alertFlag && analysisResultCode === ANALYSIS_RESULT_CODE.ABNORMAL.VALUE
    "
  >
    <StatusChipComponent v-if="confirmStatus === 1" type="unconfirmed">
      未確認
    </StatusChipComponent>
    <StatusChipComponent v-if="confirmStatus === 2" type="confirming">
      確認中
    </StatusChipComponent>
    <StatusChipComponent v-if="confirmStatus === 3" type="confirmed">
      確認済
    </StatusChipComponent>
  </div>

  <div
    v-if="
      !alertFlag && analysisResultCode === ANALYSIS_RESULT_CODE.ABNORMAL.VALUE
    "
  >
    <StatusChipComponent v-if="confirmStatus === 2" type="confirming">
      確認中
    </StatusChipComponent>
    <StatusChipComponent v-if="confirmStatus === 3" type="confirmed">
      確認済
    </StatusChipComponent>
  </div>

  <div v-if="analysisResultCode === ANALYSIS_RESULT_CODE.ERROR.VALUE">
    <StatusChipComponent v-if="confirmStatus === 2" type="confirming">
      確認中
    </StatusChipComponent>
    <StatusChipComponent v-if="confirmStatus === 3" type="confirmed">
      確認済
    </StatusChipComponent>
  </div>
</template>

<script>
import { ANALYSIS_RESULT_CODE, CONFIRM_STATUS } from "@/globals/enums";
export default {
  name: "AnalysisLabelComponent",
  created() {
    this.ANALYSIS_RESULT_CODE = ANALYSIS_RESULT_CODE;
    this.CONFIRM_STATUS = CONFIRM_STATUS;
  },
  props: {
    analysisResultCode: {
      type: String,
      default: "",
    },
    confirmStatus: {
      type: Number,
      default: 0,
    },
    alertFlag: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
