import { STATUS, WEB_STORAGE_KEY } from "@/globals/enums";
import store from "./../store/index";
import { convertDataHome } from "@/store/converter/home.converter";
import { getHomeDetail } from "./api/home.service";
import WebStorageService from "./web-storage.service";

const webStorageService = new WebStorageService();
export default class HomeService {
  async getHome() {
    const token = webStorageService.getLocalStorage(WEB_STORAGE_KEY.ID_TOKEN);
    if (!token) {
      return;
    }
    try {
      const result = await getHomeDetail();
      await store.dispatch("global/updateGlobalData", convertDataHome(result));
      return {
        status: STATUS.SUCCESS,
        data: {
          // fake to 2 to change router
          serviceStatus: result.mimamori_info.service_status,
          relativesEmail: result.relatives_account_info.relatives_email,
          mimamoriTargetPersonId: result.mimamori_target_person_id,
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
      };
    }
  }
}
