<template>
  <div class="EditMimamoriComponent">
    <TitleComponent class="EditMimamoriComponent-title">
      <template v-slot:icon>
        <i class="icon icon-service_profile"></i>
      </template>
      <template v-slot:content>状況確認結果登録</template>
    </TitleComponent>
    <CardComponent
      class="record-table-card"
      v-if="analysis"
      :width="'960px'"
      :height="'auto'"
    >
      <section class="mimamori-record-group">
        <h4 class="mimamori-record-group__header">アラート発報</h4>
        <table class="mimamori-record-group__main table-container">
          <tr>
            <TableHeaderComponent :alignStart="true">日付</TableHeaderComponent>
            <TableHeaderComponent :alignStart="true">
              判定結果
            </TableHeaderComponent>
            <TableHeaderComponent :alignStart="true">
              アラート発報
            </TableHeaderComponent>
            <TableHeaderComponent>確認日</TableHeaderComponent>
            <TableHeaderComponent>確認結果</TableHeaderComponent>
            <TableHeaderComponent>確認者</TableHeaderComponent>
            <TableHeaderComponent></TableHeaderComponent>
          </tr>
          <tbody class="table-row-group">
            <TableRowComponent
              :class="{ 'highlight-row': highlightAnalysis(analysis) }"
            >
              <TableCellComponent>
                <span
                  class="content-cell"
                  v-formatDateTimeJanpanese="analysis?.analysisDate"
                ></span>
              </TableCellComponent>
              <TableCellComponent>
                <AnalysisStatusComponent
                  :pauseStart="!!analysis?.pauseStart"
                  :analysisResultCode="analysis?.analysisResultCode"
                  :confirmStatus="analysis?.confirmStatus"
                  :alertFlag="analysis?.alertFlag"
                />
              </TableCellComponent>
              <TableCellComponent>
                <span
                  class="content-cell"
                  :class="{ 'highlight-text': highlightAnalysis(analysis) }"
                >
                  {{ analysis?.alertFlag ? "アラートが発報されました" : "" }}
                </span>
              </TableCellComponent>
              <TableCellComponent class="content-algin-center">
                <span
                  class="content-cell"
                  v-formatDateTimeJanpanese="
                    safetyCheckHistory?.safetyCheckUpdate
                  "
                ></span>
              </TableCellComponent>
              <TableCellComponent class="content-algin-center">
                <span class="content-cell">
                  {{ safetyCheckHistory?.safetyCheckResult }}
                </span>
              </TableCellComponent>
              <TableCellComponent class="content-algin-center">
                <span class="content-cell">
                  {{ safetyCheckHistory?.safetyCheckPerson }}
                </span>
              </TableCellComponent>
              <TableCellComponent class="content-algin-center">
                <AnalysisLabelComponent
                  :analysisResultCode="analysis?.analysisResultCode"
                  :confirmStatus="analysis?.confirmStatus"
                  :alertFlag="analysis?.alertFlag"
                />
              </TableCellComponent>
            </TableRowComponent>
          </tbody>
        </table>
        <table class="mimamori-record-group__main-mobile table-container">
          <tbody class="table-row-group">
            <TableRowComponent
              :class="{ 'highlight-row': highlightAnalysis(analysis) }"
            >
              <TableCellComponent>
                <div class="d-flex flex-column justify-center color-content">
                  <p
                    class="message-content content-cell"
                    :class="{ 'mb-5': analysis.alertFlag }"
                    v-formatDateTimeJanpanese="analysis?.analysisDate"
                  ></p>
                  <p
                    class="message-content content-cell"
                    :class="{ 'highlight-text': highlightAnalysis(analysis) }"
                    v-if="analysis.alertFlag"
                  >
                    アラートが発報されました
                  </p>
                  <div class="mt-5">
                    <p
                      class="content-safety mb-2"
                      v-if="safetyCheckHistory.safetyCheckUpdate !== 'ー'"
                    >
                      <span class="content-cell small-content">確認日</span>
                      <span
                        class="content-cell"
                        v-formatDateTimeJanpanese="
                          safetyCheckHistory.safetyCheckUpdate
                        "
                      ></span>
                    </p>
                    <p
                      class="content-safety mb-2"
                      v-if="safetyCheckHistory.safetyCheckResult !== 'ー'"
                    >
                      <span class="content-cell small-content">確認結果</span>
                      <span class="content-cell">
                        {{ safetyCheckHistory.safetyCheckResult }}
                      </span>
                    </p>
                    <p
                      class="content-safety mb-2"
                      v-if="safetyCheckHistory.safetyCheckPerson !== 'ー'"
                    >
                      <span class="content-cell small-content">確認者</span>
                      <span class="content-cell">
                        {{ safetyCheckHistory.safetyCheckPerson }}
                      </span>
                    </p>
                  </div>
                </div>
              </TableCellComponent>
              <TableCellComponent class="content-label">
                <div
                  class="d-flex flex-column justify-center align-center content-status"
                >
                  <AnalysisStatusComponent
                    class="mb-2"
                    :pauseStart="!!analysis.pauseStart"
                    :analysisResultCode="analysis.analysisResultCode"
                    :confirmStatus="analysis.confirmStatus"
                    :alertFlag="analysis.alertFlag"
                  />
                  <AnalysisLabelComponent
                    :analysisResultCode="analysis.analysisResultCode"
                    :confirmStatus="analysis.confirmStatus"
                    :alertFlag="analysis.alertFlag"
                  />
                </div>
              </TableCellComponent>
            </TableRowComponent>
          </tbody>
        </table>
      </section>
      <section class="mimamori-form">
        <Form
          @submit="onUpdateAnalysis"
          :validation-schema="schema"
          v-slot="{ meta }"
          v-if="isLoaded"
        >
          <h3
            class="mimamori-form-title"
            v-if="analysis && analysis.confirmStatus !== 3"
          >
            状況を確認して結果を登録してください
          </h3>
          <div
            class="mimamori-form-content"
            v-if="analysis && analysis.confirmStatus !== 3"
          >
            <InputComponent
              type="radio"
              name="checkboxStatus"
              id="checkboxStatus_1"
              label="普段どおり生活中"
              :defaultValue="
                safetyCheckHistory?.safetyCheckId ||
                STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT
              "
              @trigle-change="errorDetail = null"
              :value="2"
            />
            <InputComponent
              type="radio"
              name="checkboxStatus"
              id="checkboxStatus_2"
              label="外出・旅行"
              :defaultValue="
                safetyCheckHistory?.safetyCheckId ||
                STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT
              "
              @trigle-change="errorDetail = null"
              :value="3"
            />
            <InputComponent
              type="radio"
              name="checkboxStatus"
              id="checkboxStatus_3"
              label="入院"
              :defaultValue="
                safetyCheckHistory?.safetyCheckId ||
                STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT
              "
              @trigle-change="errorDetail = null"
              :value="5"
            />
            <InputComponent
              type="radio"
              name="checkboxStatus"
              id="checkboxStatus_4"
              label="退院"
              :defaultValue="
                safetyCheckHistory?.safetyCheckId ||
                STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT
              "
              @trigle-change="errorDetail = null"
              :value="6"
            />
            <InputComponent
              type="radio"
              name="checkboxStatus"
              id="checkboxStatus_5"
              label="ご逝去"
              :defaultValue="
                safetyCheckHistory?.safetyCheckId ||
                STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT
              "
              @trigle-change="errorDetail = null"
              :value="4"
            />
            <InputComponent
              type="radio"
              name="checkboxStatus"
              id="checkboxStatus_6"
              label="確認中"
              :defaultValue="
                safetyCheckHistory?.safetyCheckId ||
                STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT
              "
              @trigle-change="errorDetail = null"
              :value="7"
            />
          </div>
          <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
          <div class="mimamori-form-action">
            <ButtonComponent
              buttonType="button"
              @click="onBack($event)"
              type="outline"
            >
              {{
                analysis && analysis.confirmStatus !== 3
                  ? "キャンセル"
                  : "もどる"
              }}
            </ButtonComponent>
            <ButtonComponent
              buttonType="submit"
              v-if="analysis && analysis.confirmStatus !== 3"
              :disabled="!meta.valid"
            >
              登録
            </ButtonComponent>
          </div>
        </Form>
      </section>
    </CardComponent>
  </div>
</template>

<script>
import AnalysisService from "@/services/analysis.service";
import {
  STATUS,
  ANALYSIS_RESULT_CODE,
  CONFIRM_STATUS,
  STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT,
} from "@/globals/enums";
import { Form } from "vee-validate";
import { editMimamoriSchema } from "@/validations/schemas/editMimamoriSchema";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import WarningDialogService from "@/services/dialog/warning-dialog.service";
export default {
  name: "EditMimamoriComponent",
  components: {
    Form,
  },
  created() {
    this.STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT =
      STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT;
  },
  data: () => ({
    analysisService: new AnalysisService(),
    confirmDialogService: new ConfirmDialogService(),
    warningDialogService: new WarningDialogService(),
    analysis: null,
    safetyCheckHistory: null,
    schema: editMimamoriSchema,
    errorDetail: null,
    isLoaded: false,
  }),
  mounted() {
    this.getAnalysisInfo();
  },
  methods: {
    getAnalysisInfo() {
      const body = {
        mimamoriTargetPersonId: this.$route.query.mimamoriTargetPersonId,
        analysisResultId: this.$route.params.analysisResultId,
      };
      this.$store.dispatch("global/updateLoadingStatus", true);
      this.analysisService.getAnalysisInfo(body).then((analysisInfo) => {
        this.$store.dispatch("global/updateLoadingStatus", false);
        if (analysisInfo.status === STATUS.SUCCESS) {
          this.analysis = analysisInfo.data.mimamoriInfo;
          this.safetyCheckHistory = analysisInfo.data.safetyCheckHistory;
        }
        this.isLoaded = true;
      });
    },
    async updateSafety(analysisResultId, isNormal) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const resultUpdate = await this.analysisService.editSafety(
        this.$route.params.analysisResultId,
        {
          mimamoriTargetPersonId: this.$route.query.mimamoriTargetPersonId,
          analysisResultId,
        }
      );
      if (resultUpdate.status === STATUS.SUCCESS) {
        if (
          (analysisResultId === 3 || analysisResultId === 5) &&
          this.analysis.latestDataFlg
        ) {
          this.onGotoAbsence();
          return resultUpdate;
        }
        this.onConfirmDialog();
      }
      if (resultUpdate.status === STATUS.ERROR) {
        if (isNormal && resultUpdate?.response?.detail)
          this.errorDetail = resultUpdate.response.detail;
      }
      this.$store.dispatch("global/updateLoadingStatus", false);
      return resultUpdate;
    },

    onBack(e) {
      e.preventDefault();
      this.$router.go(-1);
    },

    highlightAnalysis(analysis) {
      return (
        analysis.analysisResultCode === ANALYSIS_RESULT_CODE.ABNORMAL.VALUE &&
        (analysis.confirmStatus === CONFIRM_STATUS.UNCONFIRMED.VALUE ||
          analysis.confirmStatus === CONFIRM_STATUS.CHECKING.VALUE) &&
        analysis.alertFlag
      );
    },

    onUpdateAnalysis(data) {
      const checkboxStatus = data.checkboxStatus;
      if (
        checkboxStatus === 2 ||
        checkboxStatus === 6 ||
        checkboxStatus === 7
      ) {
        this.updateSafety(checkboxStatus, true);
        return;
      }
      switch (checkboxStatus) {
        case 3:
        case 5:
          if (this.analysis?.latestDataFlg) {
            this.onConfirmEditAnalysis(checkboxStatus);
          } else {
            this.updateSafety(checkboxStatus, true);
          }
          break;
        case 4:
          this.onWarningDialog(checkboxStatus);
          break;

        default:
          break;
      }
    },

    onWarningDialog(analysisResultId) {
      this.warningDialogService.openWarningAutoCallDialog(
        "本サービスのご利用ができなくなります。",
        "本当に登録してよろしいですか？",
        null,
        async () => await this.updateSafety(analysisResultId)
      );
    },

    onConfirmEditAnalysis(analysisResultId) {
      this.confirmDialogService.openConfirmEditAnalysis(
        null,
        async () => await this.updateSafety(analysisResultId)
      );
    },

    onConfirmDialog() {
      if (this.$vfm.modals.length !== 0) {
        this.$vfm.hideAll();
      }
      this.confirmDialogService.openConfirmDialog(
        "状況確認結果を登録しました。",
        null,
        () => this.getAnalysisInfo()
      );
    },

    onGotoAbsence() {
      if (this.$vfm.modals.length !== 0) {
        this.$vfm.hideAll();
      }
      this.confirmDialogService.openConfirmDialog(
        "状況確認結果を登録しました。",
        null,
        () =>
          this.$router.push({
            name: "AbsenceRegistrationDetailComponent",
            query: { isEdit: true },
          })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.EditMimamoriComponent {
  &-title {
    display: flex;
    width: 960px;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    margin: auto;
    // TODO: Refactoring
    .icon {
      line-height: 1.5;
      margin-right: 0.25em;
      vertical-align: middle;
    }
  }
  .v-card {
    padding: 30px 40px;
    .mimamori-record-group__main {
      width: 100%;
      &-mobile {
        display: none;
        .content-label {
          vertical-align: top !important;
        }
      }
    }
    .table-row-group {
      .highlight-row {
        background: #fef7f6 !important;
      }
      .content-algin-center {
        text-align: center;
      }
      .TableCellComponent {
        padding: 13px 3px;
        .highlight-text {
          color: #c51f1f !important;
        }
        .content-cell {
          color: #7c8185;
        }
      }
    }
    .mimamori {
      &-form {
        margin-top: 40px;
        &-title {
          width: 100%;
          text-align: center;
          font-size: 20px;
          font-weight: 500;
          color: #000000;
        }
        &-content {
          display: flex;
          justify-content: center;
          margin: 25px 0 40px 0;
          .InputComponent {
            margin: 0 10px;
          }
        }
        &-action {
          display: flex;
          justify-content: center;
          padding-bottom: 120px;
          .ButtonComponent {
            margin: 0 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .EditMimamoriComponent {
    &-title {
      width: 90%;
    }
    .v-card {
      width: 100% !important;
      padding: 30px 20px;
      .mimamori-form-content {
        flex-direction: column;
        margin: 30px auto;
        width: min-content;
        .InputComponent-radio {
          margin-bottom: 10px;
        }
      }
      .mimamori-record-group__main {
        display: none;
        &-mobile {
          display: table;
          width: 100%;

          .TableCellComponent {
            text-align: unset !important;
            vertical-align: middle;
            .content-safety {
              display: grid;
              grid-template-columns: 70px 1fr;
              .small-content {
                font-size: 12px;
              }
            }

            .content-status {
              width: max-content;
            }
          }
        }
      }
      .mimamori-form-action {
        flex-direction: column-reverse;
        align-items: center;
        .ButtonComponent {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
