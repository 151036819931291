const domain = require(`@/__resources/${process.env.VUE_APP_RESOURCE}/domain.json`);

export const WEB_STORAGE_KEY = {
  ID_TOKEN: "idtoken",
  ACCESS_TOKEN: "accesstoken",
  REFRESH_TOKEN: "refreshtoken",
  SERVER_TIME: "server_time",
  CLIENT_TIME: "client_time",
  USER_INFO: "user_info",
  INCOMPLETE_REGISTRATION: "incomplete_registration",
  COMPANY_INFO_VALID: "company_info_valid",
  SEARCH_MIMAMORI: "search_mimamori",
};

export const TYPE_MFA = {
  TOTP: "TOTP",
  SMS: "SMS",
  NOMFA: "NOMFA",
};

export const TYPE_MFA_MAP = {
  SOFTWARE_TOKEN_MFA: "TOTP",
  SMS_MFA: "SMS",
  NO_MFA: "NOMFA",
};

export const STATUS = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  NO_ACTION: "NO_ACTION",
};

export const ANALYSIS_SORT = {
  ASC: "ANALYSIS_DATE_ASC",
  DESC: "ANALYSIS_DATE_DESC",
};

export const PHONE_REGION_START_NUMBER = "+81";

export const NUMBER_REGEX = "[0-9]+";
// for mapping a phone to 3 path
export const PHONE_PARSE = [
  {
    FROM_INDEX: 0,
    TO_INDEX: 3,
    NAME: "firstPathNumbers",
    MAX_LENGTH: "6",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 3,
    TO_INDEX: 7,
    NAME: "secondPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 7,
    TO_INDEX: 11,
    NAME: "thirtPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
];

export const FORTMAT_DATE_TIME = {
  EXPORT_DATETIME: "YYYYMMDDHHmmss",
};

export const MAX_EMAIL_LENGTH = 255;

export const REG_8_CHARACTER = /.{8,}/;
export const MIN_8_CHARACTER = 8;

export const OTP_LENGTH = 6;

export const VALIDATES = {
  HAS_NUMBER: /[0-9]/,
  HAS_DOWNER_CHARACTER: /[a-z]/,
  HAS_UPPER_CHARACTER: /[A-Z]/,
};

export const SPECIAL_STRING = {
  OVER_8_CHARACTERS: "8文字以上",
  NUMBER: "数字",
  UPPER_DOWNER_CHARACTERS: "英字小文字",
};

//支払方法
export const PAYMENT_OPTIONS = [
  {
    NAME: "振込",
    VALUE: 1,
  },
  {
    NAME: "口座振替",
    VALUE: 2,
  },
];

// 都道府県を選択
export const ALL_PREFECTURES = [
  { VALUE: 0, LABEL: "都道府県を選択" },
  { VALUE: "北海道", LABEL: "北海道" },
  { VALUE: "青森県", LABEL: "青森県" },
  { VALUE: "岩手県", LABEL: "岩手県" },
  { VALUE: "宮城県", LABEL: "宮城県" },
  { VALUE: "秋田県", LABEL: "秋田県" },
  { VALUE: "山形県", LABEL: "山形県" },
  { VALUE: "茨城県", LABEL: "茨城県" },
  { VALUE: "栃木県", LABEL: "栃木県" },
  { VALUE: "群馬県", LABEL: "群馬県" },
  { VALUE: "埼玉県", LABEL: "埼玉県" },
  { VALUE: "千葉県", LABEL: "千葉県" },
  { VALUE: "東京都", LABEL: "東京都" },
  { VALUE: "神奈川県", LABEL: "神奈川県" },
  { VALUE: "神奈川県", LABEL: "神奈川県" },
  { VALUE: "新潟県", LABEL: "新潟県" },
  { VALUE: "富山県", LABEL: "富山県" },
  { VALUE: "石川県", LABEL: "石川県" },
  { VALUE: "福井県", LABEL: "福井県" },
  { VALUE: "山梨県", LABEL: "山梨県" },
  { VALUE: "長野県", LABEL: "長野県" },
  { VALUE: "岐阜県", LABEL: "岐阜県" },
  { VALUE: "静岡県", LABEL: "静岡県" },
  { VALUE: "愛知県", LABEL: "愛知県" },
  { VALUE: "三重県", LABEL: "三重県" },
  { VALUE: "滋賀県", LABEL: "滋賀県" },
  { VALUE: "京都府", LABEL: "京都府" },
  { VALUE: "大阪府", LABEL: "大阪府" },
  { VALUE: "兵庫県", LABEL: "兵庫県" },
  { VALUE: "奈良県", LABEL: "奈良県" },
  { VALUE: "奈良県", LABEL: "奈良県" },
  { VALUE: "和歌山県", LABEL: "和歌山県" },
  { VALUE: "鳥取県", LABEL: "鳥取県" },
  { VALUE: "岡山県", LABEL: "岡山県" },
  { VALUE: "広島県", LABEL: "広島県" },
  { VALUE: "山口県", LABEL: "山口県" },
  { VALUE: "徳島県", LABEL: "徳島県" },
  { VALUE: "香川県", LABEL: "香川県" },
  { VALUE: "愛媛県", LABEL: "愛媛県" },
  { VALUE: "高知県", LABEL: "高知県" },
  { VALUE: "福岡県", LABEL: "福岡県" },
  { VALUE: "佐賀県", LABEL: "佐賀県" },
  { VALUE: "長崎県", LABEL: "長崎県" },
  { VALUE: "熊本県", LABEL: "熊本県" },
  { VALUE: "大分県", LABEL: "大分県" },
  { VALUE: "宮崎県", LABEL: "宮崎県" },
  { VALUE: "鹿児島県", LABEL: "鹿児島県" },
  { VALUE: "沖縄県", LABEL: "沖縄県" },
];

export const COMPANY_SETTING_TAB = {
  EDIT: 1,
  PREVIEW: 2,
  DONE: 3,
};

export const MONTHS = [
  { VALUE: 0, NAME: "ー" },
  { VALUE: 1, NAME: "01" },
  { VALUE: 2, NAME: "02" },
  { VALUE: 3, NAME: "03" },
  { VALUE: 4, NAME: "04" },
  { VALUE: 5, NAME: "05" },
  { VALUE: 6, NAME: "06" },
  { VALUE: 7, NAME: "07" },
  { VALUE: 8, NAME: "08" },
  { VALUE: 9, NAME: "09" },
  { VALUE: 10, NAME: "10" },
  { VALUE: 11, NAME: "11" },
  { VALUE: 12, NAME: "12" },
];

export const DATES = [
  { VALUE: 0, NAME: "ー" },
  { VALUE: 1, NAME: "01" },
  { VALUE: 2, NAME: "02" },
  { VALUE: 3, NAME: "03" },
  { VALUE: 4, NAME: "04" },
  { VALUE: 5, NAME: "05" },
  { VALUE: 6, NAME: "06" },
  { VALUE: 7, NAME: "07" },
  { VALUE: 8, NAME: "08" },
  { VALUE: 9, NAME: "09" },
  { VALUE: 10, NAME: "10" },
  { VALUE: 11, NAME: "11" },
  { VALUE: 12, NAME: "12" },
  { VALUE: 13, NAME: "13" },
  { VALUE: 14, NAME: "14" },
  { VALUE: 15, NAME: "15" },
  { VALUE: 16, NAME: "16" },
  { VALUE: 17, NAME: "17" },
  { VALUE: 18, NAME: "18" },
  { VALUE: 19, NAME: "19" },
  { VALUE: 20, NAME: "20" },
  { VALUE: 21, NAME: "21" },
  { VALUE: 22, NAME: "22" },
  { VALUE: 23, NAME: "23" },
  { VALUE: 24, NAME: "24" },
  { VALUE: 25, NAME: "25" },
  { VALUE: 26, NAME: "26" },
  { VALUE: 27, NAME: "27" },
  { VALUE: 28, NAME: "28" },
  { VALUE: 29, NAME: "29" },
  { VALUE: 30, NAME: "30" },
  { VALUE: 31, NAME: "31" },
];

export const SORT_ORDER = {
  ASC: 1,
  DESC: 2,
};

export const ANALYSIS_RESULT_CODE = {
  NORMAL: {
    TEXT: "正常",
    VALUE: "00",
  },
  ABNORMAL: {
    TEXT: "異常",
    VALUE: "10",
  },
  ERROR: {
    TEXT: "エラー",
    VALUE: "90",
  },
};

export const CONFIRM_STATUS = {
  UNCONFIRMED: {
    TEXT: "未確認",
    VALUE: 1,
  },
  CHECKING: {
    TEXT: "確認中",
    VALUE: 2,
  },
  CONFIRMED: {
    TEXT: "確認済",
    VALUE: 3,
  },
};

export const SAFETY_CHECK_PERSON = domain.SAFETY_CHECK_PERSON;

export const SAFETY_CHECK_RESULT = {
  1: "電話応答あり",
  2: "〇",
  3: "外出・旅行",
  4: "ご逝去",
  5: "入院",
  6: "退院",
  7: "確認中",
  8: "正常復帰・状況未確認",
  9: "その他",
};

export const ABSENCE_REASON_TYPE = [
  {
    VALUE: 2,
    LABEL: "外出・旅行",
  },
  {
    VALUE: 1,
    LABEL: "入院",
  },
  {
    VALUE: 3,
    LABEL: "その他",
  },
];

export const PROFILE_STEPS = [
  {
    VALUE: 1,
    LABEL: "プロフィール情報登録",
  },
  {
    VALUE: 2,
    LABEL: "アラート通知先・条件設定",
  },
  {
    VALUE: 3,
    LABEL: "テストメール配信 \n （登録完了）",
  },
];

export const ALERT_DATES_OPTIONS = [
  {
    VALUE: 0,
    LABEL: "ー",
  },
  {
    VALUE: 1,
    LABEL: "1日",
  },
  {
    VALUE: 2,
    LABEL: "2日",
  },
  {
    VALUE: 3,
    LABEL: "3日",
  },
  {
    VALUE: 4,
    LABEL: "4日",
  },
  {
    VALUE: 5,
    LABEL: "5日",
  },
  {
    VALUE: 6,
    LABEL: "6日",
  },
  {
    VALUE: 7,
    LABEL: "7日",
  },
  {
    VALUE: 8,
    LABEL: "8日",
  },
  {
    VALUE: 9,
    LABEL: "9日",
  },
];

export const PHONE_FIRST_3_DIGITS = [
  {
    VALUE: "",
    LABEL: "",
  },
  {
    VALUE: "070",
    LABEL: "070",
  },
  {
    VALUE: "080",
    LABEL: "080",
  },
  {
    VALUE: "090",
    LABEL: "090",
  },
];

export const ALERT_MAIL_ACTIVE = [
  {
    VALUE: true,
    LABEL: "有効",
    TYPE: "confirmed",
    CLASS: "mail-activation_valid",
  },
  {
    VALUE: false,
    LABEL: "無効",
    TYPE: "unconfirmed",
    CLASS: "mail-activation_invalid",
  },
  {
    VALUE: undefined,
    LABEL: "",
    TYPE: "",
    CLASS: "mail-activation_empty",
  },
];

export const VALIDATE_DATE_TIME_TYPE = {
  NOT_SETTING_YET: 1,
  START_DATE_IS_FUTURE: 2,
  START_DATE_IS_PAST: 3,
};

export const START_RANGE_TIME = {
  YEAR: 2021,
  MONTH: 5,
  DAY: 1,
};

export const FALSY_VALUE = {
  YEAR: 9999,
  MONTH: 12,
  DAY: 31,
};

export const TOTAL_VISIBLE = 7;

export const STATUS_CONFIRM_RESULT_REGISTRATION_DEFAULT = -1;

export const REGEX_5XX = /^5\d{2}$/;

export const REGEX_4XX = /^4\d{2}$/;

/**
 * ATTENTION:
 * --------------------
 * Some regular expressions have the global flag (/g) at the end of line.
 *
 * Since they have the global flag (/g),
 * when passing to RegExp.prototype.test() or exec(),
 * use with full understanding of lastIndex and match result behavior.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test#using_test_on_a_regex_with_the_global_flag
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/lastIndex
 *
 * NOTE:
 * When specifying the regular expression flags,
 * be aware of whether the regular expression is passed to which methods.
 * For example,
 * - "RegExp.prototype.test()" or "exec()"
 * - "String.prototype.match()" or "matchAll()"
 * - "String.prototype.replace()" or "replaceAll()"
 * --------------------
 */

export const IS_ACTIVATE_EMAIL_PAGE = /\/activate-email\/[^\n]+/;

export const HAS_CHAR_IS_NOT_HALF_WIDTH =
  // eslint-disable-next-line
  /[^(a-zA-Z0-9ｧ-ﾝﾞﾟ)|(ﾞﾟ()･.!"#$%&'()*+,-.\/\-)]/g;

export const CHECK_FULL_WIDTH =
  // eslint-disable-next-line
  /[—〜¢£＇＂~〳〴〵〻〼ヿゟ⊄⊅⊊⊋∉∅⌅⌆⊕⊖⊗∦｟｠〘〙〖〗≢≃≅≈≶≷↔♮♫♬♩▷▶◁◀↗↘↖↙⇄⇨⇦⇧⇩⤴⤵⦿◉〽﹆﹅◦•∓ℵℏ㏋ℓ℧゠–⧺⧻ゔゕゖ゚ς⓵⓶⓷⓸⓹⓺⓻⓼⓽⓾☖☗〠☎☀☁☂☃♨▱ㇰㇱㇲㇳㇴㇵㇶㇷㇸㇹㇷㇺㇻㇼㇽㇾㇿ⎾⎿⏀⏁⏂⏃⏄⏅⏆⏇⏈⏉⏊⏋⏌ヷヸヹヺ⋚⋛⅓⅔⅕✓⌘␣⏎ⅪⅫ❖☞♤♠♢♦♡♥♧♣㉑㉒㉓㉔㉕㉖㉗㉘㉙㉚㉛㉜㉝㉞㉟㊱㊲㊳㊴㊵㊶㊷㊸㊹㊺㊻㊼㊽㊾㊿        ◐◑◒◓‼⁇⁈⁉ǍǎǐḾḿǸǹǑǒǔǖǘǚǜ  € ¡¤¦©ª«­®¯²³·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿĀĪŪĒŌāīūēōĄ˘ŁĽŚŠŞŤŹŽŻą˛łľśˇšşťź˝žżŔĂĹĆČĘĚĎŃŇŐŘŮŰŢŕăĺćčęěďđńňőřůűţ˙ĈĜĤĴŜŬĉĝĥĵŝŭɱʋɾʃʒɬɮɹʈɖɳɽʂʐɻɭɟɲʝʎɡŋɰʁħʕʔɦʘǂɓɗʄɠƓœŒɨʉɘɵəɜɞɐɯʊɤʌɔɑɒʍɥʢʡɕʑɺɧɚæ̀ǽὰάɔ̀ɔ́ʌ̀ʌ́ə̀ə́ɚ̀ɚ́ὲέ͡ˈˌːˑ̆‿̋́̄̀̏̌̂˥˦˧˨˩˩˥˥˩̥̬̹̜̟̠̩̯̈̽˞̴̤̰̼̝̞̘̙̪̺̻̃̚         ⁑⁂❶❷❸❹❺❻❼❽❾❿⓫⓬⓭⓮⓯⓰⓱⓲⓳⓴ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅺⅻⓐⓑⓒⓓⓔⓕⓖⓗⓘⓙⓚⓛⓜⓝⓞⓟⓠⓡⓢⓣⓤⓥⓦⓧⓨⓩ㋐㋑㋒㋓㋔㋕㋖㋗㋘㋙㋚㋛㋜㋝㋞㋟㋠㋡㋢㋣㋺㋩㋥㋭㋬]/g;

export const CHECK_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CHECK_PASSWORD =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[0-9])(?=.*[a-z])[a-zA-Z\d^$*\.\[\]\{\}\(\)\?"!@#%&/\\,><':;|_~`=\+-]{8,}$/;

export const CHECK_FULL_WIDTH_KANA = /^[\u30A0-\u30FF]+$/;

export const numberFormat = /^[0-9]*$/;
