import { createApp } from "vue";
import Amplify from "aws-amplify";
import { vfmPlugin } from "vue-final-modal";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/router";
import store from "./store/index";
import components from "./components/index";
import directives from "./directives";
import "@/assets/scss/styles.scss";
import "vue3-date-time-picker/dist/main.css";
import "@/directives/validations";

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  },
});

/**
 * NOTE:
 * Setup API mocking library.
 * - Learn more about using MSW in a browser: [https://mswjs.io/docs/getting-started/integrate/browser]
 * - Setup Worker API: [https://mswjs.io/docs/api/setup-worker]
 */
if (process.env.VUE_APP_MOCKS_ENABLED === "1") {
  const { worker } = require("./mocks/browser");
  worker.start();
}

const domain = require(`./__resources/${process.env.VUE_APP_RESOURCE}/domain.json`);
const app = createApp(App);
app.config.globalProperties.$env = process.env.VUE_APP_MODE;
app.config.globalProperties.$domain = domain;
app
  .use(vuetify)
  .use(router)
  .use(components)
  .use(directives)
  .use(store)
  .use(
    vfmPlugin({
      key: "$vfm",
      componentName: "VueFinalModal",
      dynamicContainerName: "ModalsContainer",
    })
  )
  .mount("#app");
