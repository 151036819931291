import axios from "../axios/axios.service";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
// import LoginService from "../login.service";
import { REGEX_5XX, REGEX_4XX } from "@/globals/enums";
import {
  ERROR_MESSAGES_COMMON,
  ERROR_MESSAGES_COMMON_WITH_CODE,
  ERROR_MESSAGES_CUSTOM_WITH_CODE,
} from "@/globals/error-messages";
const confirmDialogService = new ConfirmDialogService();
// const loginService = new LoginService();
const baseDomain = process.env.VUE_APP_API_HTTP;

const options = {
  headers: {
    "Content-Type": "application/json",
  },
};

/**
 * Http GET
 */
export async function get(
  url,
  params = {},
  autoHandleError = true,
  exceptHandleErrorStatus = []
) {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        searchParams.append(key, val);
      });
    } else if (value !== null) {
      searchParams.append(key, value);
    }
  }
  const newOptions = {
    ...options,
    params: searchParams,
  };
  try {
    const requestURL = `${baseDomain}${url}`;
    const result = await axios.get(requestURL, newOptions);
    return result.data;
  } catch (error) {
    throw errorWrapper(error, autoHandleError, exceptHandleErrorStatus);
  }
}

/**
 * Http POST
 */
export async function post(
  url,
  params = {},
  autoHandleError = true,
  exceptHandleErrorStatus = []
) {
  const newOptions = {
    ...options,
  };

  try {
    const requestURL = `${baseDomain}${url}`;
    const result = await axios.post(requestURL, params, newOptions);
    return result.data;
  } catch (error) {
    throw errorWrapper(error, autoHandleError, exceptHandleErrorStatus);
  }
}

/**
 * Http PUT
 */
export async function put(
  url,
  params = {},
  autoHandleError = true,
  exceptHandleErrorStatus = []
) {
  const newOptions = {
    ...options,
  };

  try {
    const requestURL = `${baseDomain}${url}`;
    const result = await axios.put(requestURL, params, newOptions);
    return result.data;
  } catch (error) {
    throw errorWrapper(error, autoHandleError, exceptHandleErrorStatus);
  }
}

/**
 * Http DELETE
 */
export async function del(
  url,
  params = {},
  autoHandleError = true,
  exceptHandleErrorStatus = []
) {
  const newOptions = {
    ...options,
    params,
  };

  try {
    const requestURL = `${baseDomain}${url}`;
    const result = await axios.delete(requestURL, newOptions);
    return result.data;
  } catch (error) {
    throw errorWrapper(error, autoHandleError, exceptHandleErrorStatus);
  }
}

const is5xxErrorStatus = (status) => {
  return REGEX_5XX.test(status);
};

const is4xxErrorStatus = (status) => {
  return REGEX_4XX.test(status);
};

const showDialogError = (title, content, onClose) => {
  confirmDialogService.openConfirmDialog(title, content, onClose);
};

const getCommonCode = (code) => {
  let result = ERROR_MESSAGES_COMMON_WITH_CODE.find(
    (data) => data.code === code
  );
  return result;
};

const getCustomCode = (code) => {
  let result = ERROR_MESSAGES_CUSTOM_WITH_CODE.find(
    (data) => data.code === code
  );
  return result;
};

/**
 * Http Post by token id
 */
export function errorWrapper(error) {
  const errorData = {
    status: error?.response?.status,
    code: error?.response?.data?.code,
    message: error.message || "Error happened!",
  };

  // response object not exists
  if (!error?.response) {
    showDialogError(
      ERROR_MESSAGES_COMMON.OTHERS.message,
      ERROR_MESSAGES_COMMON.OTHERS.detail
    );
  }

  // response object not have code
  if (!error?.response?.data?.code) {
    if (is5xxErrorStatus(error.response.status)) {
      showDialogError(
        ERROR_MESSAGES_COMMON.STATUS_CODES_5xx.message,
        ERROR_MESSAGES_COMMON.STATUS_CODES_5xx.detail
      );
    }

    if (is4xxErrorStatus(error.response.status)) {
      showDialogError(
        ERROR_MESSAGES_COMMON.STATUS_CODES_4xx.message,
        ERROR_MESSAGES_COMMON.STATUS_CODES_4xx.detail
      );
    }
  } else {
    // response object have code in custom
    const customCurrentCode = getCustomCode(error?.response?.data?.code);
    if (customCurrentCode) {
      return {
        ...errorData,
        response: customCurrentCode,
      };
    }
    // response object have code in common
    const commonCurrentCode = getCommonCode(error?.response?.data?.code);
    if (commonCurrentCode) {
      showDialogError(commonCurrentCode.message, commonCurrentCode.detail);
    }
  }

  return {
    ...errorData,
  };
}
