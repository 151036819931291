import { TYPE_MFA_MAP } from "@/globals/enums";
import { parsePhoneNumber } from "@/plugins/parsePhoneNumber";
export const filterMFAInfo = (user) => {
  const userPhoneNumber =
    user.preferredMFA === "SMS_MFA" && user?.attributes?.phone_number
      ? user.attributes.phone_number.replace("+81", "0")
      : "";
  return {
    email: user?.attributes?.email,
    phoneNumber: userPhoneNumber,
    phoneNumberGroup: parsePhoneNumber(userPhoneNumber),
    MFAType: TYPE_MFA_MAP[user.preferredMFA] || "NO_MFA",
  };
};
