<template>
  <aside
    class="InquiryBannerComponent"
    :class="defaultStyle ? classes.defaultStyle : null"
  >
    <section class="banner-content">
      <div class="banner-content_detail">
        <!-- For Admin Inquiries -->
        <div class="banner-content_detail-inner">
          <p class="contact-information">
            <span class="information-item">■お問い合わせ先：</span>
            <span class="information-item">
              {{ adminInfo?.inquiryName || "ー" }}
            </span>
          </p>
          <p class="contact-information">
            <span class="information-item">電話番号：</span>
            <span class="information-item">
              <span
                v-if="
                  adminInfo?.inquiryPhoneNumber1 &&
                  adminInfo?.inquiryPhoneNumber2 &&
                  adminInfo?.inquiryPhoneNumber3
                "
                class="phone-number"
              >
                {{
                  adminInfo?.inquiryPhoneNumber1 +
                  "-" +
                  adminInfo?.inquiryPhoneNumber2 +
                  "-" +
                  adminInfo?.inquiryPhoneNumber3
                }}
              </span>
              <span class="phone-number" v-else>ー</span>
            </span>
          </p>
          <p class="contact-information">
            <span class="information-item">受付時間：</span>
            <span class="information-item">
              {{ adminInfo?.inquiryReceptionTime || "ー" }}
            </span>
          </p>
          <p v-if="adminInfo?.inquiryNotices" class="contact-information">
            <span class="information-item">
              {{ adminInfo?.inquiryNotices }}
            </span>
          </p>
        </div>
      </div>
    </section>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import { STATUS, WEB_STORAGE_KEY } from "@/globals/enums";
import CommonService from "@/services/common.service";
import LoginService from "@/services/login.service";
import WebStorageService from "@/services/web-storage.service";
export default {
  name: "InquiryBannerComponent",
  props: {
    defaultStyle: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    commonService: new CommonService(),
    loginService: new LoginService(),
    webStorageService: new WebStorageService(),
    adminInfo: {},
  }),
  computed: {
    ...mapState({
      tempUsername: (state) => state.settings?.tempUsername,
    }),
  },
  async created() {
    /**
     * #78325
     * The username {relatives_account_id} is obtained by referring to...
     * - (Before logging in): the URL parameter of the "/signup/:id+" page.
     * - (After logging in):  the USER_INFO in local storage.
     *
     * see also "src/views/SignUpComponent.vue"
     *
     * NOTE:
     * The tempUsername value is only stored in the store, so it will be lost if user reload the page.
     * However, of the screens that use the InquiryBannerComponent,
     * the following three screens that are displayed when user is not logged in
     * will automatically redirect him/her to the login screen when they reload the page.
     * Therefore, you do not need to worry about its volatility.
     *
     * - [/signup/:id+]  src/views/SignUpComponent.vue
     * - [/registration] src/views/RegistrationComponent.vue
     * - [/signup-mfa]   src/views/SignUpMFAComponent.vue
     */
    let result;
    const isLoggedIn = this.loginService.isLoggedIn();
    if (isLoggedIn) {
      const userInfo = this.webStorageService.getLocalStorage(
        WEB_STORAGE_KEY.USER_INFO
      );
      result = await this.commonService.getInquiry(userInfo);
    } else {
      result = await this.commonService.getInquiry(this.tempUsername);
    }
    if (result.status === STATUS.SUCCESS) {
      this.adminInfo = result.data;
    }
  },
};
</script>

<style lang="scss" scoped>
.InquiryBannerComponent {
  .banner-content_detail {
    text-align: left;
    .contact-information {
      color: var(--color-black-text);
      font-weight: var(--font-weight-medium);
      margin-bottom: 5px;
    }
    .information-item {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      white-space: pre-wrap;
      word-break: break-all;
    }
    .phone-number {
      font-size: var(--font-size-large);
      font-weight: var(--font-weight-bold);
      color: var(--color-black-text);
    }
  }
}
</style>

<style lang="scss" module="classes">
.defaultStyle {
  width: 500px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: var(--default-color);

  @media only screen and (max-width: $mobile-breakpoint) {
    max-width: 500px;
    width: 100%;
  }
}
</style>
