<template>
  <div class="StatusChipComponent">
    <!-- default -->
    <v-chip
      :class="{
        default: type === 'default',
        confirmed: type === 'confirmed',
        confirming: type === 'confirming',
        unconfirmed: type === 'unconfirmed',
        className,
      }"
      label
    >
      <slot></slot>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "StatusChipComponent",
  props: {
    type: {
      type: String,
      default: "default",
    },
    className: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.StatusChipComponent {
  .v-chip--label {
    border-radius: 0 !important;
    background: #fff;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    height: 30px;

    &.default {
      border: 1px solid #8d9caa;
      color: #8d9caa;
    }
    &.confirmed {
      border: 1px solid #748cbb;
      color: #748cbb;
    }
    &.confirming {
      border: 1px solid #74afbb;
      color: #74afbb;
    }
    &.unconfirmed {
      border: 1px solid #eba447;
      color: #eba447;
    }
    ::v-deep(.v-chip__underlay) {
      background-color: #fff;
    }
  }
}
</style>
