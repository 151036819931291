<template>
  <div
    class="GroupContentComponent"
    :class="{ noBorderBottom: isNoBorderBottom }"
  >
    <div class="child-content title" :class="{ titleTop: isTitleTop }">
      <slot name="title"></slot>
      <div v-if="required" class="label">必須</div>
    </div>
    <div class="child-content content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupContentComponent",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    isTitleTop: {
      type: Boolean,
      default: false,
    },
    isNoBorderBottom: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.GroupContentComponent {
  display: grid;
  grid-template-columns: 200px 1fr;
  border-bottom: 1px solid #d0d9df;
  padding: 15px 10px;
  font-size: 14px;
  &.noBorderBottom {
    border-bottom: unset;
  }
  .child-content {
    display: flex;
    align-items: center;
  }
  .label {
    width: 20px;
    height: 15px;
    font-size: 10px;
    font-weight: 500;
    color: #7c8185;
    background-color: #d0d9df;
    width: 30px;
    height: 16px;
    text-align: center;
  }
  .title {
    color: #7c8185;
    justify-content: space-between;
    margin-right: 10px;
  }
  .titleTop {
    align-items: baseline;
  }
  .content {
    font-weight: 500;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .GroupContentComponent {
    display: block;
    .title {
      justify-content: left;
      gap: 10px;
    }
  }
}
</style>
