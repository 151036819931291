import * as Yup from "yup";
import {
  validateEndDateTimewithStartDateTime,
  validateSelectNumber,
  validateStartDateTimewithEndTime,
} from "../common";
import { ERROR_MESSAGES } from "@/globals/error-messages";

export const absenceRegistrationSchema = (disabledStartTime) => {
  return Yup.object().shape({
    startYear: !disabledStartTime
      ? validateStartDateTimewithEndTime(
          "startYear",
          "startMonth",
          "startDate",
          "endYear",
          "endMonth",
          "endDate",
          ERROR_MESSAGES.PAST_TIME_NOT_VALID,
          ERROR_MESSAGES.NOT_VALID_TIME,
          ERROR_MESSAGES.NOT_EXIST_TIME,
          ERROR_MESSAGES.PAST_TIME_NOT_VALID,
          ERROR_MESSAGES.PLEASE_INPUT_TIME
        )
      : Yup.string().nullable(),
    startMonth: !disabledStartTime
      ? validateStartDateTimewithEndTime(
          "startYear",
          "startMonth",
          "startDate",
          "endYear",
          "endMonth",
          "endDate"
        )
      : Yup.string().nullable(),
    startDate: !disabledStartTime
      ? validateStartDateTimewithEndTime(
          "startYear",
          "startMonth",
          "startDate",
          "endYear",
          "endMonth",
          "endDate"
        )
      : Yup.string().nullable(),
    endYear: validateEndDateTimewithStartDateTime(
      "startYear",
      "startMonth",
      "startDate",
      "endYear",
      "endMonth",
      "endDate",
      ERROR_MESSAGES.PAST_TIME_NOT_VALID,
      ERROR_MESSAGES.NOT_VALID_TIME,
      ERROR_MESSAGES.NOT_EXIST_TIME,
      ERROR_MESSAGES.PAST_TIME_NOT_VALID
    ),
    endMonth: validateEndDateTimewithStartDateTime(
      "startYear",
      "startMonth",
      "startDate",
      "endYear",
      "endMonth",
      "endDate"
    ),
    endDate: validateEndDateTimewithStartDateTime(
      "startYear",
      "startMonth",
      "startDate",
      "endYear",
      "endMonth",
      "endDate"
    ),
    absenceType: validateSelectNumber(4),
  });
};
