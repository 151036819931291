import { get } from "./../https/http.service";

/**
 * IF-COMMON-001
 * @param {string} params
 * @returns {}
 */
export const getAddress = (params) => {
  return get("common/address", params);
};

/**
 * IF-COMMON-002
 * @returns {}
 */
export const getInquiry = (id) => {
  return get(`common/inquiry/${id}`);
};
