<template>
  <div class="ErrorComponent">
    <CardComponent width="960px" height="541px" class="ErrorComponent__content">
      <div class="ErrorComponent__content-left">
        <div class="ErrorComponent__content-left-content">
          <h1 class="status-code">404</h1>
          <h3 class="name-page">Page not found</h3>
          <div class="content-page">
            申し訳ございません。<br />
            お探しのページは見つかりませんでした。
          </div>
          <div class="text-page">
            URLが間違っているか、ページが存在いたしません。お手数をお掛けいたしますが、再度ご確認ください。
          </div>
          <ButtonComponent
            class="content-btn-left"
            @click="gotoHome"
            width="230px"
            height="50px"
          >
            HOME画面へ
          </ButtonComponent>
        </div>
      </div>
      <div class="ErrorComponent__content-right">
        <div class="ErrorComponent__content-right-content">
          <img
            :src="require('@/assets/images/qms/not_found_relatives.svg')"
            width="234"
            height="234"
          />
        </div>
        <ButtonComponent @click="gotoHome" width="230px" height="50px">
          HOME画面へ
        </ButtonComponent>
      </div>
    </CardComponent>
  </div>
</template>

<script>
export default {
  name: "ErrorComponent",
  methods: {
    gotoHome() {
      this.$router.push({ name: "HomeComponent" });
    },
  },
};
</script>

<style lang="scss" scoped>
.ErrorComponent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    display: flex;
    &-left {
      width: 50%;
      height: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      &-content {
        width: 385px;
        text-align: center;
        .status-code {
          color: #8d9caa;
          font-size: 120px;
          font-family: "ArciformSans";
        }
        .name-page {
          color: #8d9caa;
          letter-spacing: 1.3px;
          font-style: normal;
          line-height: 0.46;
          font-size: 26px;
          font-weight: normal;
        }
        .content-page {
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          margin: 45px 0;
        }
        .text-page {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 0.7px;
        }
        .content-btn-left {
          display: none;
        }
      }
    }
    &-right {
      width: 50%;
      height: 100%;
      background-color: #e7edef;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon-ico_notfound {
        font-size: 250px;
      }
      .ButtonComponent {
        margin-top: 50px;
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .ErrorComponent {
    .v-card {
      height: 100%;
    }
    &__content {
      &-left {
        width: 55%;
        margin: auto;
        &-content {
          .content-page {
            margin: 35px 0;
          }
          .content-btn-left {
            margin-top: 35px;
            display: block;
          }
        }
      }
      &-right {
        display: none;
      }
    }
  }
}
</style>
