export const ERROR_MESSAGES_COMMON = {
  AUTHENTICATION_ERROR: {
    code: "AUTHENTICATION_ERROR",
    message: "認証エラー",
    detail: `認証の有効期限切れ、もしくは認証エラーのため\nログアウトします。`,
  },
  STATUS_CODES_4xx: {
    code: "STATUS_CODES_4xx",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  STATUS_CODES_5xx: {
    code: "STATUS_CODES_4xx",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  OTHERS: {
    code: "OTHERS",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
};

export const ERROR_MESSAGES_COMMON_WITH_CODE = [
  {
    code: "COMMON-PARAMETER_ERROR",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-QUERY_ROW_NOT_FOUND",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-BAD_REQUEST",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-PERMISSION_ERROR",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-API_NOT_FOUND",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-METHOD_NOT_FOUND",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-ATTRIBUTE_ERROR",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-DATABASE_ERROR",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-CALL_LAMBDA_ERROR",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-CALL_COGNITO_ERROR",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-EXTERNAL_API_ERROR",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
  {
    code: "COMMON-UNKNOWN_ERROR",
    message: "エラー",
    detail: `データを取得できませんでした。\n暫くたってから再度お試しください。`,
  },
];

export const ERROR_MESSAGES_CUSTOM_WITH_CODE = [
  {
    code: "CUSTOM-RESET_MFA_TOKEN_ERROR",
  },
  {
    code: "CUSTOM-RECORD_NOT_FOUND_ERROR",
  },
  {
    code: "CUSTOM-MIMAMORI_PHONE_NUMBER_DUPLICATE_ERROR",
  },
  {
    code: "CUSTOM-SUPPLY_POINT_ID_NUMBER_DUPLICATE_ERROR",
  },
  {
    code: "CUSTOM-CANNOT_EDITED",
  },
  {
    code: "CUSTOM-USER_NOT_FOUND",
  },
  {
    code: "CUSTOM-DUPLICATE_PERIOD",
  },
  {
    code: "CUSTOM-PROFILE_REGISTERED",
  },
  {
    code: "CUSTOM-OUT_RANGE_ALERT_CONDITIONS",
  },
  {
    code: "CUSTOM-INVALID_REQUEST_ERROR",
  },
  {
    code: "CUSTOM-DUPLICATE_REQUEST_NOTIFICATION_EMAIL_ERROR",
  },
  {
    code: "CUSTOM-DUPLICATE_REQUEST_NOTIFICATION_PHONE_NUMBER_ERROR",
  },
  {
    code: "CUSTOM-VERIFIED_EMAIL",
  },
  {
    code: "CUSTOM-SESSION_ID_ERROR",
  },
  {
    code: "CUSTOM-DUPLICATE_RELATIVES_EMAIL_ERROR",
  },
  {
    code: "CUSTOM-EMAIL_DUPLICATE_ERROR",
  },
  {
    code: "CUSTOM-REGISTERED_SAFETY",
  },
  {
    code: "CUSTOM-CANNOT_REGISTERED",
  },
  {
    code: "CUSTOM-INVALID_IP_ADDRESS",
  },
];

export const ERROR_MESSAGES = {
  REQUIRED: "入力してください。",
  INVALID_EMAIL: "メールアドレスが正しい形式ではありません。",
  INVALID_PASSWORD:
    "半角英数字記号(^ $ * . [ ] { } ( ) ?" +
    '"' +
    "! @ # % & / \\, > < ' : ; | _ ~ ` = + -)で入力してください。",
  MAX_LENGTH_IS_N: "N文字以下で入力してください。",
  PLEASE_INPUT_OVER_8_NUMBER: "8文字以上で入力してください。",
  SAME_PASSWORD_REQUIRED: "パスワードが一致しません。",
  OTP_6_NUMBER_REQUIRED: "6文字で入力してください。",
  PLEASE_INPUT_OVER_N_NUMBER: "N文字以上で入力してください。",
  PLEASE_INPUT_N_NUMBER: "N文字で入力してください。",
  PLEASE_INPUT_VALID_FULL_WIDTH: "全角文字で入力してください。",
  PLEASE_INPUT_VALID_HALF_WIDTH_NUMBER: "半角数字で入力してください。",
  PLEASE_INPUT_HAFT_WIDTH_OR_FULL_WIDTH:
    "利用できない文字が含まれています。全角文字と半角文字で入力してください。",
  PLEASE_INPUT_VALID_FULL_WIDTH_KANA: "全角カナで入力してください。",
  NOT_VALID_TIME: "指定できない日付です。",
  NOT_EXIST_TIME: "存在しない日付です。",
  FUTURE_TIME_NOT_VALID: "指定できない未来の日付です。",
  PAST_TIME_NOT_VALID: "指定できない過去の日付です。",
  PLEASE_INPUT_TIME: "指定してください。",
  PLEASE_SELECT: "選択してください。",
  INVALID_TELEPHONE: "指定できない電話番号です。",
  SAME_EMAIL_REQUIRED: "メールアドレスが一致しません。",
  SAME_EMAIL_NOT_VALID: "メールアドレスは、重複しないように入力してください。",
};
