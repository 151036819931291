import axios from "axios";
import { WEB_STORAGE_KEY } from "./../../globals/enums";
import WebStorageService from "./../web-storage.service";
import LoginService from "./../login.service";
import dayjs from "dayjs";
const webStorageService = new WebStorageService();
const request = axios.create();

let isRefreshing = false;
let refreshSubscribers = [];

request.interceptors.request.use(
  function (config) {
    const token = webStorageService.getLocalStorage(WEB_STORAGE_KEY.ID_TOKEN);
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    window.clearInterval(window.tokenInterval);
    window.tokenInterval = null;
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    if (response.headers.date) {
      webStorageService.setLocalStorage(
        WEB_STORAGE_KEY.SERVER_TIME,
        dayjs(response.headers.date)
          .add(9, "hours")
          .format("YYYY-MM-DD HH:mm:ss")
      );
      webStorageService.setLocalStorage(
        WEB_STORAGE_KEY.CLIENT_TIME,
        dayjs().format("YYYY-MM-DD HH:mm:ss")
      );
    }
    return response;
  },
  (err) => {
    let { config } = err;
    let code = null;
    if (err.response) {
      code = err.response?.data?.code;
    }
    const originalRequest = config;
    if (code === "COMMON-AUTHENTICATION_ERROR") {
      if (!isRefreshing) {
        isRefreshing = true;
        const loginService = new LoginService();
        loginService.refreshToken().then((res) => {
          isRefreshing = false;
          onRefreshed(res?.idToken?.jwtToken || "");
          refreshSubscribers = [];
        });
      }
      return new Promise((resolve) => {
        subscribeTokenRefresh(() => {
          const refreshedToken = webStorageService.getLocalStorage(
            WEB_STORAGE_KEY.ID_TOKEN
          );
          if (refreshedToken) {
            originalRequest.headers.Authorization = "Bearer " + refreshedToken;
          }
          resolve(axios(originalRequest));
        });
      });
    } else {
      console.log("request.interceptors.request.use", err);
    }
    return Promise.reject(err);
  }
);

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
}
export default request;
