import { HAS_CHAR_IS_NOT_HALF_WIDTH } from "@/globals/enums";
import encoding from "encoding-japanese";

export const convertHalfWidthToFullWidth = (chars) => {
  const result = encoding.toZenkanaCase(chars).replace(/\s+/g, "");
  return encoding.toZenkakuCase(result);
};

export const convertFullWidthToHalfWidth = (chars) => {
  const zenkakuKata = encoding.toKatakanaCase(chars);
  const numberKata = encoding.toHankakuCase(zenkakuKata);
  const result = encoding.toHankanaCase(numberKata).replace(/\s+/g, "");
  return result.replace(HAS_CHAR_IS_NOT_HALF_WIDTH, "");
};

export const trimPassword = (chars) => {
  return chars.replace(/\s+/g, "");
};

const allowNumberOnly = (value) => {
  return value.replace(/[^0-9０-９]+/g, "");
};

export const inputFilter = (el, update) => {
  if (el.value && update.value === "number") {
    el.addEventListener("keyup", () => {
      el.value = allowNumberOnly(el.value);
      el.dispatchEvent(new Event("input"));
    });
    el.addEventListener("blur", () => {
      if (el.value) el.value = convertFullWidthToHalfWidth(el.value);
      el.dispatchEvent(new Event("input"));
    });
  }
  if (el.value && update.value === "fullWidth") {
    el.addEventListener("blur", () => {
      if (el.value) el.value = convertHalfWidthToFullWidth(el.value);
      el.dispatchEvent(new Event("input"));
    });
  }
  if (el.value && update.value === "halfWidth") {
    el.addEventListener("blur", () => {
      if (el.value) el.value = convertFullWidthToHalfWidth(el.value);
      el.dispatchEvent(new Event("input"));
    });
  }
  if (el.value && update.value === "trimPassword") {
    el.addEventListener("blur", () => {
      if (el.value) el.value = trimPassword(el.value);
      el.dispatchEvent(new Event("input"));
    });
  }
};
