<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    :content-class="`ConfirmSafetyDialog modal-content ${className}`"
    @click-outside="onClose()"
  >
    <button
      class="modal__close"
      @click="
        close();
        onClose();
      "
    >
      <span class="icon-ico_closedialog"></span>
    </button>
    <span class="modal__title">
      <span class="icon-title icon-ico_info"></span>
      <span>アラートが発報されました</span>
    </span>

    <div class="modal__content">
      <h3>状況の確認後、確認結果を入力してください。</h3>
    </div>
    <div class="modal__box">
      <AnalysisStatusComponent
        :pauseStart="false"
        :analysisResultCode="ANALYSIS_RESULT_CODE.ABNORMAL.VALUE"
        :confirmStatus="CONFIRM_STATUS.UNCONFIRMED.VALUE"
        :alertFlag="true"
      />
      <h4>
        アラート発報開始日：<span
          v-formatDateTimeJanpanese="content"
          class="date"
        ></span>
      </h4>
    </div>
    <ButtonComponent
      width="230px"
      height="50px"
      @click="
        close();
        onClose();
      "
    >
      確認結果を入力
    </ButtonComponent>
  </vue-final-modal>
</template>

<script>
import { ANALYSIS_RESULT_CODE, CONFIRM_STATUS } from "@/globals/enums";
export default {
  name: "ConfirmSafetyDialog",
  created() {
    this.ANALYSIS_RESULT_CODE = ANALYSIS_RESULT_CODE;
    this.CONFIRM_STATUS = CONFIRM_STATUS;
  },
  props: {
    title: {
      type: [String, Number],
      default: null,
    },
    content: {
      type: [String, Number],
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    className: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.ConfirmSafetyDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 40px 40px 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      border: 1px solid #888;
      border-radius: 5px;
      width: 500px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: left;
          min-width: 224px;
          display: flex;
          align-items: center;
          color: #c51f1f;
          font-size: 20px;
          .icon-title {
            font-size: 30px;
            margin-right: 10px;
          }
        }
        &__content {
          font-size: 14px;
          text-align: center;
          margin-top: 20px;
        }
        &__box {
          border: 1px solid #d0d9df;
          width: 100%;
          height: 60px;
          margin: 30px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .mimamori-record_status_alert {
            background-color: unset !important;
            .mimamori-record__label {
              color: #c51f1f;
            }
          }
          h4 {
            margin: 0 15px;
          }
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
      }
    }
    &-403 {
      width: 430px;
      padding: 30px 20px;
    }
  }
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: $mobile-breakpoint) {
  ::v-deep(.ConfirmSafetyDialog) {
    &.modal {
      &-content {
        width: 320px !important;
        padding: 40px 15px 29px;
        .modal__content {
          margin: 20px 15px 0;
        }
        .modal__box h4 {
          text-align: left;
        }
        .modal__box h4 .date {
          display: block;
        }
      }
    }
  }
}
</style>
