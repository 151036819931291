<template>
  <router-view :alertInfo="alertInfo" v-if="alertInfo" :getAgain="getData" />
</template>

<script>
import HomeService from "@/services/home.service";
import AlertService from "@/services/alert.service";
import { STATUS } from "@/globals/enums";

export default {
  name: "AlertSettingComponent",
  data: () => ({
    homeService: new HomeService(),
    alertService: new AlertService(),
    alertInfo: null,
  }),
  mounted() {
    this.getData(true);
  },
  methods: {
    goEditPage() {
      this.$router.push({ name: "AlertEditComponent" });
    },
    async getData(loading) {
      loading && this.$store.dispatch("global/updateLoadingStatus", true);
      const homeData = await this.homeService.getHome();
      if (homeData?.data?.mimamoriTargetPersonId) {
        const result = await this.alertService.getAlertInfo(
          homeData.data.mimamoriTargetPersonId
        );
        if (result.status === STATUS.SUCCESS) {
          this.alertInfo = {
            ...result?.data,
            showMessage:
              (homeData.data.serviceStatus === 3 ||
                homeData.data.serviceStatus === 4) &&
              result?.data?.isUnActiveEmail,
          };
        }
      }
      loading && this.$store.dispatch("global/updateLoadingStatus", false);
    },
  },
};
</script>
<style scoped lang="scss"></style>
