<template>
  <v-expansion-panel
    class="PanelComponent"
    :class="{
      nesting: nesting,
      nested: nested,
      shrinked: shrinked,
    }"
    :value="value"
  >
    <v-expansion-panel-title
      class="PanelComponent_title"
      :expand-icon="collapseIcon"
      :collapse-icon="expandIcon"
    >
      <slot name="title"></slot>
    </v-expansion-panel-title>
    <v-expansion-panel-text class="PanelComponent_content">
      <slot name="content"></slot>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "PanelComponent",
  props: {
    nesting: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    shrinked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
    },
  },
  computed: {
    expandIcon() {
      return this.nested ? "mdi-chevron-s-up" : "mdi-chevron-up";
    },
    collapseIcon() {
      return this.nested ? "mdi-chevron-s-down" : "mdi-chevron-down";
    },
  },
};
</script>

<style lang="scss" scoped>
.PanelComponent {
  border-radius: 0;
  border: solid 1px #e7edef;
  margin-top: 0 !important;

  .PanelComponent_title {
    z-index: 2;
    font-weight: 500;
    color: #000;
    text-align: left;
  }

  // Default Style - 1
  :not(&.nested) {
    > .PanelComponent_title {
      min-height: 80px;
      background-color: #e7edef;
      font-size: 18px;
    }
  }

  // Nested Child Style - 1
  &.nested {
    > .PanelComponent_title {
      min-height: 60px;
      background-color: #f3f6f7;
      font-size: 16px;
    }
  }

  // Shrinked Style
  &.shrinked {
    > .PanelComponent_title {
      min-height: 60px;
    }
  }
}
</style>

<!--
  [Global (Not being 'scoped')]
  Override the default style for Expansion panels.
  (NOTE: MOVED FROM styles.scss)
-->
<style lang="scss">
.PanelComponent {
  .v-expansion-panel__shadow {
    display: none;
  }

  // Default Style - 1
  :not(&.nested) {
    > .v-expansion-panel-title > .v-expansion-panel-title__icon {
      margin-left: 0.5em;
    }
    > .v-expansion-panel-title
      > .v-expansion-panel-title__icon
      > .v-icon--size-default {
      font-size: 18px;
    }
  }
  // Nested Child Style - 1
  &.nested {
    > .v-expansion-panel-title > .v-expansion-panel-title__icon {
      margin-left: 0.5em;
    }
    > .v-expansion-panel-title
      > .v-expansion-panel-title__icon
      > .v-icon--size-default {
      font-size: 16px;
    }
  }

  .v-expansion-panel-title__overlay {
    opacity: 0 !important;
  }

  // Default Style - 2
  :not(&.nesting) {
    > .v-expansion-panel-text > .v-expansion-panel-text__wrapper {
      padding: 30px 23px 50px 29px;
    }
  }
  // Nested Child's Parent Style - 2
  &.nesting {
    > .v-expansion-panel-text > .v-expansion-panel-text__wrapper {
      padding: 0;
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .PanelComponent {
    // Default Style - 2
    :not(&.nesting) {
      > .v-expansion-panel-text > .v-expansion-panel-text__wrapper {
        padding: 1em;
      }
    }
    // Nested Child's Parent Style - 2
    &.nesting {
      > .v-expansion-panel-text > .v-expansion-panel-text__wrapper {
        padding: 0;
      }
    }
  }
}
</style>
