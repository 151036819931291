import router from "../router/router";
import store from "./../store/index";
import WebStorageService from "./web-storage.service";
import { WEB_STORAGE_KEY, TYPE_MFA, STATUS } from "../globals/enums";
import { Auth } from "aws-amplify";
import {
  verifyPhoneNumber,
  resetMFA,
  checkToken,
} from "@/services/api/api.service";
import ConfirmDialogService from "./dialog/confirm-dialog.service";
import { ERROR_MESSAGES_COMMON } from "@/globals/error-messages";
import HomeService from "./home.service";
import { convertHomeChangeRouter } from "@/store/converter/home.converter";
import { $vfm } from "vue-final-modal";

const webStorageService = new WebStorageService();
const confirmDialogService = new ConfirmDialogService();
export default class LoginService {
  /**
   * Overview:
   * Check function to simply determine from WebStorage information
   * whether or not a user is logged in.
   *
   * NOTE:
   * This function is used for the purpose of displaying different contents
   * that can be viewed by logged-in/non-logged-in users.
   * Since there is no explicit definition of "login status"
   * in the current implementation, this function is substituted.
   * (Ideally, It would be better to get the login status from the store.)
   *
   * @returns Boolean
   */
  isLoggedIn() {
    const token = webStorageService.getLocalStorage(WEB_STORAGE_KEY.ID_TOKEN);
    const userInfo = webStorageService.getLocalStorage(
      WEB_STORAGE_KEY.USER_INFO
    );
    return token && userInfo;
  }

  async login(user) {
    webStorageService.setLocalStorage(
      WEB_STORAGE_KEY.ID_TOKEN,
      user.signInUserSession.idToken.jwtToken
    );
    webStorageService.setLocalStorage(
      WEB_STORAGE_KEY.ACCESS_TOKEN,
      user.signInUserSession.accessToken.jwtToken
    );
    webStorageService.setLocalStorage(
      WEB_STORAGE_KEY.REFRESH_TOKEN,
      user.signInUserSession.refreshToken.token
    );
    webStorageService.setLocalStorage(WEB_STORAGE_KEY.USER_INFO, user.username);
    const homeService = new HomeService();
    const result = await homeService.getHome();
    if (result?.status === STATUS.SUCCESS) {
      const reRouterComponent = convertHomeChangeRouter(result?.data, true);
      router.push({ name: reRouterComponent });
    }
  }

  tempLogin(tempUser) {
    store.dispatch("settings/changeTempUser", tempUser);
  }

  saveQrCode(email, code) {
    store.dispatch("settings/changeQRCode", {
      generateQRCode:
        "otpauth://totp/" +
        process.env.VUE_APP_AWS_ISSUER_COMPANY +
        ":" +
        email +
        "?secret=" +
        code +
        "&issuer=" +
        process.env.VUE_APP_AWS_ISSUER_COMPANY,
      code,
    });
  }

  clearQrCode() {
    store.dispatch("settings/changeQRCode", null);
  }

  async getCognitoUserData() {
    try {
      /**
       * #70176
       * If Auth.getPreferredMFA() is used before Auth.setPreferredMFA() is called,
       * the cached value may be returned even if "bypassCache: true" was set for Auth.getPreferredMFA().
       * See also comments in registerSMSAgain() function.
       */
      const user = await Auth.currentAuthenticatedUser();
      const preferredMFA = await Auth.getPreferredMFA(user, {
        bypassCache: true,
      });
      store.dispatch("settings/changeCurrentUserMFAInfo", {
        ...user,
        preferredMFA,
      });
    } catch (error) {
      console.log(error);
      return { status: STATUS.ERROR, error };
    }
  }

  async setupTOTP() {
    try {
      const data = store.getters["settings/getTempUser"];
      const token = webStorageService.getLocalStorage(WEB_STORAGE_KEY.ID_TOKEN);
      const user = token
        ? await Auth.currentAuthenticatedUser()
        : await Auth.signIn(data.emailAddress, data.tempPassword);
      const code = await Auth.setupTOTP(user);
      //save QRCODE to store
      this.saveQrCode(token ? user.attributes.email : data.emailAddress, code);
      return {
        status: STATUS.SUCCESS,
        data: user,
      };
    } catch (error) {
      console.log(error);
      return { status: STATUS.ERROR, error };
    }
  }

  async setPreferredTOTP() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(user, TYPE_MFA.TOTP);
      return { status: STATUS.SUCCESS };
    } catch (error) {
      console.log(error);
      return { status: STATUS.ERROR, error };
    }
  }

  async registerTOTPAgain(challengeAnswer, user) {
    try {
      await Auth.verifyTotpToken(user, challengeAnswer);
      await Auth.setPreferredMFA(user, TYPE_MFA.TOTP);
      confirmDialogService.openConfirmDialog(
        "セキュリティ設定を保存しました。",
        null,
        () => router.push({ name: "HomeComponent" })
      );
    } catch (error) {
      return {
        status: STATUS.ERROR,
        error: error,
        response: { detail: "有効な認証コードを入力してください。" },
      };
    }
  }

  async registerSMSAgain(phoneNumber) {
    try {
      const data = store.getters["settings/getTempUser"];
      const token = webStorageService.getLocalStorage(WEB_STORAGE_KEY.ID_TOKEN);
      const user = token
        ? await Auth.currentAuthenticatedUser()
        : await Auth.signIn(data.emailAddress, data.tempPassword);
      await verifyPhoneNumber({
        account_id: user.username,
        phone_number: phoneNumber,
        type: parseInt(process.env.VUE_APP_PERMISION_ID),
      });

      /**
       * #70176
       * To avoid unexpected responses from Cognito,
       * it is recommended to call Auth.setPreferredMFA() to ensure a user has set their MFA method
       * before retrieving MFA settings with Auth.getPreferredMFA().
       * [https://docs.amplify.aws/lib/auth/mfa/q/platform/js/#retrieve-current-preferred-mfa-type]
       */
      await Auth.setPreferredMFA(user, TYPE_MFA.SMS);

      confirmDialogService.openConfirmDialog(
        "セキュリティ設定を保存しました。",
        null,
        () => router.push({ name: "HomeComponent" })
      );
    } catch (error) {
      return {
        status: STATUS.ERROR,
        error: error,
        response: {
          detail:
            "セキュリティ設定の変更に失敗しました。暫くたってから再度試してください。",
        },
      };
    }
  }

  async reConfigSMS(phoneNumber, accountId) {
    try {
      await verifyPhoneNumber({
        account_id: accountId,
        phone_number: phoneNumber,
        type: parseInt(process.env.VUE_APP_PERMISION_ID),
      });
      confirmDialogService.openConfirmDialog(
        "多要素認証のリセットが完了しました。",
        "トップページより、新しいパスワードにて\n再度ログインしてください。",
        () => {
          this.logout();
        },
        "modal-resetPassword"
      );
    } catch (error) {
      console.log(error);
      return {
        status: STATUS.ERROR,
        error: error,
      };
    }
  }

  async loginMFA(user, loginMFAcode, mfaType) {
    await Auth.confirmSignIn(user, loginMFAcode, mfaType);
    await this.login(user, true);
  }

  async verifyMFACode(emailAddress, password, username, user, fromSignUp) {
    this.tempLogin({
      emailAddress,
      tempPassword: password,
      username,
      currentUser: user,
    });
    router.push({
      name: fromSignUp ? "SignUpMFAComponent" : "LoginMFAComponent",
    });
  }

  async resetMFASetting(userName) {
    try {
      await resetMFA({
        account_id: userName,
        type: parseInt(process.env.VUE_APP_PERMISION_ID),
      });
      return { status: STATUS.SUCCESS };
    } catch (error) {
      console.log(error);
      return { status: STATUS.ERROR, error };
    }
  }

  /**
   * Authenticate with Cognito
   * @param {string} emailAddress - NOTE: Before logging in, this value can be username {relatives_account_id}.
   * @param {string} password
   * @param {string} changePasswordRequired
   * @param {string} loginMFAcode
   * @param {boolean} again
   * @param {boolean} fromSignUp
   * @returns
   */
  async signIn(
    emailAddress,
    password,
    changePasswordRequired = null,
    loginMFAcode = null,
    again = false,
    fromSignUp = false
  ) {
    try {
      const data = store.getters["settings/getTempUser"];
      if (
        (data?.currentUser?.challengeName === "SOFTWARE_TOKEN_MFA" ||
          data?.currentUser?.challengeName === "SMS_MFA") &&
        loginMFAcode
      ) {
        await this.loginMFA(
          data.currentUser,
          loginMFAcode,
          data.currentUser.challengeName
        );
        return;
      }
      const user = await Auth.signIn(emailAddress, password);
      if (user) {
        if (
          user.challengeName === "SMS_MFA" ||
          user.challengeName === "SOFTWARE_TOKEN_MFA"
        ) {
          this.verifyMFACode(
            emailAddress,
            password,
            user.username,
            user,
            fromSignUp
          );
        } else if (
          user.challengeName &&
          user.challengeName === "NEW_PASSWORD_REQUIRED"
        ) {
          if (changePasswordRequired) {
            try {
              const newUser = await Auth.completeNewPassword(
                user,
                changePasswordRequired
              );
              this.verifyMFACode(
                emailAddress,
                password,
                newUser.username,
                newUser,
                fromSignUp
              );
            } catch (error) {
              console.log(error);
              throw error;
            }
          } else {
            this.tempLogin({
              emailAddress: emailAddress,
              tempPassword: password,
            });
            router.push({ name: "RegistrationComponent" });
          }
        } else if (user.challengeName === "MFA_SETUP") {
          this.tempLogin({
            emailAddress: emailAddress,
            tempPassword: password,
          });
          fromSignUp && (await Auth.setPreferredMFA(user, TYPE_MFA.SMS));
          !again && this.signIn(emailAddress, password, null, null, true);
        } else {
          this.login(user, true);
        }
      }
      return { status: "NO_ACTION" };
    } catch (err) {
      if (loginMFAcode) {
        return {
          status: STATUS.ERROR,
          error: err,
          response: { detail: "有効な認証コードを入力してください。" },
        };
      }
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        return {
          status: STATUS.ERROR,
          error: err,
          response: {
            detail: "メールアドレスまたはパスワードが正しくありません。",
          },
        };
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
      return { status: STATUS.ERROR, error: err, response: {} };
    }
  }

  async refreshToken() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = cognitoUser.getSignInUserSession();
      return new Promise((resolve) =>
        cognitoUser.refreshSession(refreshToken, async (err, session) => {
          if (err) {
            throw err;
          }
          console.log("session", session);
          webStorageService.setLocalStorage(
            WEB_STORAGE_KEY.ID_TOKEN,
            session.idToken.jwtToken
          );
          webStorageService.setLocalStorage(
            WEB_STORAGE_KEY.USER_INFO,
            cognitoUser.username
          );
          webStorageService.setLocalStorage(
            WEB_STORAGE_KEY.ACCESS_TOKEN,
            session.accessToken.jwtToken
          );
          webStorageService.setLocalStorage(
            WEB_STORAGE_KEY.REFRESH_TOKEN,
            session.refreshToken.token
          );
          const cognitoUserToken = `CognitoIdentityServiceProvider.${process.env.VUE_APP_COGNITO_CLIENT_ID}.${cognitoUser.username}.idToken`;
          webStorageService.setLocalStorage(
            cognitoUserToken,
            session.idToken.jwtToken
          );
          resolve(session);
        })
      );
    } catch (error) {
      console.log("Unable to refresh Token", error);
      if ($vfm.modals.length === 0) {
        confirmDialogService.openConfirmDialog(
          ERROR_MESSAGES_COMMON.AUTHENTICATION_ERROR.message,
          ERROR_MESSAGES_COMMON.AUTHENTICATION_ERROR.detail,
          () => this.logout()
        );
      }
    }
  }

  async logout() {
    window.clearInterval(window.tokenInterval);
    window.tokenInterval = null;
    await Auth.signOut();
    webStorageService.clearLocalStorage();
    webStorageService.clearSessionStorage();
    store.dispatch("settings/resetState");
    store.dispatch("global/resetState");
    router.push({ name: "LoginComponent" });
  }

  async forgotPassword(email) {
    try {
      await Auth.forgotPassword(email);
      // save email to store
      this.tempLogin({
        emailAddress: email,
      });
      confirmDialogService.openConfirmDialog(
        "認証コードを送信しました。",
        "認証コードをメールアドレスへ送信しました。\n認証コードを入力して新しいパスワードを再設定してください。",
        () => router.push({ name: "ResetPasswordComponent" }),
        "modal-resetPassword"
      );
    } catch (error) {
      return {
        status: STATUS.ERROR,
        error: error,
        response: {
          detail:
            "認証コードの発行に失敗しました。暫くたってから再度試してください。",
        },
      };
    }
  }

  async resetPassword(emailAddress, code, password) {
    try {
      await Auth.forgotPasswordSubmit(emailAddress, code, password);
      confirmDialogService.openConfirmDialog(
        "パスワードの再設定が完了しました。",
        "トップページより、新しいパスワードにて再度ログインしてください。",
        () => router.push({ name: "LoginComponent" })
      );
      this.tempLogin(null);
    } catch (error) {
      if (
        error.code === "CodeMismatchException" ||
        error.code === "ExpiredCodeException"
      ) {
        return {
          status: STATUS.ERROR,
          error: error,
          response: { detail: "承認コードが無効です" },
        };
      }
      if (error.code === "LimitExceededException") {
        return {
          status: STATUS.ERROR,
          error: error,
          response: {
            detail: "承認コードが無効です。暫くたってから再度試してください。",
          },
        };
      }
      return {
        status: STATUS.ERROR,
        error: error,
        response: {
          detail:
            "新しいパスワード設定ができませんでした。暫くたってから再度試してください。",
        },
      };
    }
  }

  async changePassword(oldPassword, newPassword) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      confirmDialogService.openConfirmDialog(
        "パスワードの変更が完了しました。",
        null,
        () => router.push({ name: "HomeComponent" })
      );
    } catch (error) {
      if (error.code === "NotAuthorizedException") {
        return {
          status: STATUS.ERROR,
          error: error,
          response: { detail: "現在のパスワードが正しくありません。" },
        };
      }
      if (error.code === "LimitExceededException") {
        return {
          status: STATUS.ERROR,
          error: error,
          response: {
            detail:
              "現在のパスワードが正しくありません。暫くたってから再度試してください。",
          },
        };
      }
      return {
        status: STATUS.ERROR,
        error: error,
        response: {
          detail:
            "パスワード変更が受けられませんでした。暫くたってから再度試してください。",
        },
      };
    }
  }

  async checkToken(token) {
    try {
      const result = await checkToken({
        token: token,
        type: parseInt(process.env.VUE_APP_PERMISION_ID),
      });
      return { status: STATUS.SUCCESS, data: result };
    } catch (error) {
      console.log(error);
      return { status: STATUS.ERROR, error: error };
    }
  }
}
