import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { FORTMAT_DATE_TIME } from "./../globals/enums";

const ExcelJS = require("exceljs");

export const openOrFocusTab = (url, name) => {
  window.open(url, name).focus();
};

/**
 * function check file
 * @param fileName string - file name
 */
export const checkExcelFile = (fileName) => {
  return fileName.match(/(.xls|.xlsx|.csv|.xlsm)/);
};

/**
 * function get content file xlsx xlsm file
 * @param file file
 */
export const readXlsxAndXlsm = (file) => {
  const wb = new ExcelJS.Workbook();
  const reader = new FileReader();

  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    const buffer = reader.result;
    wb.xlsx.load(buffer).then((workbook) => {
      console.log(workbook, "workbook instance");
      workbook.eachSheet((sheet) => {
        sheet.eachRow((row, rowIndex) => {
          console.log(row.values, rowIndex);
        });
      });
    });
  };
};

/**
 * function save csv file
 * @param columns array object - column headers and define column keys and widths
 * @param data array object - data row
 * @param fileName string - export filename
 */
export const exportCSV = (columns = [], data = [], fileName = "") => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet();
  worksheet.columns = columns;
  data.forEach((item) => {
    for (const property in item) {
      if (item[property] && typeof item[property] === "string") {
        item[property] = item[property].replace(/[\r\n]+/g, " "); // 改行コードを半角スペースに変換
      }
    }
    worksheet.addRow(item);
  });
  // save file to local
  workbook.csv
    .writeBuffer()
    .then((data) => {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, data], {
        type: "text/csv",
      });
      saveAs(
        blob,
        `${fileName}_${dayjs().format(FORTMAT_DATE_TIME.EXPORT_DATETIME)}.csv`
      );
    })
    .catch((err) => {
      console.log(err);
    });
};

export const formatTelNumber = (value) => {
  if (value?.length === 11 && checkException(value)) {
    return [value.slice(0, 3), value.slice(3, 7), value.slice(7, 11)];
  }
  // 日本の国コード
  const region = "JP";

  const util = PhoneNumberUtil.getInstance();

  try {
    // 番号と地域を設定
    const number = util.parseAndKeepRawInput(value, region);

    // 電話番号の有効性チェック
    if (!util.isValidNumberForRegion(number, region)) {
      return null;
    }

    // ハイフン付きの形式で返却
    return util.format(number, PhoneNumberFormat.NATIONAL).split("-");
  } catch (error) {
    return null;
  }
};

export const checkException = (value) => {
  const first4 = value.slice(0, 4);
  const character5 = value.charAt(5);
  const validFirst4 = ["0700", "0800", "0900"];
  return validFirst4.includes(first4) && /^\d$/.test(character5);
};

export const testphoneNumber = (value) => {
  if (value?.length === 11 && checkException(value)) {
    return true;
  }
  // 日本の国コード
  const region = "JP";

  const util = PhoneNumberUtil.getInstance();

  try {
    // 番号と地域を設定
    const number = util.parseAndKeepRawInput(value, region);

    // 電話番号の有効性チェック
    return !!util.isValidNumberForRegion(number, region);
  } catch (error) {
    return false;
  }
};
