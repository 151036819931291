import {
  getProfile,
  getRelative,
  updateAutoCallTelephone,
  updateRelative,
  updateRelativeEmail,
  createProfileInfo,
  checkPhoneNumberMimamori,
} from "./api/user.service";
import store from "./../store/index";
import { STATUS } from "@/globals/enums";
import {
  convertAutoCallTelephone,
  convertRelativeEdit,
  convertCreateProfileInfo,
  convertRelativeInfo,
} from "@/store/converter/profile.convert";

export default class UserService {
  async getProfileInfo(userId) {
    try {
      const profileInfo = await getProfile(userId);
      store.dispatch("settings/changeProfileInfo", profileInfo);
    } catch (error) {
      console.log(error);
    }
  }

  async getRalativeInfo(userId) {
    try {
      const relativeInfo = await getRelative(userId);
      store.dispatch("settings/changeRelativeInfo", relativeInfo);
      return {
        status: STATUS.SUCCESS,
        data: convertRelativeInfo(relativeInfo),
      };
    } catch (error) {
      console.log(error);
      return {
        status: STATUS.ERROR,
      };
    }
  }

  async updateAutoCallTelephone(userId, data) {
    try {
      await updateAutoCallTelephone(userId, convertAutoCallTelephone(data));
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        response: {
          ...error.response,
          ...(error?.response?.code ===
            "CUSTOM-MIMAMORI_PHONE_NUMBER_DUPLICATE_ERROR" && {
            detail:
              "同じ電話番号が登録されています。別の電話番号を入力してください。",
          }),
        },
      };
    }
  }

  async checkPhoneNumberMimamori(id) {
    try {
      await checkPhoneNumberMimamori(id);
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        response: {
          ...error.response,
          ...(error?.response?.code === "CUSTOM-RECORD_NOT_FOUND_ERROR" && {
            detail:
              "電話番号の登録に問題があり、有効性を確認できません。もういちど登録しなおしてください。",
          }),
        },
      };
    }
  }

  async updateRelative(userId, data) {
    try {
      await updateRelative(userId, convertRelativeEdit(data));
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        response: error?.response,
      };
    }
  }

  async updateRelativeEmail(email) {
    try {
      await updateRelativeEmail({
        relatives_email: email,
      });
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: {
          ...error.response,
          ...(error?.response?.code ===
            "CUSTOM-DUPLICATE_RELATIVES_EMAIL_ERROR" && {
            detail:
              "同じメールアドレスが登録されています。別のメールアドレスを入力してください。",
          }),
        },
      };
    }
  }

  async createProfileInfo(data) {
    try {
      await createProfileInfo(convertCreateProfileInfo(data));
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: {
          ...error.response,
          ...(error?.response?.code === "CUSTOM-PROFILE_REGISTERED" && {
            detail: "プロフィールがすでに登録されています。",
          }),
          ...(error?.response?.code === "CUSTOM-OUT_RANGE_ALERT_CONDITIONS" && {
            detail: "異常判定日数の値を小さくしてください。",
          }),
          ...(error?.response?.code === "CUSTOM-INVALID_REQUEST_ERROR" && {
            detail: "メールアドレスと電話番号を入力してください。",
          }),
          ...(error?.response?.code ===
            "CUSTOM-DUPLICATE_REQUEST_NOTIFICATION_EMAIL_ERROR" && {
            detail: "アラート通知先のメールアドレスが重複しています。",
          }),
          ...(error?.response?.code ===
            "CUSTOM-DUPLICATE_REQUEST_NOTIFICATION_PHONE_NUMBER_ERROR" && {
            detail: "アラート通知先の電話番号が重複しています。",
          }),
        },
      };
    }
  }
}
