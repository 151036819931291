<template>
  <div class="MailRegistrationComponent">
    <Form @submit="submit" :validation-schema="schema" v-slot="{ meta }">
      <CardComponent class="main main-success" :width="'960px'" height="auto">
        <TitleComponent class="fitContent" :hasUnderLine="true">
          <template v-slot:content>メールアドレスログイン設定</template>
        </TitleComponent>
        <span class="success-content"
          >メールアドレスを使ってログインできるように設定します。</span
        >
        <div class="inputGroup">
          <InputComponent
            name="email"
            placeholder="メールアドレスを入力"
            maxlength="100"
            @trigle-change="errorDetail = null"
          />
          <InputComponent
            name="confirmEmail"
            placeholder="メールアドレスを再入力（確認）"
            maxlength="100"
            @trigle-change="errorDetail = null"
          />
        </div>
        <div class="description">
          <p class="details">
            ご登録いただくメールアドレスは、Webメール（GmailやiCloudメールなど）をおすすめします。<br />
            携帯電話会社（キャリア）が提供するメールアドレスを登録されますと、携帯電話会社を変更した際にログインができなくなる可能性があります。また、独自の設定により通知メールが届かない場合があります。
          </p>
        </div>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="action-group">
          <ButtonComponent
            width="150px"
            height="50px"
            color="#8d9caa"
            :disabled="!(meta.valid && meta.dirty)"
            >登録</ButtonComponent
          >
        </div>
        <LinkComponent :link="$domain.HELP_CONTENT_LINK['2-1-d']">
          <span class="d-flex align-center justify-center black">
            <i class="icon icon-help"></i>
            操作方法がご不明な場合
          </span>
        </LinkComponent>
        <InquiryBannerComponent class="mb-5" />
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import UserService from "@/services/user.service";
import { STATUS } from "@/globals/enums";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import { updateRelativesEmailSchema } from "@/validations/schemas/updateRelativesEmailSchema";
export default {
  name: "MailRegistrationComponent",
  components: {
    Form,
  },
  data: () => ({
    userService: new UserService(),
    confirmDialogService: new ConfirmDialogService(),
    errorDetail: null,
    schema: updateRelativesEmailSchema,
  }),

  methods: {
    async submit(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.userService.updateRelativeEmail(data.email);
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
      if (result?.status === STATUS.SUCCESS) {
        this.confirmDialogService.openConfirmDialog(
          "メールアドレスログイン設定が完了しました。",
          null,
          () => {
            this.$router.push({ name: "ProfileCreateComponent" });
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.MailRegistrationComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .main {
    padding: 30px 40px;
    margin: 40px 0;
    &-success {
      flex-direction: column;
      align-items: center;
      display: flex;
      .success-content {
        margin: 20px 0 40px;
      }
    }
    .inputGroup {
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-bottom: 30px;
    }
    .description {
      margin-bottom: 40px;
      .details {
        text-align: left;
      }
    }
  }
  .ButtonComponent {
    font-size: 18px;
    font-weight: bold;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .MailRegistrationComponent {
    height: 100%;
    display: block;
    .v-card {
      width: 100% !important;
      height: 100% !important;
    }
    form {
      height: 100%;
    }
    .main {
      height: 100%;
      padding: 30px 20px;
      margin: 0;
      &-success {
        flex-direction: column;
        align-items: center;
        display: flex;
        text-align: center;
        .success-content {
          margin: 20px 0 40px;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .inputGroup {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
        .InputComponent {
          width: 100% !important;
        }
      }
    }
  }
}
</style>
