<template>
  <div class="UpdateRelativesEmailComponent">
    <TitleComponent class="UpdateRelativesEmailComponent-title">
      <template v-slot:icon>
        <i class="icon icon-t_ico_mail"></i>
      </template>
      <template v-slot:content>メールアドレスログイン設定</template>
    </TitleComponent>
    <CardComponent width="960px" height="auto">
      <div class="UpdateRelativesEmailComponent-title-content">
        <h4>メールアドレスログイン設定</h4>
      </div>
      <div v-if="!isEdit" class="UpdateRelativesEmailComponent-content">
        <GroupContentComponent>
          <template v-slot:title>メールアドレス</template>
          <template v-slot:content>
            {{ globalData?.relativesAccountInfo?.relativesEmail }}
          </template>
        </GroupContentComponent>
        <div class="UpdateRelativesEmailComponent-content-action">
          <ButtonComponent
            @click="changeStateEdit($event)"
            width="100px"
            height="30px"
          >
            編集
          </ButtonComponent>
        </div>
      </div>
      <div v-if="isEdit" class="UpdateRelativesEmailComponent-content">
        <Form
          @submit="submit"
          :validation-schema="schemaEdit"
          v-slot="{ meta }"
        >
          <div class="UpdateRelativesEmailComponent-form">
            <GroupContentComponent :required="true">
              <template v-slot:title>メールアドレス</template>
              <template v-slot:content>
                <InputComponent
                  type="text"
                  name="email"
                  placeholder="メールアドレスを入力"
                  maxlength="100"
                  :small="true"
                  @trigle-change="errorDetail = null"
                />
              </template>
            </GroupContentComponent>
            <GroupContentComponent :required="true">
              <template v-slot:title>メールアドレス（確認）</template>
              <template v-slot:content>
                <InputComponent
                  type="text"
                  name="confirmEmail"
                  maxlength="100"
                  placeholder="メールアドレスを再入力（確認）"
                  :small="true"
                  @trigle-change="errorDetail = null"
                />
              </template>
            </GroupContentComponent>
            <div class="UpdateRelativesEmailComponent-form-description">
              <h2 class="title">
                登録後、ログイン時のID（メールアドレス）が変更になります。
              </h2>
              <p class="details">
                ご登録いただくメールアドレスは、Webメール（GmailやiCloudメールなど）をおすすめします。<br />
                携帯電話会社（キャリア）が提供するメールアドレスを登録されますと、携帯電話会社を変更した際にログインができなくなる可能性があります。また、独自の設定により通知メールが届かない場合があります。
              </p>
            </div>
            <ErrorDetailComponent
              v-if="errorDetail"
              :errorDetail="errorDetail"
            />
            <div class="UpdateRelativesEmailComponent-form-action">
              <ButtonComponent
                @click="changeStateEdit($event)"
                width="150px"
                height="50px"
                type="outline"
              >
                キャンセル
              </ButtonComponent>
              <ButtonComponent
                buttonType="submit"
                width="150px"
                height="50px"
                class="btn-submit"
                :disabled="!(meta.valid && meta.dirty)"
              >
                登録
              </ButtonComponent>
            </div>
          </div>
        </Form>
      </div>
    </CardComponent>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import { mapState } from "vuex";
import { updateRelativesEmailSchema } from "@/validations/schemas/updateRelativesEmailSchema";
import { STATUS } from "@/globals/enums";
import UserService from "@/services/user.service";
import HomeService from "@/services/home.service";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
export default {
  name: "UpdateRelativesEmailComponent",
  components: {
    Form,
  },
  data: () => ({
    isEdit: false,
    schemaEdit: updateRelativesEmailSchema,
    userService: new UserService(),
    homeService: new HomeService(),
    confirmDialogService: new ConfirmDialogService(),
  }),
  computed: {
    ...mapState({
      globalData: (state) => state.global.globalData,
    }),
  },
  methods: {
    async submit(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.userService.updateRelativeEmail(data.email);
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
      if (result?.status === STATUS.SUCCESS) {
        this.confirmDialogService.openConfirmDialog(
          "メールアドレスログイン設定が完了しました。",
          null,
          async () => {
            await this.homeService.getHome();
            this.isEdit = false;
          }
        );
      }
    },

    changeStateEdit(e) {
      e.preventDefault();
      this.isEdit = !this.isEdit;
      this.errorDetail = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.UpdateRelativesEmailComponent {
  &-title {
    display: flex;
    width: 960px;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    margin: auto;
    // TODO: Refactoring
    .icon {
      line-height: 1.5;
      margin-right: 0.25em;
      vertical-align: middle;
    }
    &-content {
      font-size: 16px;
      border-bottom: 2px solid #d0d9df;
      font-weight: 500;
      padding: 5px 0;
    }
  }
  .v-card {
    min-height: 500px;
    padding: 30px 40px;
  }
  &-content {
    &-action {
      text-align: right;
      margin-top: 30px;
    }
  }
  &-form {
    &-description {
      margin: 30px 0;
      .title {
        text-align: center;
        margin-bottom: 1em;
      }
      .details {
        text-align: left;
        padding: 0 10px;
      }
    }
    &-action {
      display: flex;
      justify-content: center;
      .ButtonComponent {
        margin: 0 10px;
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .UpdateRelativesEmailComponent {
    height: 100%;
    display: block;
    .v-card {
      width: 100% !important;
      height: 100% !important;
      padding: 30px 20px;
    }
    form {
      height: 100%;
    }
    &-content-action {
      text-align: center;
    }
    &-form-description {
      .title {
        text-align: left;
      }
      .details {
        text-align: left;
        padding: 0;
      }
    }
    &-form-action {
      align-items: center;
      flex-direction: column-reverse;
      gap: 0 !important;
      .btn-submit {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
