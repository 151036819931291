<template>
  <div
    class="SelectComponent"
    :class="{
      'has-error': !!errorMessage || errors.length !== 0,
      isSmall: isSmall,
      isSelectYear: isSelectYear,
      isSelectTime: isSelectTime,
      isSelectDateRange: isSelectDateRange,
    }"
  >
    <Field
      as="select"
      :name="name"
      @change="onChange($event)"
      :disabled="disabled"
    >
      <option v-for="(item, index) in options" :key="index" :value="item.value">
        {{ item.label }}
      </option>
    </Field>
    <p class="help-message" v-if="!!errorMessage" :class="errorMessageClass">
      <span class="icon-ico_ijyo2">
        <span class="path1" v-if="errorMessage"></span
        ><span class="path2"></span>
      </span>
      <span class="error-text">{{ errorMessage }}</span>
    </p>
  </div>
</template>

<script>
import { useField, Field } from "vee-validate";
export default {
  name: "SelectComponent",
  components: {
    Field,
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: null,
    },
    initialValue: {
      type: [Number, String],
      default: 0,
    },
    currentValue: {
      type: [Number, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isSelectYear: {
      type: Boolean,
      default: false,
    },
    isSelectTime: {
      type: Boolean,
      default: false,
    },
    isSelectDateRange: {
      type: Boolean,
      default: false,
    },
    errorMessageClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    onChange(e) {
      this.$emit("trigle-change", e);
    },
  },
  setup(props) {
    const { errorMessage, errors, handleBlur, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.initialValue,
        currentValue: props.currentValue,
      }
    );

    return {
      handleChange,
      handleBlur,
      errorMessage,
      errors,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped>
.SelectComponent {
  position: relative;
  width: fit-content;
  &:after {
    content: ">";
    font: 16px "Consolas", "monospace";
    font-weight: bold;
    color: #98a6b5;
    transform: rotate(90deg);
    right: 11px;
    top: 6px;
    position: absolute;
    pointer-events: none;
  }
  select {
    width: 210px;
    height: 30px;
    padding: 3px 8px 3px;
    border: solid 1px #d0d9df;
    border-radius: 0;
    background-color: #fff;
    -webkit-appearance: auto;
    -webkit-text-fill-color: var(--color-black-text);
    outline: none;
    font-size: 14px;
    font-weight: 500;
    appearance: none;
    -webkit-padding-end: 25px;
    &::-ms-expand {
      display: none;
    }
    &:focus {
      border-color: #8d9caa !important;
    }
    option {
      font-size: 14px;
      font-weight: 500;
      border: solid 1px #d0d9df;
      color: var(--color-black-text);
    }
  }
  &.isSmall {
    select {
      width: 170px;
    }
  }
  &.isSelectYear {
    select {
      width: 80px;
    }
  }
  &.isSelectTime {
    select {
      width: 60px;
    }
  }
  &.isSelectDateRange {
    select {
      width: 70px;
    }
  }
  &.has-error {
    select {
      border: solid 1px var(--error-color);
      padding: 3px 8px 3px;
      &:focus {
        outline: none;
        border-color: var(--error-color) !important;
      }
    }
  }
  .help-message {
    position: absolute;
    bottom: -2em;
    left: 0;
    margin: 0;
    font-size: 10px;
    color: var(--error-color);
    width: max-content;
    .error-text {
      position: relative;
      bottom: 1px;
      left: 5px;
    }
    .icon-ico_ijyo2 {
      margin-right: 2px;
      .path1 {
        &::before {
          font-size: 12px;
          color: #c51f1f;
        }
      }
      .path2 {
        &::before {
          font-size: 12px;
        }
      }
    }
    &.firstTelephone {
      left: -20px;
    }
  }
  // -webkit-text-fill-color: black;
}
</style>
