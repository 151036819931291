<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    :content-class="`WarningDialog modal-content ${className}`"
  >
    <button
      class="modal__close"
      @click="
        close();
        onClose();
      "
    >
      <span class="icon-ico_closedialog"></span>
    </button>

    <span class="modal__title"
      ><span class="icon-ico_info"></span> {{ title }}</span
    >
    <div class="content-custom">{{ content }}</div>
    <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
    <div class="modal__action">
      <ButtonComponent
        type="outline"
        width="150px"
        height="50px"
        buttonType="button"
        @click="
          close();
          onClose();
        "
      >
        {{ onCloseText }}
      </ButtonComponent>
      <ButtonComponent
        width="150px"
        height="50px"
        class="btn-submit"
        @click="onApprove()"
      >
        {{ onChoiseText }}
      </ButtonComponent>
    </div>
  </vue-final-modal>
</template>

<script>
import { STATUS } from "@/globals/enums";
export default {
  name: "WarningDialog",
  props: {
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onChoise: {
      type: Function,
      default: () => {},
    },
    onCloseText: {
      type: String,
      default: "キャンセル",
    },
    onChoiseText: {
      type: String,
      default: "OK",
    },
    className: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    errorDetail: null,
  }),
  methods: {
    async onApprove() {
      const result = await this.onChoise(true);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response?.detail;
        return;
      }
      this.$vfm.hideAll();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.WarningDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 40px 50px 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      border: 1px solid #888;
      width: 500px;
      min-height: 236px;
      height: auto;
      border-radius: 5px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
          color: #c51f1f;
          display: inline-flex;
          .icon-ico_info {
            margin-right: 5px;
            font-size: 25px;
          }
        }
        &__content {
          width: 100%;
          font-size: 14px;
          text-align: center;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 10px;
          &-addressItem {
            height: 60px;
            padding: 20px;
            border: solid 1px #d0d9df;
            display: flex;
            align-items: center;
            .InputComponent {
              margin-bottom: 0;
            }
            &.active {
              background-color: #f3f6f7;
              color: #333;
            }
          }
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
        &__action {
          display: flex;
          gap: 20px;
          margin-top: 30px;
        }
      }
      &.autoCall {
        .modal {
          &__title {
            color: #000;
            font-size: 16px;
            font-weight: bold;
            .icon-ico_info {
              margin-right: 10px;
              font-size: 30px;
              &::before {
                color: #000;
              }
            }
          }
          &__content {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  ::v-deep(.WarningDialog) {
    &.modal {
      &-content {
        width: 320px;
        height: auto;
        padding: 40px 20px 29px;
        .modal {
          &__title {
            font-size: 20px;
          }
          &__action {
            flex-direction: column-reverse;
            gap: unset;
            .btn-submit {
              margin-bottom: 20px;
            }
          }
        }
        &.autoCall {
          width: 320px;
          min-height: 324px;
          height: auto;
          padding: 35px 22px;
          .modal {
            &__title {
              color: #000;
              font-size: 16px;
              font-weight: bold;
              text-align: left;
              .icon-ico_info {
                margin-right: 10px;
                font-size: 30px;
                &::before {
                  color: #000;
                }
              }
            }
            &__content {
              font-size: 16px;
              font-weight: bold;
            }
            &__action {
              flex-direction: column-reverse;
              .btn-submit {
                margin-bottom: 20px;
              }
            }
            &__close {
              display: none;
            }
          }
        }
      }
    }
  }
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-custom {
  font-size: 16px;
  font-weight: bold;
  white-space: pre-wrap;
}
</style>
