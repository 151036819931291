<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    :content-class="`ConfirmEditAnalysisDialog modal-content`"
    @click-outside="onClose()"
  >
    <button
      class="modal__close"
      @click="
        close();
        onClose();
      "
    >
      <span class="icon-ico_closedialog"></span>
    </button>
    <span class="modal__title">
      <span class="icon-title icon-ico_info"></span>
      <div>
        明日から不在期間が設定されます。
        <br />
        本サービスのご利用を
        <span class="highlight">一時停止</span> いたしますが、
        <br />
        よろしいでしょうか？
      </div>
    </span>
    <span class="modal__content">
      ※一時停止後にサービスを再開する場合、不在期間の終了日を設定
      <br />
      <div class="modal__content__child">
        いただく必要があります。
        <br />
        終了日のご予定がある場合、<span @click="showDetail" class="underline"
          >不在期間設定</span
        >
        から不在期間をご登録
        <br />
        ください。
      </div>
    </span>
    <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
    <div class="modal__action">
      <ButtonComponent
        width="150px"
        height="50px"
        type="outline"
        @click="
          close();
          onClose();
        "
      >
        キャンセル
      </ButtonComponent>
      <ButtonComponent
        width="150px"
        height="50px"
        :disabled="isLoading"
        @click="onApprove()"
      >
        OK
      </ButtonComponent>
    </div>
  </vue-final-modal>
</template>

<script>
import { STATUS } from "@/globals/enums";
export default {
  name: "ConfirmEditAnalysisDialog",
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    onChoise: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    errorDetail: null,
    isLoading: false,
  }),
  methods: {
    async onApprove() {
      this.isLoading = true;
      const result = await this.onChoise(true);
      if (result?.status === STATUS.ERROR && result.response?.detail) {
        this.errorDetail = result.response?.detail;
      }
      this.isLoading = false;
    },

    showDetail() {
      this.$router.push({ name: "AbsenceRegistrationDetailComponent" });
      this.$vfm.hideAll();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.ConfirmEditAnalysisDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 40px 40px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      border: 1px solid #888;
      border-radius: 5px;
      // width: 500px;
      // height: 238px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: left;
          min-width: 224px;
          display: flex;
          font-size: 16px;
          .highlight {
            color: #c51f1f;
          }
          .icon-title {
            font-size: 30px;
            margin-right: 10px;
            &::before {
              color: #000000;
            }
          }
        }
        &__content {
          font-size: 14px;
          text-align: left;
          margin: 15px 0 25px 0;
          &__child {
            margin-left: 15px;
            .underline {
              @include anchorLink(var(--primary-color));
            }
          }
        }
        &__action {
          display: flex;
          .ButtonComponent {
            margin: 0 10px;
          }
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
      }
    }
    &-403 {
      width: 430px;
      padding: 30px 20px;
    }
  }
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: $mobile-breakpoint) {
  ::v-deep(.ConfirmEditAnalysisDialog) {
    &.modal {
      &-content {
        width: 320px;
        padding: 40px 20px 30px;
        .modal {
          &__content {
            display: none;
          }
          &__action {
            flex-direction: column-reverse;
            margin-top: 20px;
            * > * {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
