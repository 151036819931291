<template>
  <div
    class="TextAreaComponent"
    :class="{
      success: meta.valid,
      'has-error': !!errorMessage || errors.length !== 0,
    }"
  >
    <div v-if="required" class="label" :class="labelClass">必須</div>
    <textarea
      v-inputFilter="inputFilter"
      :name="name"
      :id="id || name"
      :type="type"
      :placeholder="placeholder"
      :pattern="pattern"
      :maxlength="maxlength"
      :disabled="disabled"
      :rows="rows"
      :cols="cols"
      @focus="onFocus"
      @input="handleChange"
      @blur="onBlur"
      :value="inputValue"
    >
    </textarea>
    <span
      class="help-message"
      v-show="errorMessage && showErrorMessage && meta.touched"
      :class="{
        noMarginBottomError: noMarginBottomError,
      }"
    >
      <span class="icon-ico_ijyo2">
        <span class="path1" v-if="errorMessage"></span
        ><span class="path2"></span>
      </span>
      <span class="error-text">{{ errorMessage || successMessage }}</span>
    </span>
  </div>
</template>

<script>
import { useField } from "vee-validate";
export default {
  name: "TextAreaComponent",
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    validateSteps: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: [String, Number, Boolean],
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Boolean,
      default: false,
    },
    inputFilter: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredLabelClass: {
      type: String,
      default: "label",
    },
    noMarginBottomError: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: "",
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 50,
    },
    rows: {
      type: Number,
      default: 4,
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      errors,
      handleBlur,
      handleChange,
      validate,
      setTouched,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.defaultValue ? props.defaultValue : props.value,
      validateOnValueUpdate: false,
    });
    const onFocus = async () => {
      await setTouched(false);
    };
    const onBlur = async (e) => {
      await validate(e);
      await handleBlur(e);
    };

    return {
      handleChange,
      handleBlur,
      onFocus,
      onBlur,
      errorMessage,
      errors,
      inputValue,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped>
.TextAreaComponent {
  position: relative;
  width: 100%; //default width
  position: relative;
  label {
    display: block;
    margin-bottom: 4px;
    width: 100%;
  }
  &_validateSteps {
    display: flex;
    position: absolute;
    margin-top: 5px;
    gap: 18px;
    &_step {
      display: flex;
      gap: 5px;
      span {
        font-size: 10px;
        font-weight: 500;
        color: #7c8185;
      }
    }
  }

  textarea {
    border: 1px solid #d0d9df;
    padding: 6px 11px;
    outline: none;
    background-color: #ffffff;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    &:focus {
      border-color: #8d9caa !important;
    }
    &:disabled {
      border-color: #8d9caa !important;
      background-color: #d0d9df;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 0.86;
      color: var(--color-gray);
    }
    resize: none;
  }

  .icon-ico_password_open {
    &::before {
      content: "\e932";
      color: #8d9caa;
      font-size: 18px;
    }
  }
  .help-message {
    position: absolute;
    bottom: calc(-1.5 * 1em);
    left: 0;
    margin: 0;
    font-size: 10px;
    width: max-content;
    color: var(--error-color);
    &.noMarginBottomError {
      bottom: calc(-3.5 * 1em);
    }
    .error-text {
      position: relative;
      bottom: 1px;
      left: 5px;
    }
    .icon-ico_ijyo2 {
      margin-right: 2px;
      .path1 {
        &::before {
          font-size: 12px;
          color: #c51f1f;
        }
      }
      .path2 {
        &::before {
          font-size: 12px;
        }
      }
    }
  }

  &.has-error textarea {
    border-color: var(--error-color);
  }

  &.has-error textarea {
    border-color: var(--error-color);
  }

  &.has-error .help-message {
    color: var(--error-color);
  }

  .TextAreaComponent.success textarea {
    background-color: var(--success-bg-color);
  }

  .TextAreaComponent.success textarea:focus {
    border-color: var(--success-color);
  }

  .TextAreaComponent.success .help-message {
    color: var(--success-color);
  }
}
</style>
