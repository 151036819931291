import showDialog from "@/services/dialog.service";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import WarningAutoCallDialog from "@/components/dialogs/WarningAutoCallDialog.vue";

export default class WarningDialogService {
  openWarningDialog(
    title,
    content,
    callbackClose,
    callbackChoise,
    onCloseText,
    onChoiseText,
    className = ""
  ) {
    showDialog({
      component: WarningDialog,
      bind: {
        title,
        content,
        onClose: callbackClose,
        onChoise: callbackChoise,
        onCloseText,
        onChoiseText,
        className,
      },
    });
  }

  openWarningAutoCallDialog(
    title,
    content,
    callbackClose,
    callbackChoise,
    onCloseText,
    onChoiseText,
    className = ""
  ) {
    showDialog({
      component: WarningAutoCallDialog,
      bind: {
        title,
        content,
        onClose: callbackClose,
        onChoise: callbackChoise,
        onCloseText,
        onChoiseText,
        className,
      },
    });
  }
}
