<template>
  <div class="ActivateEmailComponent">
    <CardComponent
      class="form-registrationSuccess"
      :width="'960px'"
      :height="'530px'"
      v-if="activateEmailResult"
    >
      <TitleComponent
        class="form-registrationSuccess-title fitContent"
        :hasUnderLine="true"
      >
        <template v-slot:content>{{ activateEmailResult?.title }}</template>
      </TitleComponent>
      <div class="form-registrationSuccess-action-group">
        <ErrorDetailComponent
          v-if="activateEmailResult?.detail"
          :errorDetail="activateEmailResult?.detail"
        />
        <ButtonComponent @click="nextAction" width="230px">
          {{ isMainEmail ? "HOME画面へ" : "閉じる" }}
        </ButtonComponent>
      </div>
      <LinkComponent
        :link="$domain.HELP_CONTENT_LINK['2-1-h']"
        v-if="isMainEmail && isSuccess"
      >
        <span class="d-flex align-center justify-center black">
          <i class="icon icon-help"></i>
          操作方法がご不明な場合
        </span>
      </LinkComponent>
    </CardComponent>
  </div>
</template>

<script>
import WebStorageService from "@/services/web-storage.service";
import HomeService from "@/services/home.service";
import LoginService from "@/services/login.service";
import AlertService from "@/services/alert.service";
import { STATUS } from "@/globals/enums";

export default {
  name: "ActivateEmailComponent",
  data: () => ({
    homeService: new HomeService(),
    loginService: new LoginService(),
    webStorageService: new WebStorageService(),
    alertService: new AlertService(),
    activateEmailResult: null,
    isSuccess: false,
    isMainEmail: false,
  }),
  mounted() {
    /**
     * [SPECIFICATION]
     * If the value of query parameter "order" is 1 (or the parameter doesn't exist),
     * this screen was opened to activate the main e-mail address.
     * Otherwise (if the value of query parameter "order" is 2 or 3),
     * it was opened to activate the second or third e-mail address.
     */
    const order = this.$route?.query?.order;
    this.isMainEmail = order === "1" || typeof order === "undefined";
    this.activateEmail();
  },
  methods: {
    async nextAction() {
      if (this.isMainEmail) {
        /**
         * [SPECIFICATION]
         * When this screen was opened to activate the main e-mail address,
         * move to the Home screen.
         */
        this.goHomePage();
      } else {
        /**
         * [SPECIFICATION]
         * When this screen was opened to activate the second or third e-mail address,
         * close the window without moving to the Home screen.
         */
        window.close();
      }
    },
    async goHomePage() {
      const router = this.loginService.isLoggedIn()
        ? "HomeComponent"
        : "LoginComponent";
      if (router === "HomeComponent") {
        this.$store.dispatch("global/updateLoadingStatus", true);
        await this.homeService.getHome();
        this.$store.dispatch("global/updateLoadingStatus", false);
      }
      this.$router.push({ name: router });
    },
    async activateEmail() {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.alertService.activateEmail(
        this.$route?.params?.id || ""
      );
      this.$store.dispatch("global/updateLoadingStatus", false);
      this.activateEmailResult = result.response;
      this.isSuccess = result.status === STATUS.SUCCESS;
    },
  },
};
</script>

<style scoped lang="scss">
.ActivateEmailComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-registrationSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    &-title {
      font-size: 20px;
      font-weight: 500;
      font-weight: 500;
    }
    &-description {
      font-size: 14px;
      font-weight: 500;
      margin: 20px 0 0;
    }
    &-action-group {
      margin-top: 75px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      .ErrorDetailComponent {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .ActivateEmailComponent {
    height: 100%;
    display: block;
    margin: 0;
    &-title {
      width: 300px;
      margin: 0 20px;
    }
    .form-registrationSuccess {
      width: 100% !important;
      height: 100% !important;
      padding: 30px 20px;
      margin-bottom: 0;
      &-title {
        text-align: center;
        width: 315px;
      }
      &-action-group {
        margin-top: 82px;
      }
    }
  }
}
</style>
