<template>
  <div class="ForgotPasswordComponent">
    <Form
      @submit="sendCodeToEmail"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <CardComponent
        class="form-forgotPassword"
        :width="'960px'"
        :height="'550px'"
      >
        <TitleComponent
          class="form-forgotPassword_title fitContent"
          :hasUnderLine="true"
        >
          <template v-slot:content>認証コード発行</template>
        </TitleComponent>
        <span class="form-forgotPassword_description">
          <p>パスワードを再発行するために、 認証コードをお送りします。</p>
          認証コードをお送りするためのメールアドレスを入力してください。
        </span>

        <div class="form-forgotPassword_inputGroups">
          <InputComponent
            name="mail"
            placeholder="メールアドレスを入力"
            maxlength="100"
            :maxWidth="true"
            @trigle-change="errorDetail = null"
          />
        </div>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="form-action-group">
          <ButtonComponent
            buttonType="button"
            type="outline"
            width="150px"
            @click="(e) => cancel(e)"
          >
            キャンセル
          </ButtonComponent>
          <ButtonComponent
            buttonType="submit"
            width="150px"
            :disabled="!(meta.valid && meta.dirty)"
            class="btn-submit"
          >
            送信
          </ButtonComponent>
        </div>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import LoginService from "@/services/login.service";
import { forgotPasswordSchema } from "@/validations/schemas/forgotPasswordSchema";
import { STATUS } from "@/globals/enums";

export default {
  name: "ForgotPasswordComponent",
  components: {
    Form,
  },
  data: () => ({
    loginService: new LoginService(),
    schema: forgotPasswordSchema,
    errorDetail: null,
  }),
  methods: {
    async sendCodeToEmail(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.loginService.forgotPassword(data.mail);
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
    async cancel(e) {
      e.preventDefault();
      await this.loginService.logout();
    },
  },
};
</script>

<style scoped lang="scss">
.ForgotPasswordComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-forgotPassword {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &_description {
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0 40px;
        text-align: center;
      }
      &_inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 360px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          font-weight: bold;
          color: #7c8185;
        }
        .InputComponent {
          margin: 0;
        }
      }
    }
    &-action-group {
      display: flex;
      gap: 20px;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .ForgotPasswordComponent {
    display: block;
    .v-card {
      width: 100% !important;
      height: 100% !important;
    }
    form {
      height: 100%;
    }
    .form {
      &-forgotPassword {
        &_description {
          font-size: 14px;
          font-weight: 500;
          width: 320px;
          text-align: center;
          p {
            width: 220px;
            margin: 0 auto;
          }
        }
        &_inputGroups {
          padding: 0 20px 0;
          width: 100%;
        }
      }
      &-action-group {
        flex-direction: column-reverse;
        margin-top: 30px;
        .btn-submit {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
