<template>
  <div class="ReSettingAuthAppComponent">
    <Form
      @submit="handleMFA"
      :validation-schema="schema"
      v-slot="{ meta, handleReset }"
    >
      <TitleComponent>
        <template v-slot:icon><span class="icon-ico_guard"></span></template>
        <template v-slot:content>セキュリティ設定</template>
      </TitleComponent>
      <CardComponent
        ref="pageContainer"
        class="card"
        :width="'960px'"
        :height="'auto'"
      >
        <template v-if="currentPage === 1">
          <div class="card_body">
            <div class="card-title">Authenticatorアプリの設定</div>
            <div class="step">
              <span class="step_title">
                <span>1.</span>
                認証用のアプリをスマートフォンにダウンロードし、インストールしてください。
              </span>
              <div class="step_description step_description-left">
                Microsoft Authenticator
              </div>
              <ul class="getApp">
                <li class="getApp_item">
                  <figure class="getApp_qrcode">
                    <img
                      class="qrcode_img"
                      alt="App StoreへのQRコード"
                      :src="
                        require('@/assets/images/common/qr_ios-app-store.svg')
                      "
                    />
                  </figure>
                  <figure class="getApp_badge">
                    <figcaption class="badge_caption">iPhone/iPad版</figcaption>
                    <a
                      class="badge_link"
                      href="https://apps.apple.com/jp/app/microsoft-authenticator/id983156458"
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        class="badge_img"
                        alt="App Storeからダウンロード"
                        :src="
                          require('@/assets/images/common/badge_ios-app-store.svg')
                        "
                      />
                    </a>
                  </figure>
                </li>
                <li class="getApp_item">
                  <figure class="getApp_qrcode">
                    <img
                      class="qrcode_img"
                      alt="Google PlayへのQRコード"
                      :src="
                        require('@/assets/images/common/qr_google-play-store.svg')
                      "
                    />
                  </figure>
                  <figure class="getApp_badge">
                    <figcaption class="badge_caption">Android版</figcaption>
                    <a
                      class="badge_link"
                      href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        class="badge_img"
                        alt="Google Playで手に入れよう"
                        :src="
                          require('@/assets/images/common/badge_google-play-store.png')
                        "
                      />
                    </a>
                  </figure>
                </li>
              </ul>
            </div>
            <div class="step step_last">
              <span class="step_title pb-8">
                <span>2.</span>
                認証アプリを起動し、プライバシー保護に同意するとともに、続行ボタンを押下します。
              </span>
            </div>
          </div>
          <div class="action-group">
            <ButtonComponent
              buttonType="button"
              type="outline"
              width="160px"
              @click="(e) => cancel(e)"
            >
              キャンセル
            </ButtonComponent>
            <ButtonComponent
              buttonType="button"
              width="160px"
              class="btn-submit"
              @click="onPageChanged(2), handleReset()"
            >
              次へ
            </ButtonComponent>
          </div>
        </template>
        <template v-if="currentPage === 2">
          <div class="card_body">
            <div class="card-title">Authenticatorアプリの設定</div>
            <div class="step">
              <span class="step_title">
                <span>3.</span>
                「QRコードをスキャンします」を選択のうえ、次の画像をスキャンします。
              </span>
              <div class="step_description">
                <QrcodeVue
                  v-if="QRcode"
                  :value="QRcode.generateQRCode"
                  :size="120"
                />
                <div v-else class="empty"></div>
                <div class="QRcode_description">
                  画像をスキャンできない場合は、<br />
                  アプリケーションに次の情報を入力してください。
                  <p class="code">コード： {{ QRcode?.code }}</p>
                </div>
              </div>
            </div>
            <div class="step step_last">
              <span class="step_title">
                <span>4.</span>
                「アプリロック有効」の表示を確認のうえ、「{{
                  issuerCompany
                }}」を選択することで表示される「ワンタイム パスワード
                コード」（6桁）を以下に入力してください。
              </span>
              <div class="step_description form">
                <InputComponent
                  name="code"
                  placeholder="認証コードを入力"
                  inputFilter="number"
                  maxlength="6"
                  @trigle-change="errorDetail = null"
                />
              </div>
            </div>
          </div>
          <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
          <div class="action-group">
            <ButtonComponent
              buttonType="button"
              type="outline"
              width="160px"
              @click="onPageChanged(1), (errorDetail = null)"
            >
              もどる
            </ButtonComponent>
            <ButtonComponent
              buttonType="submit"
              width="160px"
              class="btn-submit"
              :disabled="!(meta.valid && meta.dirty)"
            >
              OK
            </ButtonComponent>
          </div>
        </template>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { STATUS } from "@/globals/enums";
import QrcodeVue from "qrcode.vue";
import { mapState } from "vuex";
import LoginService from "@/services/login.service";
import { Form } from "vee-validate";
import { settingMFAAuthAppSchema } from "@/validations/schemas/settingMFAAuthAppSchema";

export default {
  name: "ReSettingAuthAppComponent",
  components: {
    QrcodeVue,
    Form,
  },
  async created() {
    const result = await this.loginService.setupTOTP();
    if (result.status === STATUS.SUCCESS) {
      this.user = result.data;
    }
  },
  computed: mapState({
    QRcode: (state) => state.settings.QRcode,
  }),
  data: () => ({
    currentPage: 1,
    issuerCompany: process.env.VUE_APP_AWS_ISSUER_COMPANY,
    loginService: new LoginService(),
    schema: settingMFAAuthAppSchema,
    errorDetail: null,
  }),
  methods: {
    async handleMFA(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.loginService.registerTOTPAgain(
        data.code,
        this.user
      );
      await this.loginService.getCognitoUserData();
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
    cancel(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
    onPageChanged(page) {
      const $container = this.$refs.pageContainer.$el;

      // Temporarily keep container height
      // because low screen height causes scrolling position to shift
      $container.style.height = `${$container.offsetHeight}px`;

      // Switch pages and make them visible
      this.currentPage = page;
      $container.scrollIntoView();

      // Roll back the container height
      setTimeout(() => {
        $container.style.height = "auto";
      });
    },
  },
  unmounted() {
    this.loginService.clearQrCode();
  },
};
</script>

<style scoped lang="scss">
.ReSettingAuthAppComponent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  .TitleComponent {
    width: 960px;
    text-align: left;
    color: #000000;
    padding: 0 0 11px 0;
    .icon-ico_guard {
      &::before {
        color: #000;
        font-size: 29px;
      }
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 40px;
    margin-bottom: 30px;
    &_title {
      margin-top: 30px;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
    &_description {
      font-size: 14px;
      font-weight: 500;
      margin: 20px 0 0;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
      width: 100%;
      border-bottom: solid 2px #d0d9df;
      line-height: 29px;
    }
    &_body {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      .step {
        padding: 0 12px;
        &_title {
          font-size: 14px;
          font-weight: 600;
          display: flex;
          gap: 7px;
        }
        &_description {
          margin: 17px 20px 0px;
          display: flex;
          .QRcode_description {
            margin-left: 27px;
            font-size: 12px;
            font-weight: normal;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            color: #000;
            .code {
              font-size: 14px;
              font-weight: 500;
            }
          }
          &.form {
            margin: 20px 21px 30px;
            .InputComponent {
              height: 50px;
            }
          }
        }
        &_last {
          border-bottom: solid 2px #d0d9df;
          margin-bottom: 30px;
        }
      }
    }
    .action-group {
      display: flex;
      gap: 20px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .getApp {
    display: flex;
    list-style: none;
  }
  .getApp_item {
    display: flex;
  }
  .getApp_item:not(:last-child) {
    margin-right: 20px;
  }
  .getApp_qrcode {
    display: block;
    margin: 12px;
    .qrcode_img {
      width: 82px; // = (original: 164px) * 1/2
      vertical-align: bottom;
    }
  }
  .getApp_badge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .badge_caption {
      font-size: var(--font-size-small);
      margin-bottom: 8px;
    }
    .badge_link {
      transition: opacity 0.5s;
      &:hover {
        opacity: 0.65;
        cursor: pointer;
      }
    }
    .badge_img {
      height: 44px;
      vertical-align: bottom;
    }
  }

  .empty {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: $tablet-breakpoint) and (min-width: $mobile-breakpoint) {
  .ReSettingAuthAppComponent {
    .card {
      width: 740px !important;
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .ReSettingAuthAppComponent {
    height: 100%;
    align-items: flex-start;
    margin: 0;
    form {
      height: 100%;
      width: 100%;
    }
    .TitleComponent {
      width: 300px;
      margin-left: 20px;
    }
    .card {
      width: 100% !important;
      height: max-content !important;
      padding: 0px 0px 30px;
      margin-bottom: 0;
      &_body {
        padding: 30px 20px 10px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        width: 100%;
        .step {
          width: 100%;
          padding: 0;
          &_title {
            font-size: 14px;
            font-weight: 500;
            width: 100%;
          }
          &_description {
            margin: 17px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            &-left {
              display: block;
              margin: 17px 20px;
            }
            .QRcode_description {
              margin-left: 20px;
              font-size: 12px;
              font-weight: 500;
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: 100%;
              .code {
                font-size: 14px;
                width: 100%;
                word-wrap: break-word;
              }
            }
          }
          .form {
            .InputComponent {
              width: 100% !important;
            }
          }
        }
      }
      .action-group {
        flex-direction: column-reverse;
        gap: 0;
        .btn-submit {
          margin-bottom: 20px;
        }
      }
    }

    .getApp {
      flex-direction: column;
    }
    .getApp_item {
      flex-wrap: wrap;
    }
    .getApp_item:not(:last-child) {
      margin-right: auto;
      margin-bottom: 30px;
    }
    .getApp_qrcode {
      margin: 0 8px;
      .qrcode_img {
        width: 123px; // = (original: 164px) * 3/4
      }
    }
    .getApp_badge {
      width: 181px;
      .badge_img {
        height: 56px;
      }
    }
  }
}
</style>
